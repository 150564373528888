import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import { Menu } from 'antd';
import './../assets/styles/toggleMenuWrapper.scss'
import { AdapterLocalStorage } from '../shared/Infraestructure/AdapterLocalStorage';
import { useLocation, useNavigate } from 'react-router-dom';

const ToggleMenuWrapper = (props: { children: JSX.Element }) => {
    const [menu, setMenu] = useState<any[]>([]);
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = () => {
        let { menu } = AdapterLocalStorage.get('menu');
        if (!menu) return;
        menu = JSON.parse(menu);
        setMenu(menu)
       if (window.innerWidth < 1000) onChangeCollapse();
    }

    const onChangeCollapse = () => setCollapsedMenu(!collapsedMenu);
    const handleRoute = (url:string) => navigate(`/${url}`);

    return (
        <Dashboard>
            <div className="toggle_menu">
                <div className="body_menu">
                    <Menu
                        style={ collapsedMenu ? { width: 1 } : { width: 256 }}
                        inlineCollapsed={ collapsedMenu }
                        defaultSelectedKeys={[ `${location.pathname}` ]}
                        mode={'vertical'}
                    >
                        {
                            menu?.map((row, index) =>
                                row.routing ?
                                    <Menu.Item 
                                        key={`/${row.routing}`}
                                        onClick={ () => handleRoute(row.routing) }
                                    >   
                                        {row.title}
                                    </Menu.Item>
                                    :
                                    <Menu.Item key={index} disabled> 
                                        {row.title}
                                    </Menu.Item> 
                            )
                        }
                    </Menu>
                </div>
                <div className={ `body_button ${collapsedMenu ? 'btn__Open' : 'btn__Close'}`}>
                    <button onClick={onChangeCollapse} style={{ marginBottom: 16 }}>
                        {collapsedMenu ? '>' : '<'}
                    </button>
                </div>

                    { props.children }
            </div>
        </Dashboard>
    )
}

export default ToggleMenuWrapper;