import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { PrivateRoute } from '../components/privateRouter';
import ErrorBoundary from "./ErrorBoundary";
import Login from "../pages/Login";
import LogoPowered from './../assets/images/logo/Powered-MagicChain.svg';

import LoginQR from './../pages/LoginQr';
import ForgotPassword from '../pages/ForgotPassword';
import RecoverPassword from '../pages/RecoverPassword';
import EmailActivaction from '../pages/emailActivation';

import Dashboard from "../pages/web/Dashboard";
import ChangePassword from "../pages/web/Options/ChangePassword";
import MyAccount from "../pages/web/Options/MyAccount";
import Commercialization from "../pages/web/Commercialization";
import ProcessesList from "../pages/web/Processes/list";
import ProccessesReport from "../pages/web/Processes/Reporte";
import ProccessesGenCode from '../pages/web/Processes/genCode';
import ListStakeholdersType from "../pages/web/stakeholders/list-type";
import ListStakeHolders from "../pages/web/stakeholders/list";
import ListUserStakeholders from "../pages/web/stakeholders/user-stakeholder";
import Usuario from "../pages/web/Usuario";
import Report from "../pages/web/Report";
import ReportDetail from "../pages/web/Report/detail";
import ViewPropuesta from "../pages/web/PropuestaDAO";
import ViewConfiguracion from "../pages/web/Configuracion";

import ViewLadingPageNFT from '../pages/ladingpage/nft';
import ViewLadingPageHistory from '../pages/ladingpage/history';
import ViewLandingPagePropuesta from './../pages/ladingpage/propuesta/List';
import ViewLandingPagePropuestaDetail from './../pages/ladingpage/propuesta/Detail';

export class Main extends React.Component<any, any> {

	render() {
		return (
			<ErrorBoundary>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/propuestas" element={<ViewLandingPagePropuesta />} />
					<Route path="/propuestas/:id" element={<ViewLandingPagePropuestaDetail />} />
					<Route path="/nft/:id" element={<ViewLadingPageNFT />} />
					<Route path="/historia/:id" element={<ViewLadingPageHistory />} />
					<Route path="/qraccess" element={<LoginQR />} />
					<Route path="/forgot-Password" element={<ForgotPassword />} />
					<Route path="/recuperar-password" element={<RecoverPassword />} />
					<Route path="/activar-cuenta" element={ <EmailActivaction /> } />
					<Route element={<PrivateRoute />}>
						{/*Comercio*/}
						<Route path="comercializacion" element={ <Commercialization /> } />
						{/*Procesos*/}
						<Route path="procesos/generar-codigos" element={ <ProccessesGenCode /> }/>
						<Route path="procesos/reporte" element={ <ProccessesReport /> }/>
						<Route path="procesos" element={ <ProcessesList /> }/>
						{/*Stakeholders*/}
						<Route path="tipo-stakeholder" element={ <ListStakeholdersType /> } />
						<Route path="stakeholders" element={ <ListStakeHolders /> } />
						<Route path="usuarios-stakeholder" element={ <ListUserStakeholders /> } />
						{/*Reporte*/}
						<Route path="reporte-trazabilidad/:id" element={ <ReportDetail /> }/>
						<Route path="reporte-trazabilidad" element={ <Report /> }/>
						{/*Usuario*/}
						<Route path="usuarios" element={ <Usuario /> }/>
						{/*password*/}
						<Route path="cambiar-password" element={ <ChangePassword /> }/>
						{/*Mi Cuenta*/}
						<Route path="mi-cuenta" element={ <MyAccount /> }/>
						{/*Propuestas-DAO */}
						<Route path="propuestas-dao" element={ <ViewPropuesta /> } />
						{/*Configuración */}
						<Route path="configuracion" element={ <ViewConfiguracion /> } />
						{/*Dashboard*/}
						<Route path="tablero-control" element={ <Dashboard /> }/>
						<Route path="" element={ <Navigate to={'tablero-control'} /> }/>
					</Route>
				</Routes>
				<div className='logoPowered'> <a target="_blank" rel="noopener noreferrer" href="https://magia.digital/magicchain"> <img src={LogoPowered} alt="logo" height={40}/> </a> </div>
			</ErrorBoundary>	
		)
	}
}

export default Main;