// Library
import { useAddress, useMetamask } from '@thirdweb-dev/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';

// Domain
import { EntityConfigModule, initEntityConfigModule } from '../Domain/EntityUtils';
import { EntityResponsetNFTMint } from '../Domain/EntityResponse';

// Adapters
import { baseURLSet } from '../../../../services/constants';
import { AdapterWeb3 } from '../../../../shared/Infraestructure/AdapterWeb3';
import { AdapterDetectDevice } from '../../../../shared/Infraestructure/AdapterDetectDevice';
import { AdapterGeneric } from '../../../../shared/Infraestructure/AdapterGeneric';
import { message } from 'antd';
import { AdapterPublicEnv } from '../../../../shared/Infraestructure/AdapterPublicEnv';

let tokenID = '';
export const Controller = () => {
    const { changeChainCustom, reclaimNFTCustom, connectWalletCustom } = AdapterWeb3();
    const [currentStep, setCurrentStep] = useState(1);
    const [configModule, setConfigModule] = useState<EntityConfigModule>(initEntityConfigModule);
    const [loading, setLoading] = useState(false);
    const connectWallet = useMetamask();
    const address = useAddress();
    const params = useParams();

    const init = () => {
        validateNFT();
    }

    const validateNFT = async () => {
        try {
            if (["Mobile", "Tablet"].includes(AdapterDetectDevice())) {
                setConfigModule({ code: 'notSupport', text: '' })
                return;
            }
            const response = (await axios.get(`${baseURLSet.default}client/portal/confirmacion_reclamacion_nft/${params.id}`)).data.result;
            if (response === 'P') {
                setConfigModule({ code: 'pending', text: '' })
            } else {
                setConfigModule({ code: 'reclaimed', text: 'El NFT asociado a esta página ya fue reclamado. ¡Muchas gracias!' })
            }
        } catch (error) {
            setConfigModule({ code: 'error', text: '¡Lo sentimos! Esta página no existe.' })
        }
    }

    const formWallet = useFormik({
        initialValues: { wallet: '' },
        onSubmit: () => {},
        validationSchema: Yup.object({
            wallet: Yup.string().required('Conecte su billetera virtual'),
        })
    });

    const onChange = (name: string, value: any) => formWallet.setValues({...formWallet.values, [name]: value})
    const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        try {
            setLoading(true);
            let newAddress = address || await connectWalletCustom();
            if (!window.ethereum) throw Error('Ocurrió un error al conectarse con metamask')

            const response: EntityResponsetNFTMint = (await axios.post(`${baseURLSet.default}client/proceso/mint-nft-blockchain`, {
                "hash_nft": `${params.id}`,
                "address_to": newAddress,
                "info_uri": `${new AdapterPublicEnv().getImageIPFS()}`
            })).data.result;

            tokenID = response.tokenId;
        } catch (error) {

        } finally {
            setLoading(false);
        }

        setCurrentStep(2);
    }

    const handleAddNFT = async () => {
        if (!tokenID) return console.log('Error not tokenID');
        if (typeof window.ethereum !== 'undefined') {
            try {
                await changeChainCustom();
                await reclaimNFTCustom(tokenID);
            } catch (error) {
                console.error('Error adding NFT to MetaMask tokens:', error);
            }
        }
    };

    const CopyUrlNFT = () => {
        AdapterGeneric.copyTextToClipboard(window.location.href);
        message.success('Copiado', 1);
    }

    return ({
        init,
        onChange,
        onSubmit,
        connectWallet,
        handleAddNFT,
        CopyUrlNFT,
        formWallet,
        currentStep,
        address,
        configModule,
        loading,
    })
}