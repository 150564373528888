/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Input, Select, Checkbox, Radio, DatePicker, Col } from 'antd';
import { DictionaryDataType, DictionaryValidation } from './Dictionary';
import { AdapterGeneric } from '../../shared/Infraestructure/AdapterGeneric';
const { TextArea } = Input;

interface IPropsDefault {
    etiqueta: string,
    identificador: string,
    onChange: Function,
    tipoDato: string,
    value: any,
    obligatorio: boolean,
    readOnly: boolean,
    tipoValidacion: any,
    textoMaxLength?: number | null,
    options?: any[],
    loadingBtnModal: boolean
    isFilter?: boolean
}
const Style = { padding: '8px 12px', margin: 0};

const ValidateOnChange = (props:any, value:any) => {

    const isTipoDecimal = props.tipoDato === "03";
    const isTipoNumber = props.tipoDato === "02";
    if(!isTipoDecimal && !isTipoNumber) return props.onChange({name:props.identificador, value});

    //validacion del minimo valor "0"
    const validate01 = DictionaryValidation["minNumber"];
    if(validate01 > value) return props.onChange({name:props.identificador, value: 0});;
    //validacion tipo número
    const validate02 = DictionaryValidation["number"];
    if(!validate02.test(value) && isTipoNumber) return; 
    //si pasa la validacion cambia el valor
    return props.onChange({name:props.identificador, value});
}

//NOTE: Input type 08+ are dont configurate
/** @keyword Input Text Field - 01**/
export const CTextfield = (props:IPropsDefault) => {
    const typeInput = DictionaryDataType[props.tipoDato] || "";
    const disabled = !!(props.readOnly || props.loadingBtnModal);

    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> }</label>
            <Input 
                onChange={(e:any) => ValidateOnChange(props, e.target.value)}
                placeholder={props.etiqueta}
                type={typeInput}
                value={props.value}
                disabled={disabled}
                //maxLength={props.textoMaxLength}
            />
        </Col>
    )
}
/** @keyword Input Text Area - 02**/
export const CTextArea = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{props.etiqueta} { props.obligatorio && <a>*</a> } </label>
            <TextArea 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                autoSize={{ minRows: 4, maxRows: 4 }}
                value={ props.value }
                disabled={disabled}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}
/** @keyword Input Dropdownlist - 03**/
export const CDropdownlist = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> }</label>
            <Select
                showSearch={props.isFilter}
                optionFilterProp="children"
                filterOption={(input, option) => ((option?.value as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                onChange={ (value:any) => props.onChange({name:props.identificador, value}) }
                style={{ width: '100%' }}
                onSearch={() => {}}
                value={props.value}
                disabled={disabled}
                virtual={false}
            >
                { props.options?.map((row, index) => <Select.Option key={`${index}-${row.value}`} value={row.value}> {row.label} </Select.Option>) }
            </Select>
        </Col>
    )
}
/** @keyword Input CheckBox - 04**/
export const CCheckBox = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } </label>
            <br/>
                <Checkbox
                    onChange = { (e:any) => props.onChange({name:props.identificador, value: e.target.checked}) }
                    checked={ props.value }
                    disabled={disabled}
                >
                    Si
                </Checkbox>
        </Col>
    )
}
/** @keyword Input Radio Button - 05**/
export const CRadioButton = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } </label>
            <Radio.Group
                onChange = {(evt) => props.onChange({name:props.identificador, value: evt.target.value})}
                value = { props.value }
                disabled={props.readOnly}
            >
                {
                    props.options?.map(row => <Radio key={row.value} value={row.value}> {row.label} </Radio>)
                }
            </Radio.Group>
        </Col>
    )
}
/** @keyword Input Date - 06**/
export const CDate = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } </label>
            <DatePicker 
                onChange = { (value, text) => props.onChange({name: props.identificador, value: text}) }
                style={{width: '100%'}}
                value={AdapterGeneric.readDateFromInputsDate(props.value) as any}
                disabled={disabled}
            />
        </Col>
    )
}
/** @keyword Input DateTime - 07**/
export const CDateTime = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } </label>
            <DatePicker 
                onChange = {(value, text) => props.onChange({ name: props.identificador, value: text })}
                style={{width: '100%'}}
                value={AdapterGeneric.readDateFromInputsDate(props.value) as any}
                disabled={disabled}
            />
        </Col>
    )
}
/** @keyword Input Compare - 08 **/
export const CCompare = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } COMPARE DONT WORKING..</label>
            <Input 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                type={props.tipoDato}
                value={ props.value }
                disabled={props.readOnly}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}
/** @keyword Input Upload - 09**/
export const CUpload = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } UPLOAD DONT WORKING..</label>
            <Input 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                type={props.tipoDato}
                value={ props.value }
                disabled={props.readOnly}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}
/** @keyword Input Ubigeo - 10**/
export const CUbigeo = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } UBIGEO DONT WORKING..</label>
            <Input 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                type={props.tipoDato}
                value={ props.value }
                disabled={props.readOnly}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}
/** @keyword Input Concatenation - 11**/
export const CConcatenation = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } CONCATENATION DONT WORKING..</label>
            <Input 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                type={props.tipoDato}
                value={ props.value }
                disabled={props.readOnly}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}
/** @keyword Input Calculated - 12**/
export const CCalculated = (props:IPropsDefault) => {
    return(
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> } CALCULATED DONT WORKING..</label>
            <Input 
                onChange={(e:any) => props.onChange({name:props.identificador, value:e.target.value})}
                type={props.tipoDato}
                value={ props.value }
                disabled={props.readOnly}
                maxLength={props.textoMaxLength || undefined}
            />
        </Col>
    )
}

/** @keyword Input MultipleSelect - 13**/
export const CMultipleSelect = (props:IPropsDefault) => {
    const disabled = !!(props.readOnly || props.loadingBtnModal);
    const formatValue = (value: any[] | string | null) => {
        let result: string[] = [];

        if (!value) return result; // si viene vacío
        if (Array.isArray(value)) return value; // si ya tiene formato de array
        if (typeof value === 'string') return value.split(',').map(row => row.trim()); // si tiene formato en string

        return result;
    };

    return (
        <Col span={12} style={Style}>
            <label>&nbsp;&nbsp;{ props.etiqueta } { props.obligatorio && <a>*</a> }</label>
            <Select
                showSearch={props.isFilter}
                optionFilterProp="children"
                filterOption={(input, option) => ((option?.value as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                mode='multiple'
                allowClear
                onChange={ (value:any[]) => props.onChange({name:props.identificador, value: value.join(', ')}) }
                style={{ width: '100%' }}
                value={formatValue(props.value)}
                disabled={disabled}
            >
                { props.options?.map((row, index) => <Select.Option key={`${index}-${row.value}`} value={row.value}> {row.label} </Select.Option>) }
            </Select>
        </Col>
    )
}