import axios from 'axios';
import * as url from '../constants';

export const Stakeholder_Agrega_Stakeholdder = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.STAKEHOLDER_AGREGAR_STAKEHOLDER, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Actualiza_Stakeholdder = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.STAKEHOLDER_ACTUALIZAR_STAKEHOLDER, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Elimina_Stakeholder = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.STAKEHOLDER_ELIMINAR_STAKEHOLDER + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Lista_Stakeholder = (Body:object, Header:any) => {
    Header.params = Body;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.STAKEHOLDER_LISTA_STAKEHOLDER, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Stakeholder_Lista_Stakeholder2 = (Header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.STAKEHOLDER_LISTA_STAKEHOLDER2, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Stakeholder_Selecciona_Stakeholder = (Id:number, Header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.STAKEHOLDER_SELECCIONA_STAKEHOLDER + Id, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

/* TIPO */

export const Stakeholder_Agrega_Tipo_Stakeholdder = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.STAKEHOLDER_AGREGAR_TIPO_STAKEHOLDER, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Actualiza_Tipo_Stakeholdder = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.STAKEHOLDER_ACTUALIZAR_TIPO_STAKEHOLDER, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Elimina_Tipo_Stakeholder = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.STAKEHOLDER_ELIMINAR_TIPO_STAKEHOLDER + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Stakeholder_Lista_Tipo_Stakeholder = (body:object, Header:any) => {
    Header.params = body;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Stakeholder_Selecciona_Tipo_Stakeholder = (Id:number, Header:any) => {

    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER + Id, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}