import React from 'react';
import { TablePaginationConfig } from "antd";
import { List } from "../../../../components/lists/list";
import { EntityListOptions } from '../Domain/EntityListOptions';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';

interface ContainerProps {
    searchList(): void;
    resetFormFilter(): void;
    onPagination(evt: TablePaginationConfig): void;
    onChange(name: string, value: any): void;
    listOption: EntityListOptions;
    formFilters: EntityGenericForm;
}

export const ViewContentVariables = (props: ContainerProps) => {
    return (
        <div className="content_list">
            {/*
            <div className="search ff-Mont-Family" style={{borderRadius: "10px 10px 0px 0px"}}>
                <div className="inputs">
                    <p>VARIABLE:</p>
                    <Input value={props.formFilters.values['variable']} onChange={(evt) => props.onChange('variable', evt.target.value)} />
                </div>
                <div className="inputs">
                    <p>PROCESO:</p>
                    <Select
                        mode="tags"
                        allowClear
                        value={props.formFilters.values['proceso']}
                        onChange={(evt) => props.onChange('proceso', evt.target.value)}
                        style={{ width: '180px' }}
                        dropdownMatchSelectWidth={false}
                    >
                    </Select>
                </div>
                <div className="inputs">
                    <p>FECHA DE CARGA:</p>
                    <DatePicker placeholder='Fecha Inicio' value={props.formFilters.values['fechaInicio']} onChange={(value) => props.onChange('fechaInicio', value)}/>
                    <DatePicker placeholder='Fecha Fin' value={props.formFilters.values['fechaFin']} onChange={(value) => props.onChange('fechaFin', value)}/>
                </div>
                <div className="inputs" style={{paddingLeft: '60px'}}>
                    <Button loading={false} onClick={props.searchList}>Buscar</Button>
                </div>
                <div className="inputs">
                    <Button loading={false} style={{ width:'50px' }} onClick={props.resetFormFilter}> <RedoOutlined/></Button>
                </div>
            </div>
            */}
            <div className="list" style={{paddingBottom: '30px', marginTop: 40}}> 
                <List
                    datasource={props.listOption.dataTable}
                    columns={props.listOption.metadata}
                    total={props.listOption.total}
                    onPagination={props.onPagination}
                    current={props.listOption.currentPage}
                /> 
            </div>
        </div>
    )
}