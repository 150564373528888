import React from 'react';
import './ViewHome.scss';
import { EntityData } from '../Domain/EntityData';

interface ContainerProps {
    list: EntityData[];
    title?: any;
    redirectDetail: (payload: EntityData) => void;
}

export const ViewHome = (props: ContainerProps) => {
    return (
        <div className='Content ViewHome'>
            <div>
                <h2 className='ViewHome-title'> { props.title || <>REVISA LAS PROPUESTAS <br/> QUE TENEMOS DISPONIBLES Y VOTA:</> }</h2>

                <div className='ListHome'>
                    {
                        props.list.map(row =>
                            <div className='ItemHome' key={row.id} onClick={() => props.redirectDetail(row)}>
                                <h3>{row.vote_name}</h3>
                                <p>{parseInt(row.publish_date) > 0 ? `Termina en ${row.publish_date} días` : `Terminó hace ${parseInt(row.publish_date) * -1} días`}</p>
                                <span>Votar</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}