import React from 'react';
import { FooterLandingPage } from '../../../../shared/components/FooterLandingPage';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';
import LogoEmpresaColor from './../../../../assets/images/logo/logo-empresa-color.svg';
import { ViewContentForm } from './Content/ViewContentForm';
import { ViewContentSuccess } from './Content/ViewContentSuccess';
import './style.scss';
import { EntityConfigModule } from '../Domain/EntityUtils';

interface ContainerProps {
    onSubmit(evt: React.FormEvent<HTMLFormElement>): Promise<any>;
    handleAddNFT(evt: React.FormEvent<HTMLFormElement>): Promise<any>;
    CopyUrlNFT(): void;
    formWallet: EntityGenericForm;
    currentStep: number;
    address: string | undefined;
    connectWallet: any;
    configModule: EntityConfigModule;
    loading: boolean;
}

export const View = (props: ContainerProps) => {
    return (
        <div className="ViewNFT">
            <header>
                <div className='ContentHeader'>
                    <img src={LogoEmpresaColor} alt ="Logo Empresa"/>
                    {
                        props.address ? <button> <span>Cuenta: {props.address}</span> </button> : <button onClick={props.connectWallet}> conectar mi Metamask </button>
                    }
                </div>
            </header>
            <div className='Content'>
                <div className='Banner'>
                    <div className='ContentBanner'>
                        { props.currentStep === 1 ? <span className='fw-MontBold'>Estás a un paso de recibir tu NFT</span> : null }
                        { props.currentStep === 2 ? <span className='fw-MontBold'>¡Todo listo!</span> : null }
                    </div>
                </div>
                <section className='Information'>
                    <div className='ContentInformation'>
                        {
                            props.configModule.code === 'error' || props.configModule.code === 'reclaimed' ?
                            <div className='GroupText ff-Mont-Family' style={{ width: '100%', textAlign: 'center' }}>
                                <span>{props.configModule.text}</span>
                            </div>
                            : null
                        }
                        {
                            props.configModule.code === 'pending' ?
                                <>
                                    <ViewContentForm loading={props.loading} formWallet={props.formWallet} onSubmit={props.onSubmit} show={props.currentStep === 1} />
                                    <ViewContentSuccess formWallet={props.formWallet} handleAddNFT={props.handleAddNFT} show={props.currentStep === 2} />
                                </>
                            : null
                        }
                        {
                            props.configModule.code === 'notSupport' ?
                            <div className='GroupText ff-Mont-Family' style={{ width: '100%', textAlign: 'center' }}>
                                <span>
                                    La funcionalidad de obtención de NFT solo está disponible en PC.
                                    Por favor, accede a esta página desde tu computadora: <br/> <strong> {window.location.href} <i onClick={props.CopyUrlNFT} style={{ marginLeft: 10 }} className="fa-regular fa-copy" /> </strong>
                                </span>
                            </div>
                            : null
                        }

                    </div>
                </section>
                <FooterLandingPage />
            </div>
        </div>
    )
}