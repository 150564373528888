/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ViewHome } from './ViewHome';
import LogoEmpresa from './../../../../../assets/images/logo/logo-empresa.svg';
import { EntityData } from '../Domain/EntityData';
import './style.scss';
import { FooterLandingPage } from '../../../../../shared/components/FooterLandingPage';


interface ContainerProps {
    connectWallet: any;
    address: string | undefined;
    list: EntityData[];
    redirectDetail: (payload: EntityData) => void;
}

const ViewPropuesta = (props: ContainerProps) => {

    return (
        <div className='ViewPropuesta'>
            <div className='Banner'>
                <div className='Group'>
                    <div className="ContainerHeaderContent ContainerHeaderContent1">
                        <img src={LogoEmpresa} alt="logo" />
                        {
                            props.address ? <button> <span>Cuenta: {props.address}</span> </button> : <button onClick={props.connectWallet}> conectar mi Metamask </button>
                        }
                    </div>
                    <div className='GroupContent'>
                        <div className="ContainerBannerContent1">
                            <h1> ¿DE QUÉ SE TRATA? </h1>
                            <p>En SDF queremos empoderar el trabajo de hilanderos y tejedores peruanos. Por ello, todas aquellas personas que compraron una prenda de nuestro proyecto y obtuvieron su NFT, pueden ser parte de las nuevas iniciativas que llevamos a cabo</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ContainerTemplate1">
                <ViewHome list={props.list} redirectDetail={props.redirectDetail}/>
            </section>
            <FooterLandingPage />
        </div>
    )
}

export default ViewPropuesta;