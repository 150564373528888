import React, { useState, useEffect } from 'react'
import TemplateLogin from './../components/Login';
import API_SERVICE from './../services/api';
import { Input, Form, Button } from 'antd';
import { Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
const Style = {margin: '0em'};

const ViewForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({ messageError: null, messageSucceful: null });
    const [data, setData] = useState({
        email:'',
        password:'',
        password2:'',
        hash:''
    });

    const location = useLocation();

    useEffect(() => {
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = () => {
        const params = new URLSearchParams(location.search);
		const hash = params.get('guid') || '';

		API_SERVICE.Cuenta_Valida_Hash_Activa_Cuenta(hash)
		.then((res) => {
			const { result } = res.data;
			if(result === false) window.location.href = '/login';
            setData((prev: any) => ({...prev, hash}))
		})
		.catch(() => {
			window.location.href = '/login';
		})
    }

    const onSubmit = (e:any) => {
        setLoading(true);
        const { email, password, hash} = data;
        
        API_SERVICE.Cuenta_Activa_Cuenta({email, password, hash})
        .then((res:any) => {
            const { status, data } = res;
            if(status === 200 || status === 201 ){
                setMessages({ messageError: null, messageSucceful: data.message })
                // eslint-disable-next-line no-implied-eval
                setTimeout(window.location.href = '/login', 10000);
            }
        })
        .catch((err) => {
            const msg = err.response.data.responseException.exceptionMessage;
            msg.errors ?
                setMessages({ messageError: msg.errors.Email[0], messageSucceful: null }) :
                setMessages({ messageError: msg, messageSucceful: null });
        })
        .finally(() => {
            setLoading(false);
        })
	}

	const onChange = (value:any) => setData((prev:any) => ({ ...prev, ...value }));

    return (
        <TemplateLogin>
				<Form
                    onFinish={onSubmit}
                >
					<div>
						<div className="header">
							<label className="title">Activar Cuenta</label>
							<p className="info">
								Establece una contraseña para activar tu cuenta.
							</p>
						</div>
						<div className="message">
                            { messages.messageError &&  <Spin spinning={loading}><div className="msgError"> <a>x</a><label>{messages.messageError}</label> </div> </Spin>}
                            { messages.messageSucceful &&  <Spin spinning={loading}><div className="msgSuccess"> <a>✓</a><label>{messages.messageSucceful}</label> </div> </Spin>}
						</div>
						<div className="body">

							<div className="block-input" style={Style}>
								<label>&nbsp;&nbsp;Correo Electrónico:</label>
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Ingrese un Correo' }]}
                                >
                                    <Input 
                                        type="email"
                                        placeholder="Correo"
                                        value={ data.email }
                                        onChange={ (e:any) => onChange({email: e.target.value}) }
                                    />
                                </Form.Item>
							</div>

							<div className="block-input" style={Style}>
								<label>Nueva contraseña:</label>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Ingrese una Contraseña' }]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        type="password" 
                                        placeholder="nueva contraseña"
                                        value={ data.password } 
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                                        onChange={ (e:any) => onChange({password: e.target.value}) }
                                    />
                                </Form.Item>
							</div>

                            <div className="block-input" style={Style}>
								<label>Repite la contraseña:</label>
                                <Form.Item
                                    name="password2"
                                    dependencies={['password']}
                                    rules={[{ required: true, message: 'Repite la contraseña'},
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject('La contraseña no coinciden');
                                          },
                                        }),
                                      ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        type="password" 
                                        placeholder="nueva contraseña"
                                        value={ data.password2 } 
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                                        onChange={ (e:any) => onChange({password2: e.target.value}) }
                                    />
                                </Form.Item>
							</div>

						</div>
						<div className="submit" style={Style} >
                            <Form.Item >
								<Button type="primary" htmlType="submit" loading={loading}> Activar </Button>
                            </Form.Item>
						</div>
					</div>
				</Form>
			</TemplateLogin>
    )
};

export default ViewForgotPassword;