import React, { useEffect } from 'react';
import ViewPropuesta from './Components/View';
import { Controller } from './Infraestructure/Controller';
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import { AdapterScroll } from '../../../../shared/Infraestructure/AdapterScroll';

const View = () => {
    const controller = Controller();

    useEffect(() => {
        controller.reloadList();
        AdapterScroll.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        controller.checkBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.address, controller.editionDrop])

    return (
        <ViewPropuesta
            connectWallet={controller.connectWallet}
            redirectDetail={controller.redirectDetail}
            address={controller.address}
            list={controller.list}
        />
    )
}
        
const ViewWebProvider = () => {
    const activeChainId = ChainId.Mumbai;
    return (
        <ThirdwebProvider desiredChainId={activeChainId}>
            <View />
        </ThirdwebProvider>
    )
}

export default ViewWebProvider;