export interface EntityProposal {
    id: string
    vote_name: string
    creator_id: string
    token_address: string
    governor_address: string
    box_address: string
    proposal_id: string
    voting_period: number
    token_name: string
    token_symbol: string
    quorum_percent: number
    proposal_description: string
    already_voters: any
    options: Options
    errors: number
    creation_date: string
    updated_date: string
    isPublished: boolean
    publish_date: string
}
    
export interface Options {
    for: number
    against: number
    absteined: number
}

export const initEntityProposal: EntityProposal = {
    id: "",
    vote_name: "",
    creator_id: "",
    token_address: "",
    governor_address: "",
    box_address: "",
    proposal_id: "",
    voting_period: 0,
    token_name: "",
    token_symbol: "",
    quorum_percent: 0,
    proposal_description: "",
    options: {
        absteined: 0,
        against: 0,
        for: 0
    },
    errors: 0,
    creation_date: "",
    updated_date: "",
    already_voters: 0,
    isPublished: false,
    publish_date: ''
}