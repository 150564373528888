import { useState } from 'react';
import { useFormik } from "formik";
import { EntityListOptions, EntityListOptionsInit } from "../Domain/EntityListOptions";
import * as Yup from 'yup';
import { EntityTabOptions, EntityTabOptionsInitial } from '../Domain/EntityTabOptions';
import { EntityData } from '../Domain/EntityData';
import { EntityModalOptions, EntityModalOptionsInitial } from '../Domain/EntityModalOptions';
import { AdapterValidator } from '../../../../shared/Infraestructure/AdapterValidator';
import { AdapterLocalStorage } from '../../../../shared/Infraestructure/AdapterLocalStorage';
import { message } from 'antd';
import axios from 'axios';
import { EntityDataDetailInit, EntityModalDetail } from '../Domain/EntityModalDetail';
import { EntityDataDetail } from '../Domain/EntityDataDetail';
import { EntityGenericMetadata } from '../../../../shared/Domain/EntityGenericMetadata';
import { baseURLSet } from '../../../../services/constants';

export const Controller = () => {
    const [listOption, setListOption] = useState<EntityListOptions>(EntityListOptionsInit);
    const [tabOption, setTabOption] = useState<EntityTabOptions>(EntityTabOptionsInitial)
    const [loadingFilter, setLoadingFilter] = useState(false);

    const { closeModal, modalOption, formModal, loadingFormModal, onChangeFormModal, openModal, onSaveModal, closeModalDetail, loadingDetailModal, modalDetail, openModalDetail } = ControllerModal();

    const formFilter = useFormik({
        initialValues: { variable: '', proceso: [], fechaFin: null, fechaInicio: null },
        onSubmit: () => {},
        validationSchema: Yup.object({
            variable: Yup.string().nullable(),
            proceso: Yup.array().nullable(),
            fechaFin: Yup.date().nullable(),
            fechaInicio: Yup.date().nullable(),
        })
    })

    const init = async () => {
        setListOption(prev => ({
            ...prev,
            metadata: [
                { key: 'variable', dataIndex: 'variable', title: 'Variable' },
                { key: 'descripcion', dataIndex: 'descripcion', title: 'Descripción' },
                { key: 'detail', dataIndex: 'detail', title: 'Detalle', render: (currentText: string, dataComplete: EntityData) => <i onClick={() => openModalDetail(`Detalle - ${dataComplete.variable}`)} className="fa-solid fa-eye iconTable" /> },
                { key: 'cargarNuevo', dataIndex: 'cargarNuevo', title: 'Cargar nuevo', render: (currentText: string, dataComplete: EntityData) => <i onClick={() => openModal(dataComplete)} className="fa-solid fa-file-arrow-up iconTable" /> }
            ],
            dataTable: [{ variable: 'SIPYC', descripcion: 'Listado de números de partida + parámetros de calidad.', id: 1, key: 1 }]
        }))

        setTabOption(prev => ({
            currentKey: 'variable',
            tabs: [{ key: 'variable', label: 'VARIABLES' }]
        }))
    }
    const onPagination = (evt: any) => {}
    const onChangeFilter = (name: string, value: any) => formFilter.setFieldValue(name, value);
    const resetFormFilters = () => {
        formFilter.resetForm();
        searchList();
    }
    const searchList = () => {
        setLoadingFilter(true);
        setLoadingFilter(false);
    }

    const onChangeTab = (newKey: string) => setTabOption(prev => ({...prev, currentKey: newKey }))

    return ({
        init,
        onPagination,
        onChangeFilter,
        resetFormFilters,
        searchList,
        onChangeTab,
        setListOption,
        closeModal,
        onChangeFormModal,
        onSaveModal,
        closeModalDetail,
        
        formModal,
        formFilter,
        loadingFilter,
        listOption,
        tabOption,
        modalOption,
        loadingFormModal,
        loadingDetailModal,
        modalDetail
    })
}

const ControllerModal = () => {
    const [modalOption, setModalOption] = useState<EntityModalOptions>(EntityModalOptionsInitial);
    const [modalDetail, setModalDetail] = useState<EntityModalDetail>(EntityDataDetailInit);
    const [loadingFormModal, setLoadingFormModal] = useState(false);
    const [loadingDetailModal, setLoadingDetailModal] = useState(false);
    
    const formModal = useFormik({
        initialValues: { file: null },
        onSubmit: () => {},
        validationSchema: Yup.object({
            file: Yup.mixed().required('Ingrese un archivo').nullable()
        })
    })
    
    const onChangeFormModal = (name: string, value: any) => formModal.setFieldValue(name, value);
    const openModal = (payload: EntityData) => setModalOption(prev => ({title: 'Cargar nuevo', show: true, payload}));
    const closeModal = () => {
        setModalOption(prev => ({title: '', show: false, payload: undefined}));
        formModal.resetForm();
    }
    const onSaveModal = async () => {
        try { await formModal.submitForm(); } catch (error) { }
        try { AdapterValidator.validate(await formModal.validateForm(), 'custom') } catch (error) { message.error((error as Error).message); return; }
        setLoadingFormModal(true)

        try {
            const formData = new FormData();
            formData.append('file', formModal.values.file || '');
            const response = await axios.post(`${baseURLSet.default}client/proceso/carga-certificados-a-lista`, formData, { headers : { Authorization: `Bearer ${AdapterLocalStorage.get('token').token}` } })
            console.log(response);
            message.success(`El archivo ha sido guardado correctamente`);
            closeModal();
        } catch(error) {
            message.error('Ocurrió un error al subir el archivo');
        } finally {
            setLoadingFormModal(false);
        }
    }


    const closeModalDetail = () => setModalDetail(() => EntityDataDetailInit);
    
    const openModalDetail = (title: string) => {
        setModalDetail(prev => ({...prev, show: true, title: title }));
        setLoadingDetailModal(true);
        callServiceListDetail();
    }
    
    const callServiceListDetail = async () => {
        try {
            const response: EntityDataDetail = (await axios.get(`${baseURLSet.default}client/proceso/selecciona-metadatalista/13`, { headers : { Authorization: `Bearer ${AdapterLocalStorage.get('token').token}` } })).data.result;
            if (response.metadataListaItem.length > 0) {
                let payload = response.metadataListaItem.map(row => ({ codigo: row.valor, ...JSON.parse(row.valor3) }));
                let metadata: EntityGenericMetadata[] = [
                    { dataIndex: 'codigo', key: 'codigo', title: 'CÓDIGO' },
                    { dataIndex: 'TITULO PROMEDIO', key: 'TITULO PROMEDIO', title: 'TITULO PROMEDIO' },
                    { dataIndex: 'RESISTENCIA A LA ROTURA', key: 'RESISTENCIA A LA ROTURA', title: 'RESISTENCIA A LA ROTURA' },
                    { dataIndex: 'RESISTENCIA A LA ROTURA', key: 'RESISTENCIA A LA ROTURA', title: 'RESISTENCIA A LA ROTURA' },
                    { dataIndex: 'PESO POR METRO PROMEDIO', key: 'PESO POR METRO PROMEDIO', title: 'PESO POR METRO PROMEDIO' },
                    { dataIndex: 'HUMEDAD PROMEDIO', key: 'HUMEDAD PROMEDIO', title: 'HUMEDAD PROMEDIO' },
                    { dataIndex: 'ENSIMAJE PROMEDIO', key: 'ENSIMAJE PROMEDIO', title: 'ENSIMAJE PROMEDIO' },
                    { dataIndex: 'ENCOGIMIENTO', key: 'ENCOGIMIENTO', title: 'ENCOGIMIENTO' },
                    { dataIndex: 'LONGITUD PROMEDIO', key: 'LONGITUD PROMEDIO', title: 'LONGITUD PROMEDIO' },
                    { dataIndex: 'RESISTENCIA METRICA', key: 'RESISTENCIA METRICA', title: 'RESISTENCIA METRICA' },
                    { dataIndex: 'IRREGULARIDAD PROMEDIO', key: 'IRREGULARIDAD PROMEDIO', title: 'IRREGULARIDAD PROMEDIO' },
                    { dataIndex: 'FIBRAS MENORES A 20 mm', key: 'FIBRAS MENORES A 20 mm', title: 'FIBRAS MENORES A 20 mm' },
                    { dataIndex: 'INDICE TOTAL SF PARA TEÑIDO', key: 'INDICE TOTAL SF PARA TEÑIDO', title: 'INDICE TOTAL SF PARA TEÑIDO' },
                ];
                setModalDetail(prev => ({...prev, payload, metadata}));
            }
        } catch (error) {
            
        } finally {
            setLoadingDetailModal(false);
        }
    }

    return ({
        openModal,
        closeModal,
        onChangeFormModal,
        onSaveModal,
        modalOption,
        formModal,
        loadingFormModal,

        closeModalDetail,
        openModalDetail,
        loadingDetailModal,
        modalDetail,
    })
}