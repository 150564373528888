import { ThirdwebProvider } from '@thirdweb-dev/react';
import { ChainId } from '@thirdweb-dev/sdk';
import React, { useEffect } from 'react';
import { View } from './components/View';
import { Controller } from './Infraestructure/Controller';
import { ElementLoadingLanding } from '../../../shared/components/ElementLoadingLanding';

const ViewMain = () => {
    const controller = Controller();

    useEffect(() => {
        controller.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!controller.address) return;
        controller.onChange('wallet', controller.address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.address]);

    return (
        <>
            <View
                onSubmit={controller.onSubmit}
                connectWallet={controller.connectWallet}
                handleAddNFT={controller.handleAddNFT}
                CopyUrlNFT={controller.CopyUrlNFT}
                formWallet={controller.formWallet}
                currentStep={controller.currentStep}
                address={controller.address}
                configModule={controller.configModule}
                loading={controller.loading}
            />
            <ElementLoadingLanding
                show={controller.loading}
            />
        </>
    )
}

const ViewWebProvider = () => {
    const activeChainId = ChainId.Mumbai;
    return (
        <ThirdwebProvider desiredChainId={activeChainId}>
            <ViewMain />
        </ThirdwebProvider>
    )
}

export default ViewWebProvider;