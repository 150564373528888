/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ViewMain } from './Components/ViewMain';
import { Controller } from './Infraestructure/Controller';

const View = () => {
    const controller = Controller();

    useEffect(() => {
        controller.setupConfig()
    }, [controller.stepContent])

    useEffect(() => {
        controller.callServiceCatalogo();
    }, [])

    useEffect(() => {
        controller.setupConfigCatalog()
    }, [controller.catalogoItems, controller.stepContent])

    return (
        <ViewMain
            onChangeStep={controller.onChangeStep}
            stepContent={controller.stepContent}
            catalogoItems={controller.catalogoItems}
            configOnChangeScreen={controller.configOnChangeScreen}
            showViewError={controller.showViewError}
            dataGeneral={controller.dataGeneral}
        />
    )
}

export default View;