/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DashboardTemplate from './Dashboard';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './../assets/styles/options.scss'

interface ContainerProps {
    title: string;
    height: string;
    children: JSX.Element;
}

const OptionTemplate = (props: ContainerProps) => {
    const navigate = useNavigate();

    return (
        <DashboardTemplate>
                <div className="templateOption">
                    <div className="link">
                        <a onClick={ () => navigate('/tablero-control') }> <ArrowLeftOutlined/> {`Regresar`} </a>
                    </div>
                    <div className="panel-option">
                        <div className="op-title">
                            <div>
                                { props.title }
                            </div>
                        </div>
                        <div className="op-content" style={{height: props.height}}>
                            <div>
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardTemplate>
    )
}

export default OptionTemplate