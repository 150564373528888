/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ToggleMenuWrapper from '../../../components/ToggleMenuWrapper';
import { Breadcrumb, Modal, Row, Col, Space, Button, message, Spin, Select } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import './../../../assets/styles/lists.scss';
import { ListCollapse } from './../../../components/lists/list';
import { TITLE__PROCESO } from './../../../global/title-modules';
import { DictionaryValidationType } from './../../../components/Form/Dictionary';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import TipoControl from './../../../components/Form';
import { connect } from 'react-redux';
import { AdapterLocalStorage } from '../../../shared/Infraestructure/AdapterLocalStorage';
import { EntityEtapa, EntityMetadataEtapa } from './List/Domain/EntityEtapa';
import { Link } from 'react-router-dom';
import { EntityUser } from '../../../shared/Domain/EntityUser';
import { EntityLista } from './List/Domain/EntityListas';

let userData: EntityUser = JSON.parse(AdapterLocalStorage.get('user').user)

class Processes extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			list:[],
			visible:false,
			data: {
				idEtapaGrupoCadenaValor: 0,
				jsonData:{}
			},
			listEtapa:[],
			listRegistro:[],
			loading:false,
			formModal: [],
			listProduct: [],
			titleModal: '',
			Etapas: [],
			dataUser: {},
			loadingBtnModal: false,
			readOnlyModal: false
		};
	}

	componentDidMount(){
		const dataUser = JSON.parse(AdapterLocalStorage.get('user').user);
		this.setState({loading:true, dataUser})

		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Proceso_Metadata_Call(resolve, reject, this.handleOpenModal);
		})
		.then(() => {
			const { list_Metadata : {etapas, listas} } = this.props;
			this.setState({list: etapas, loading:false, listas})
		})
		.catch(() => {
			this.setState({loading:false});
			message.error('Error al listar');
		})
	}

	callData = (IdEtapa:number) => {
		//validar si ya se obtuvo la lista.
		const list = this.state.list;
		for(let i = 0; i < list.length; i++){
			if(list[i].id === IdEtapa && list[i].get === true)  return;
		}
		
		const data = { IdEtapa } 

		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Proceso_Etapa_Call(data, resolve, reject);
		})
		.then(() => {
			//se pasa el Id para agregar la data al grupo
			this.addList(IdEtapa);
		})
		.catch((err:any) => {
			if(Array.isArray(err)) 
				err.map((item:any) => message.error(item));
			else
				message.error(err);
		})
	}

	addList = (Id:number) => {
		//Funcion para agregar la lista a un grupo 
		const list = this.state.list;
		const { onlyOne } = this.props;

		for(var i=0; i < list.length; i++){
			if(list[i].id === Id){

				list[i].datasource = onlyOne;
				list[i].get = true;

				this.setState({list})

				return;
			}
		}
	}
	/*----------------------Modal------------------------------ */
	renderModal = () => {
		let { data, list, titleModal, loadingBtnModal, readOnlyModal } = this.state, renderFuente:boolean = false;
		//busca al grupo que se encuentra y verfica si se muestra la seccion Fuente.

		const Grupo = (list.find((element:any) => element.id === data.idEtapaGrupoCadenaValor));
		if(Grupo) renderFuente = Grupo.asignaFuente;

		return( 
			<Modal
			title={ this.renderHeaderModal(titleModal, data.id) }
			open={this.state.visible}
			onCancel={ () => this.handleCloseModal() }
			footer={null}
			width={650}
		  >		
		  	<Spin 
			  indicator={<SettingFilled spin/>}
			  spinning={loadingBtnModal}
			  style={{color:'black'}}
			  tip={'Estamos registrando la información en el Blockchain, este proceso podría tardar algunos segundos.'}
			  className='ff-Mont-Family'
			>
			   { renderFuente && this.renderContentFuente() }
			   <br/>
			   <p className='ff-Mont-Family'>Registro:</p>
			   { this.renderContentRegistro() }

			   <div className='buttons'>
				  <div className='cancel'>
				    <button onClick={() => this.handleCloseModal() }>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<Button 
					  onClick={() => this.handleSaveModal()}
					  loading={loadingBtnModal}
					  disabled={readOnlyModal}
					  className={ readOnlyModal ? 'btn-disabled' : '' }
					>
						Guardar
					</Button>
				  </div>
			    </div>
			  </Spin>
		  </Modal>
		)
	}

	renderHeaderModal = (grupo:string, Id?: string) => {
		let render = null;
		if(Id){
			render = [
				<Breadcrumb.Item >  { grupo }  </Breadcrumb.Item>,
				<Breadcrumb.Item > <p> { Id } </p> </Breadcrumb.Item>
			]
		}
		else {
			render = <Breadcrumb.Item > <p> { grupo } </p> </Breadcrumb.Item>;
		}

		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item> {TITLE__PROCESO} </Breadcrumb.Item>
					{ render }
				</Breadcrumb>
			</div>
		)
	}

	renderContentRegistro = () => {
		const { formModal, data, listProduct, listas, readOnlyModal, loadingBtnModal } = this.state;
		let Left:any, Rigth:any, Contador:number= 1, value:string, options:any[], isFilter: boolean = false;

		return (
			<div className='form__Modal'>
				{
					formModal.map((item: EntityMetadataEtapa, index: number) => {

						//buscamos si el componente tiene una lista
						if(item.idLista !== undefined || item.idLista !== null){
							const option: EntityLista = (listas as EntityLista[]).find(data => data.id === item.idLista) || { items: [], idPadre: 0, id: 0, isFilter: false };
							isFilter = option.isFilter;
							options = option.items;
						}
						//filtra el listado (opciones) por el valor que tiene el identificadorDepende
						if (item.identificadorDepende) {
							const valueDepende = this.state.data.jsonData[item.identificadorDepende] || '';
							options = options?.filter(row => row.dataComplete.valorPadre === valueDepende) || [];
						}
						//si el campo identificadorGrupo se escoge una opción diferente al valor asignado no se mostrará
						if (!!item.identificadorGrupo) {
							const valueFromIdentificadorGrupo = this.state.data.jsonData[item.identificadorGrupo] || '';
							if (valueFromIdentificadorGrupo !== item.grupoValue) return null;
						}
						//damos el valor de donde se almacenará.
						value = item.identificador === 'descripcion' ? data[item.identificador] : data.jsonData[item.identificador];

						//validamos si es Tipo PIN
						if(item.identificador === 'pin')
							options = listProduct
						
						//aca damos toda la información para las propiedades del TipoControl
						const props= {
							...item,
							value,
							onChange: this.onChange,
							options,
							readOnly: readOnlyModal || item.soloLectura,
							loadingBtnModal,
							isFilter
						};

						Contador % 2 ? Left = <TipoControl {...props}/> : Rigth = <TipoControl {...props}/>;
						Contador+=1;

						//enviamos las propiedades y retorna el tipo de control
						if((Contador % 2 === 1) || (formModal.length - 1 === index)) { 
							let renderLeft = Left, renderRigth = Rigth;
							Left=null;
							Rigth=null;
							return 	(
								<Row key={Contador} className='ff-Mont-Family'>
									{ renderLeft }
									{ renderRigth }
								</Row>
							)
						}

						return null;
					})

				}
			</div>
		) 
	} 

	renderContentFuente = () => {
		const { listEtapa, listRegistro, data, list, Etapas } = this.state;
		//buscamos al grupo que se encuentra
		const Grupo: EntityEtapa = (list.find((element: EntityEtapa) => element.id === data.idEtapaGrupoCadenaValor));

		//Buscamos si es multiselect para enviar a la plantilla de select. Para verficar el modelo
		const selectProceso = this.handleTemplateSelect(Etapas, listEtapa, 'Etapas', Grupo.multiSelectProceso);
		const selectRegistro = this.handleTemplateSelect(data.idsProcesoPadre, listRegistro, 'idsProcesoPadre', Grupo.multiSelectRegistro);

		return (
			<>
				<span className='ff-Mont-Family'> Fuente </span>
				<div className='form__Modal ff-Mont-Family'>
					<Row gutter={[6,16]}>
						<Col span={12} style={{padding:'8px 12px'}}>
							<label>&nbsp;&nbsp;Proceso <a> {` *`} </a> </label><br/>
							<Space direction="vertical" style={{ width: '100%' }}>
								<Select {...selectProceso} virtual={false} ></Select>
							</Space>
						</Col>
						<Col span={12} style={{padding:'8px 12px'}}>
							<label>&nbsp;&nbsp;Registro <a> {` *`} </a> </label><br/>
							<Space direction="vertical" style={{ width: '100%' }}>
								<Select {...selectRegistro} virtual={false} ></Select>
							</Space>
						</Col>
					</Row>
				</div>
			</>
		)
	}

	handleTemplateSelect = (value:any, options:any, name:string, multiSelect:boolean) => {
		const { readOnlyModal } = this.state;

		if(multiSelect)
			return({
				mode: 'multiple' as const,
				style: { width: '100%' },
				value,
				options,
				onChange: readOnlyModal ?  () => {  } : (value: string[]) => this.onChange({name, value}, true),
				allowClear: true,
				maxTagCount: 1
			})
		else 
			return({
				style: { width: '100%' },
				value,
				options,
				onChange: readOnlyModal ?  () => {  } : (value: any) => this.onChange({name, value}, true),
				allowClear: true,
				maxTagCount: 1
			})
	}

	handleCloseModal = () => {
		this.setState({
			data:{
				jsonData:{}, 
				idsProcesoPadre:[]
			}, 
			visible:false, 
			formModal:[], 
			listRegistro:[], 
			listProduct:[], 
			Etapas:[], 
			loadingBtnModal: false,
			readOnlyModal: false
		})
	}

	handleSaveModal = () => {
		const validation = this.validation();
		if(validation.length > 0) {
			for(var i=0;i < validation.length; i++)
				message.error(validation[i]);
			return;
		}
		
		if(this.state.edit) this.functionsCrud(3);
		else this.functionsCrud(2);
	}

	handleOpenModal = (payload:any, type:number) => {
		this.functionsCrud(type, payload);
	}

	handleFunctionButton = (evt: any, name: string, value: EntityEtapa) => {
		//Funcion del boton del ListCollapse (Crear)
		evt.stopPropagation();
		//rellena el combobox de Fuente-Proceso // si tiene habilitado el AsignaFuente
		if(value.asignaFuente) this.handleSearchEtapas(value.id);
		//creamos un nuevo dato harcodeado para el formModal (descripcion).
		const etiquetaDescripcion = value.headers.find(row => row.key.toLowerCase() === 'descripcion')?.title || 'Descripción';
		const descripcion: EntityMetadataEtapa[] = [{
			identificador: "descripcion",
			etiqueta : etiquetaDescripcion,
			tipoDato : "01",
			idLista: null,
			tipoControl : "01",
			obligatorio : true,
			textoMaxLength : null,
			mensajeError: `Ingresa ${etiquetaDescripcion}`,
			identificadorDepende: null,
			tipoValidacion: null,
			soloLectura: false,
			defaultValue: "",
			esAgrupador: false,
			grupoValue: false,
			identificadorGrupo: null
		}]

		//Formato de la información antes de iniciar el modal-crear
		const payload = { 	
			data: {
				idEtapaGrupoCadenaValor: value.id,
				jsonData: {},
				idsProcesoPadre: []
			},
			formModal: [...(value.ocultaDescripcion ? [] : descripcion), ...value.metadata],
			titleModal: name,
		}

		// Guarda los valores iniciales
		let initialValues: any = {};
		value.metadata.forEach(row => {
			if (!row.defaultValue) return;
			if (row.identificador === "certificados") {
				initialValues[row.identificador] = row.defaultValue.split("|").join(', ');
			} else {
				initialValues[row.identificador] = row.defaultValue;
			}
		});
		payload.data.jsonData = { ...this.state.jsonData, ...initialValues };

		//Selecciona la función para guardar en el estado.
		this.functionsCrud(4, payload);
	}

	/* Funciones cbo Modal */

	handleSearchEtapas = (Id:number) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Etapas_Parent_Call(Id, resolve, reject)
		})
		.then(() => {
			const { listEtapa } = this.props;
			const templateListEtapa: Array<object> = []

			listEtapa.map((item:any) => templateListEtapa.push({label: item.Nombre, value:item.Id}))

 			this.setState({listEtapa:templateListEtapa}); 
		})
		.catch(() => {
		});
	}

	handleSearchRegister = (value: Array<number>) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Registros_Parent_Call(value, resolve, reject)
		})
		.then(() => {
			const { listRegistro } = this.props, { data } = this.state, { idsProcesoPadre } = data;
			const newIdsProcesoPadre:Array<number> = [], templateListRegistro: Array<Object> = [] ;
			//Removemos los ids de "Registro" que no esten seleccionados en "Proceso".
			idsProcesoPadre.forEach((item:number) => {
				if(listRegistro.find((element:any) => element.Id === item))
					newIdsProcesoPadre.push(item);
			})
			//formato para data.
			const newData = { ...data, idsProcesoPadre: newIdsProcesoPadre }
			//formato para lista de "Registros".
			listRegistro.map((item:any) => templateListRegistro.push({label: item.Descripcion, value: item.Id}));

			this.setState({listRegistro: templateListRegistro, data: newData, Etapas: value})
		})
		.catch((err) => {
			message.error(err);
		})
	}

	handleListProductCode = (id:number) => {
		return new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Producto_Code_Call(id, resolve, reject)
		})
		.then(() => {
			const { listProduct } = this.props;
			const { data } = this.state;

			this.setState({listProduct, data:{
				...data,
				jsonData: {
					...data.jsonData,
					pin: ''
				}
			}})
		})
		.catch(() => {
			
		})
		//this.setState({listProduct: [{label: id, value: id}]})
	}

	/*------------------------------------------------- */

	onChange = ({name, value}: any, type?: boolean) => {

		if(name === "Etapas" || name === "descripcion" || name === 'idsProcesoPadre') return this.functionsOnChange({name, value});
		// busca inputs dependientes de tipo texto o textarea, para autocompletar su valor.
		const listIdentifierDepende = (this.state.formModal as EntityMetadataEtapa[]).filter(row => row.identificadorDepende === name && ["01", "02"].includes(row.tipoControl));
		// busca todos los inputs dependientes para posteriormente limpiarlos
		const inputsClearValueDepende = (this.state.formModal as EntityMetadataEtapa[]).filter(row => row.identificadorDepende === name || row.identificadorGrupo === name);
		// buscar al identificador padre
		const inputFather = (this.state.formModal as EntityMetadataEtapa[]).find(row => row.identificador === name);

		let extraValuesDepende = {};
		// limpia los inputs que dependian del valor que se acaba de cambiar
		inputsClearValueDepende.forEach(row => extraValuesDepende = { ...extraValuesDepende, [row.identificador]: null })

		// setea la información del input dependiente desde el valor2 de una lista
		if (listIdentifierDepende.length > 0 && inputFather) {
			const allLists = (this.state.listas as EntityLista[]).find(row => row.id === inputFather.idLista);
			if (name === "numero_de_partida") {
				const arrValue = `${value}`.split(",").map(row => row.trim());
				const itemsSelected: any[] = (allLists?.items.filter((row: any) => arrValue.includes(row.value)) || []);
				console.log(itemsSelected);
				listIdentifierDepende.forEach(row => {
					extraValuesDepende = {
						...extraValuesDepende,
						[row.identificador]: itemsSelected.reduce((prev, current, currentIndex) => {
							if (currentIndex === 0)
								prev += `[\r\n`;
							if (currentIndex === (itemsSelected.length - 1))
								prev += `${current.dataComplete.valor2} \r\n]`;
							else
								prev += `${current.dataComplete.valor2}, \r\n`;

							return prev;
						}, '')
					}
				})
			} else {
				const itemSelected = (allLists?.items.find((row: any) => row.value === value) as any || {dataComplete: {}} ).dataComplete;
				listIdentifierDepende.forEach(row => {
					extraValuesDepende = {
						...extraValuesDepende,
						[row.identificador]: itemSelected.valor2
					}
				})
			}
		}

		// Autocompletados harcodeado
		let dataAutocomplete = {}
		if (name === "tipo_de_fibra_producto") {
			if (value === "Drytex Cycle") {
				// Agregar automáticamente RCS si se marca Drytex Cycle
				const newValue: string[] = typeof this.state.data.jsonData.certificados === 'string' ? 
						this.state.data.jsonData?.certificados.split(',').filter((row: string) => row !== "").map((row: string) => row.trim()) :
						this.state.data.jsonData?.certificados;
	
				if (!newValue.some(row => row === "RCS"))
					dataAutocomplete = { certificados: [...newValue,  'RCS'].join(', ') }
			} else {
				// Remover automáticamente RCS si se marca otra opción
				const newValue: string[] = typeof this.state.data.jsonData.certificados === 'string' ? 
						this.state.data.jsonData?.certificados.split(',').filter((row: string) => row !== "").map((row: string) => row.trim()) :
						this.state.data.jsonData?.certificados;
				
				const indexFinded = newValue.findIndex(row => row === "RCS");
				if (indexFinded !== -1) newValue.splice(indexFinded, 1);
				dataAutocomplete = { certificados: newValue.join(', ') }
			}
		}

		if(!type)
		//sirve para guardar en el JsonData (Formulario del modal)
			this.setState((state:any) => ({
				...state, data:{
					...state.data,
					jsonData:{
						...state.data.jsonData,
						...extraValuesDepende,
						...dataAutocomplete,
						[name]: value
					}
				}
			}))
		else
		//sirve para guardar la data, lo que no sería del jsonData.
			this.setState((state:any) => ({
				...state, data:{
					...state.data,
					[name]: value
				}
			}))
	}

	functionsOnChange = ({name, value}:any) => {
		const { list, data } = this.state;

		switch(name){
			case 'Etapas': {
				let newValue: Array<number>=[];
				if(value.length === 0) {  
					this.setState({Etapas: value, data: {
						...this.state.data,
						idsProcesoPadre: []
					}});
					return;
				}

				if(!value.length) newValue.push(value);
				else newValue = [...value];
				
				this.handleSearchRegister(newValue);
			};break;
			case 'descripcion':
			//para guardar descripcion, ya que es un dato global dentro del formulario.
				this.setState((state:any) => ({
					...state, data:{
						...state.data,
						[name]: value
					}
				}))
			;break;
			case 'idsProcesoPadre': 
				const Grupo = (list.find((element:any) => element.id === data.idEtapaGrupoCadenaValor));

				if(Grupo.id === 7) this.handleListProductCode(value);

				this.setState((state:any) => ({
					...state, data:{
						...state.data,
						[name]: value 
					}
				}));
				break;
			default: break;
		}
	}

	validation = () => {
		const { formModal, data, list } = this.state;
		const { jsonData } = data;

		let validationForm: Array<object> = [], List: Array<string> = [];

		//busca al grupo que se encuentra y verfica si se valida la seccion Fuente.
		const renderFuente = (list.find((element:any) => element.id === data.idEtapaGrupoCadenaValor)).asignaFuente;

		formModal.forEach((item: EntityMetadataEtapa) => {
			if(item.identificador === "descripcion") return;
			//si el campo identificadorGrupo se escoge una opción diferente al valor asignado no se validará
			if (!!item.identificadorGrupo) {
				const valueFromIdentificadorGrupo = this.state.data.jsonData[item.identificadorGrupo] || '';
				if (valueFromIdentificadorGrupo !== item.grupoValue) return;
			}

			//aca generamos todas las posibles validaciones dependiendo si es obligatorio.
			item.obligatorio && validationForm.push({name: item.identificador, message: item.mensajeError || item.identificador});
			if(item.tipoValidacion) {
				const temp = jsonData[item.identificador];
				if( temp === null || temp === undefined || temp === '') return;

				const type = DictionaryValidationType[item.tipoValidacion] || {};
				const validation01 = temp.match(type.pattern) != null;

				if(!validation01) List.push(type.message);
			}
		})

		if (renderFuente && data.idsProcesoPadre.length === 0) List.push("Debes ingresar un Proceso y Registro");
		const findDescription = (formModal as EntityMetadataEtapa[]).find(row => row.identificador === 'descripcion');
		if (findDescription && [null, undefined, ''].includes(data.descripcion)) List.push(`Debes ingresar ${findDescription.etiqueta}`);

		validationForm.forEach((item:any) => {
			//aca generamos un arreglo para mostrar el mensaje de error al validar
			if( jsonData[item.name] === null || jsonData[item.name] === undefined || jsonData[item.name] === '') List.push(item.message);
		})

		//retornamos la lista.
		return List;
	}

	/*------------------CRUD PROCESSES----------------- */

	functionsCrud = (type:any, value?:any) => {
		switch(type){
			case 1: this.SeleccionaProcesos(value);break;
			case 2: this.AgregaProcesos();break;
			case 3: this.ActualizaProcesos();break;
			case 4: this.setState({visible:true, edit:false, ...value});break;
		}
	}

	SeleccionaProcesos = (value:any) => {

		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Selecciona_Call(value.Id, resolve, reject)
		})
		.then(() => {
			let { getEdit } = this.props, formModal: Array<object> =[], titleModal: string = '';
			if(!getEdit.nombre){
				//Buscamos a la etapa en donde fue creada.
				const Etapa = (this.state.list.find((item:any) => item.id === getEdit.idEtapaGrupoCadenaValor));
				//Para mostrar la informacion en los inputs (Proceso y Registro) de fuente.
				const Etapas:Array<number> = [], idsProcesoPadre:Array<number> = []; 
				getEdit.fuente.forEach((item:any) => {
					const isRepeat = Etapas.find((item_:any) => item_ === item.idEtapaGrupoPadre);

					isRepeat === undefined && Etapas.push(item.idEtapaGrupoPadre);
					idsProcesoPadre.push(item.idProcesoPadre)
				});
				//BUSCA LOS REGISTROS SI TIENE ACTIVADO EL "ASGINA FUENTE" CONSULTAR EN EL METADATA(EFFECTS).
				if(value.asignaFuente) this.handleSearchRegister(Etapas);
				//creamos un nuevo dato harcodeado para el formModal (descripcion).
				const descripcion = [{
					identificador: "descripcion",
					etiqueta : "Descripción",
					idLista: null,
					tipoDato : "01",
					tipoControl : "01",
					obligatorio : true,
					textoMaxLength : null,
					mensajeError: "Ingresa la Descripción"
				}]

				//seteamos la nueva información
				if (!Etapa.ocultaDescripcion) formModal = [...descripcion];
				formModal = [...formModal, ...Etapa.metadata]
				getEdit = { ...getEdit, idsProcesoPadre };
				titleModal = Etapa.nombre;
			}

			this.handleSearchEtapas(getEdit.idEtapaGrupoCadenaValor);
			this.setState({data: getEdit, visible:true, edit:true, formModal, titleModal, readOnlyModal: value.readOnly});
		})
		.catch(() => {

		})
	}

	AgregaProcesos = () => {
		let { data, list } = this.state;
		this.setState({loadingBtnModal: true})
		const renderFuente = (list.find((element:any) => element.id === data.idEtapaGrupoCadenaValor)).asignaFuente;

		if(renderFuente && !data.idsProcesoPadre.length) {
			const idsProcesoPadre: Array<number> = [];
			idsProcesoPadre.push(data.idsProcesoPadre);
			const newData = {...data, idsProcesoPadre }
			data = newData;
		}

		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Agrega_Call(data, resolve, reject);
		})
		.then((res:any) => {
			this.addList(data.idEtapaGrupoCadenaValor);
			message.success(res);
			this.handleCloseModal();
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false})

			if(Array.isArray(err)) 
				err.map((item:any) => message.error(item));
			else {
				const renderError = <div dangerouslySetInnerHTML={{__html: err}}/>;
				message.error(renderError);
			}
		})
	}

	ActualizaProcesos = () => {
		let { data, list } = this.state;
		this.setState({loadingBtnModal: true})
		const renderFuente = (list.find((element:any) => element.id === data.idEtapaGrupoCadenaValor)).asignaFuente;

		if(renderFuente && !data.idsProcesoPadre.length) {
			const idsProcesoPadre: Array<number> = [];
			idsProcesoPadre.push(data.idsProcesoPadre);
			const newData = {...data, idsProcesoPadre }
			data = newData;
		}

		new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Actualiza_Call(data, resolve, reject);
		})
		.then((res:any) => {
			this.addList(data.idEtapaGrupoCadenaValor)
			message.success(res)
			this.handleCloseModal();
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false})
			if(Array.isArray(err)) 
				err.map((item:any) => message.error(item));
			else {
				const renderError = <div dangerouslySetInnerHTML={{__html: err}}/>;
				message.error(renderError);
			}
		})
	}

 	/*------------------------------------------------- */

	render() {

		const { list } = this.state;
		return (
			<ToggleMenuWrapper>
				<Spin spinning={this.state.loading}>
					{ this.props.status	=== 200 ?
						<div className="content">
							<div className="header_content">
								<div  className="title">
									<Breadcrumb separator="|" className='ff-Mont-Family'>
										<Breadcrumb.Item href={`/procesos`}> {TITLE__PROCESO} </Breadcrumb.Item>
									</Breadcrumb>
									<div>
									{/*<h1>PROCESOS</h1>*/}
									</div>
								</div>
								{
									(userData.procesos?.some(row => row === "confeccion-de-prenda") || userData.tipo === 2) ?
										<div className="button" >
											<Link to='/procesos/generar-codigos' className='buttonCustom'>
												Códigos QR
											</Link>
										</div>
									: null
								}
							</div>
							<div className="list__Collapse">
								{
									list.map((item: EntityEtapa, index:number) => 
										<ListCollapse 
											key={item.id}
											header={item.nombre}
											columns={item.headers}
											datasource={ item.datasource || [] }
											getData={() => this.callData(item.id)}
											functionButton={(e:any, name:any) => this.handleFunctionButton(e, name, item) }
											edit = {item.editar}
											asignaFuente = {item.asignaFuente}
										/>
									)
								}
								{ this.renderModal() }
							</div>
						</div>
					:
					<div>NOT FOUND</div>
					}
				</Spin>
			</ToggleMenuWrapper>
		)
	}
}

const mapStateToProps= (state:any) => ({
	list_Metadata: state.proceso.metadata,
	status: state.proceso.status,
	onlyOne: state.proceso.listOnlyOne,
	getEdit: state.proceso.onlyOne,
	listEtapa: state.proceso.listEtapas,
	listRegistro: state.proceso.listRegister,
	listProduct: state.proceso.listproducto
})

const mapDispatchToProps = (dispatch:any) => {
	const { AProceso_Lista_Proceso_Metadata_Call, AProceso_Lista_Proceso_Etapa_Call,
		AProceso_Agrega_Call, AProceso_Selecciona_Call, AProceso_Actualiza_Call,
		AProceso_Lista_Etapas_Parent_Call, AProceso_Lista_Registros_Parent_Call,
	    AProceso_Lista_Producto_Code_Call } = Actions;

    return bindActionCreators({
		AProceso_Lista_Proceso_Metadata_Call,
		AProceso_Lista_Proceso_Etapa_Call,
		AProceso_Agrega_Call,
		AProceso_Selecciona_Call,
		AProceso_Actualiza_Call,
		AProceso_Lista_Etapas_Parent_Call, 
		AProceso_Lista_Producto_Code_Call,
		AProceso_Lista_Registros_Parent_Call
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Processes);