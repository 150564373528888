/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DatePicker, Select, Input, Breadcrumb, Modal, Row, Col, message, Space, Tooltip, Button } from 'antd';
import * as Actions from './../../../redux/actions';
import { TITLE__STAKEHOLDER } from './../../../global/title-modules';
import moment from 'moment';
import { List } from './../../../components/lists/list';
import { EditOutlined, RedoOutlined } from '@ant-design/icons';

const { TextArea } = Input;

class list extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			visible:false,
			onlyOne: {
				estado:'A',
				permisos: []
            },
			editOrCreate: 1,
			search:{

			},
			loading: false,
			Proceso: [],
			loadingBtnModal: false
		};
	}

	componentDidMount(){
		this.onSearch(true);
	}

	buttons = (val:any) => {
		return (
			<div>
				<Tooltip  placement="left" title={"Editar"} >
					<button onClick={ () => this.functionsCRUD(4, val.id) }><EditOutlined /></button>
				</Tooltip>
				{/*<Tooltip  placement="left" title={"Eliminar"} >
					<Popconfirm 
						title={ 
								`Esta acción no se puede
								deshacer, ¿quieres continuar?` 
							} 
						placement="left"
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						onConfirm = { (e:any) => this.functionsCRUD(3, val.id) }
					>
						<button style={{ backgroundColor: '#373536'}}> <DeleteOutlined /></button>	
					</Popconfirm>
				</Tooltip>*/}
			</div>
		)
	}

	/**---------------------Buscador Listado----------------------*/

	onSearch = (TraerListaProceso:boolean) => {
		this.setState({loadingBtn1: true})
		const { search } = this.state;
		//const { FechaIncicial, FechaFinal } = search;
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Lista_Tipo_StakeHolder_Call({ search, TraerListaProceso }, resolve, reject);	
		})
		.then(() => {
			const { Proceso } = this.props;
			this.setState({loadingBtn1:false, Proceso})
		})
		.catch(() => {
			this.setState({loadingBtn1:false})
		})
	}

	/*---------------------------------------------------------------*/

	onRestore = () => {
		this.setState({loadingBtn2: true})
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Lista_Tipo_StakeHolder_Call({ search: {}, TraerListaProceso:false }, resolve, reject);
		})
		.then((res) => {
			this.setState({loadingBtn2:false, search:{}})
		})
		.catch((err) => {
			this.setState({loadingBtn2:false})
		})
	}

	getDateFormat = (date:any): any => {
		var d = moment(date);
		return date && d.isValid() ? d : null;
	};

	/*-----------------------------MODAL----------------------------------------- */
	HandleOpenModal = (val?:any) => {
		if(val) this.setState({onlyOne:val})
		
		this.setState({visible:true})
	}

	handleCloseModal  = () => {
		this.setState({
			visible:false, 
			onlyOne:{
				estado:'A', 
				descripcion:'', 
				permisos:[]
			}, 
			editOrCreate: 1,
			loadingBtnModal: false
		})
	}

	renderModal = () => {
		const { editOrCreate, loadingBtnModal } = this.state;
		return(
			<Modal
			title={ this.renderHeaderModal("Tipos de Stakeholder") }
			visible={this.state.visible}
			onCancel={ () => this.handleCloseModal() }
            footer={null}
            width={'400px'}
		  >
			  { this.renderContentModal() }

			  <div className='buttons'>
				  <div className='cancel'>
				  	<button onClick={ () => this.handleCloseModal()}>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<Button 
					  onClick = { () => this.functionsCRUD(editOrCreate) }
					  loading = { loadingBtnModal }
					>
						Guardar
					</Button>
				  </div>
			  </div>
		  </Modal>
		)
	}

	renderContentModal = () => {
		const { onlyOne, Proceso } = this.state;

		const selectProps = {
			mode: 'multiple' as const,
			style: { width: '100%' },
			value: onlyOne.permisos,
			options: Proceso,
			onChange: (value: string[]) => this.onChange({name:'permisos', value}),
			placeholder: 'Proceso',
			maxTagCount: 1
		}

		return(
			<div className='form__Modal ff-Mont-Family'>
			<Row gutter={[6,16]}>
				<Col span={24}>
					<label>&nbsp;&nbsp;NOMBRE: <a>{` *`}</a></label>
					<Input onChange = { (e:any) => this.onChange({name: 'nombre', value: e.target.value }) } value = {onlyOne.nombre} />
				</Col>
			</Row>
			<Row gutter={[8,16]}>
				<Col span={24}>
					<label>&nbsp;&nbsp;DESCRIPCIÓN: <a>{` *`}</a></label>
					<TextArea 
						onChange = { (e:any) => this.onChange({name: 'descripcion', value: e.target.value }) } 
						value = {onlyOne.descripcion} 
						autoSize={{ minRows: 4, maxRows: 4 }}
					/>
				</Col>
			</Row>
			{/* <Row gutter={[6,16]}>
				<Col span={24} >
                	<label>&nbsp;&nbsp;ESTADO:</label>
                    <Select style={{width:'100%'}} value={ onlyOne.estado || "A" } onChange={ (value:any) => this.onChange({name: 'estado', value}) }>
						<Option value="A"> Activo </Option>
						<Option value="I"> Inactivo </Option>
					</Select>
				</Col>
			</Row> */} 
			<Row gutter={[6, 16]}>
				<Col span={24}>
					<label>&nbsp;&nbsp;PROCESO: <a>{` *`}</a></label>  
					<Space direction="vertical" style={{ width: '100%' }}>
						<Select {...selectProps} ></Select>
					</Space>
					{/*<Checkbox.Group 
						options={ Proceso }
						onChange={ (value:any) => this.onChange({name: 'Proceso', value}) }
					/>*/}
				</Col>
			</Row>
		</div>
		)
	}

	renderHeaderModal = (title:string) => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item > <a style={{fontSize:'20px'}}>{ title }</a> </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}
/**---------------------------------------------------------------------------------------------- */
	handleValidation = () => {
		const { onlyOne } = this.state;
		const listTemp = [];
        if(onlyOne.idCliente+"" === null || onlyOne.idCliente+"" === undefined || onlyOne.idCliente+"" === "" ) listTemp.push("ID CLIENTE");
		if(onlyOne.nombre === null || onlyOne.nombre === undefined || onlyOne.nombre === "" ) listTemp.push("NOMBRE");
		if(onlyOne.descripcion === null || onlyOne.descripcion === undefined || onlyOne.descripcion === "" ) listTemp.push("Descripción");
		if(onlyOne.estado === null || onlyOne.estado === undefined || onlyOne.estado === "" ) listTemp.push("Estado");
		if(onlyOne.permisos.length === 0) listTemp.push("Permisos");

		return listTemp
	}

	handleSave = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
		this.setState({loadingBtnModal: true})

		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Agrega_Tipo_StakeHolder_Call(this.state.onlyOne, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false})
			message.error(err);
		})
	}

	handleUpdate = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
		this.setState({loadingBtnModal: true})

		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Actualiza_Tipo_Stakeholder_Call(this.state.onlyOne, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false})
			message.error(err);
		})
	}

	handleDelete = (id:any) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Elimina_Tipo_Stakeholder_Call(id, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			message.error(err);
		})
	}

	handleSelecciona = (id:any) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Selecciona_Tipo_StakeHolder_Call(id, resolve, reject)
		})
		.then((res) => {
			const { onlyOne } = this.props;
			this.setState({onlyOne, visible:true, editOrCreate:2});
		})
		.catch((err) => {
			message.error(err);
		})
	}

	functionsCRUD = (type:number, id?:number) => {
		switch(type){
			case 1: this.handleSave();break;
			case 2: this.handleUpdate();break;
			case 3: this.handleDelete(id);break;
			case 4: this.handleSelecciona(id);break;
			default: return;
		}
	}

	onChange = (payload:any, search?:boolean) => {
		if(search){
			this.setState((state:any) => ({
				...state, search:{
					...state.search, 
					...payload
				}
			}))
			return;
		}

		const {name, value} = payload;
		this.setState((state:any) => ({
			...state, onlyOne:{
				...state.onlyOne, 
				[name] : value
			}
		}))

	}

	onPagination = (value:any) => {
		console.log(value);
	}

	/*------------------------------------------------------------------------------------------ */

	render() {
		const { search:{ FechaIncicial, FechaFinal, Nombre }, loadingBtn1, loadingBtn2 } = this.state;
		return (
			<ToggleMenuWrapper>
				<>
					<div className="content">
						<div className="header_content">
							<div className="title">
								<Breadcrumb separator="|" className='ff-Mont-Family'>
									<Breadcrumb.Item href={'/stakeholders'}> {TITLE__STAKEHOLDER} </Breadcrumb.Item>
									<Breadcrumb.Item href={''}> <a> Tipos de Stakeholders  </a> </Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div className="button" style={{paddingLeft:'180px'}}>
								<button onClick={() => {this.HandleOpenModal(); return this.setState({typeFunctions:1})}}>
									Crear
								</button>
							</div>
						</div>
						<div className="content_list">
							<div className="search ff-Mont-Family" style={{borderRadius:'10px 10px 0px 0px'}}>
								<div className="inputs">
									<p>FECHA DE REGISTRO:</p>
									<DatePicker placeholder='Fecha Inicio' value={FechaIncicial} onChange = { (value) => this.onChange({FechaIncicial: value}, true) } />
									<DatePicker placeholder='Fecha Fin' value={FechaFinal} onChange = { (value) => this.onChange({FechaFinal: value}, true) } />
								</div>
								{/* <div className="inputs">
									<p>ESTADO: </p>
									<Select allowClear style={{width:'120px'}}  onChange = { (value:any) => this.onChange({Estado:value}, true) } value={Estado}>
										<Option value="A"> Activo </Option>
										<Option value="I"> Inactivo </Option>
									</Select>
								</div> */}
								<div className="inputs">
									<p>TIPO: </p>
									<Input onChange = { (e:any) => this.onChange({Nombre:e.target.value}, true) } value={Nombre}/>
								</div>
								<div className="inputs" style={{marginTop:'55px'}}>
									<Button loading={loadingBtn1} onClick={() => this.onSearch(false)}>Buscar</Button>
								</div>
								<div className="inputs" style={{marginTop:'55px'}}>
									<Button style={{width:'50px'}} loading={loadingBtn2} onClick={() => this.onRestore()}> <RedoOutlined/> </Button>
								</div>
							</div>
							<div className="list">
								<List 
									datasource={this.props.List_Type}
									columns={metadata(this.buttons)}
									onPagination={this.onPagination}
								/>
							</div>
						</div>
					</div>
					{  this.renderModal() }
				</>
			</ToggleMenuWrapper>
		)
	}
}

const mapStateToProps= (state:any) => ({
	List_Type: state.stakeholder.stakeholder_Type.Lista,
	onlyOne: state.stakeholder.stakeholder_Type.onlyOne,
	Proceso: state.stakeholder.stakeholder_Type.Proceso
})

const mapDispatchToProps = (dispatch:any) => {
	const { AStakeholder_Lista_Tipo_StakeHolder_Call, AStakeholder_Agrega_Tipo_StakeHolder_Call,
			AStakeholder_Actualiza_Tipo_Stakeholder_Call, AStakeholder_Elimina_Tipo_Stakeholder_Call,
			AStakeholder_Selecciona_Tipo_StakeHolder_Call } = Actions;
    return bindActionCreators({
		AStakeholder_Lista_Tipo_StakeHolder_Call,
		AStakeholder_Agrega_Tipo_StakeHolder_Call,
		AStakeholder_Actualiza_Tipo_Stakeholder_Call,
		AStakeholder_Elimina_Tipo_Stakeholder_Call,
		AStakeholder_Selecciona_Tipo_StakeHolder_Call
    }, dispatch)
}

const metadata = (buttons:any) => [
 {title: 'ID', dataIndex: 'id',key: 'id', width: 80},
 {title: 'Tipo', dataIndex: 'nombre', key: 'nombre'},
 {title: 'Procesos', dataIndex: 'permisos', key: 'permisos'},
 {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'},
 {title: 'Estado', dataIndex: 'estado', key: 'estado'},
 {title: 'Acciones', dataIndex: '', key: '', render: (text:any, record:any) => buttons(record)}
]

export default connect(mapStateToProps,mapDispatchToProps )(list);