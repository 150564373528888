import { useFormik } from "formik";
import { useState } from "react"
import { EntityListOption, EntityListOptionInit } from "../Domain/EntityListOption";
import * as Yup from 'yup';
import { EntityModalOptions, EntityModalOptionsInit } from "../Domain/EntityModalOptions";
import { AdapterValidator } from "../../../../shared/Infraestructure/AdapterValidator";
import { message, Tooltip } from "antd";
import { EntityData } from "../Domain/EntityData";
import { EyeOutlined } from '@ant-design/icons';
import axios from "axios";
import { EntityDtoRequest } from "../Domain/EntityDtoRequest";
import { AdapterGeneric } from "../../../../shared/Infraestructure/AdapterGeneric";

export const Controller = () => {
    const [listOption, setListOption] = useState<EntityListOption>(EntityListOptionInit);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const reloadList = async () => {
        try {
            const response: null | EntityData[] = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
            if (response) setListOption((prev) => ({...prev, dataTable: response}))
        } catch (err) {}
    }

    const { openModalModeEdit, openModalModePreview, openModalModeResult, publishProposal, ...rest } = ControllerItemForm({ reloadList });
    const formFilter = useFormik({
        initialValues: { estado: '', fechaFinal: '', fechaInicio: '' },
        onSubmit: () => {},
        validationSchema: Yup.object({
            estado: Yup.array()
        })
    })

    const init = () => {
        setListOption(prev => ({
            ...prev,
            metadata: [
                { key: 'vote_name', dataIndex: 'vote_name', title: 'Propuesta', render: (currentText: string, dataComplete: EntityData) => <span onClick={() => openModalModeEdit(dataComplete)} className="link-custom">{dataComplete.vote_name || dataComplete.token_name}</span>, width: '20%'  },
                { key: 'proposal_description', dataIndex: 'proposal_description', title: 'Descripción', width: '20%' },
                { key: 'fechaPublicacion', dataIndex: 'fechaPublicacion', title: 'Fecha de publicación', render: (currentText: string, dataComplete: EntityData) => <>{ dataComplete.isPublished ? AdapterGeneric.convertDateToString( AdapterGeneric.convertStringToDate(dataComplete.publish_date), 3) : '' }</> },
                { key: 'voting_period', dataIndex: 'voting_period', title: 'Días de publicación' },
                { key: 'estado', dataIndex: 'estado', title: 'Estado', render: (currentText: string, dataComplete: EntityData) => 
                    <span>
                        {
                            dataComplete.isPublished ?
                                (
                                    (-AdapterGeneric.calculateDays(dataComplete.publish_date) - 1) <= dataComplete.voting_period ?
                                        'Publicado'
                                        :
                                        'Cerrado'
                                )
                                :
                                'Borrador'
                        }
                    </span>
                },
                { key: 'cantidadVotos', dataIndex: 'cantidadVotos', title: 'Número de votos', render: (currentText: string, dataComplete: EntityData) => <>{ dataComplete.options.absteined + dataComplete.options.against + dataComplete.options.for }</> },
                { key: 'publicar', dataIndex: 'publicar', title: 'Publicar', render: (currentText: string, dataComplete: EntityData) => <Tooltip placement="left" title={"Publicar"} > <button disabled={dataComplete.isPublished} style={{ cursor: dataComplete.isPublished ? 'no-drop' : 'default' }} onClick={() => publishProposal(dataComplete)}><i className="fa-solid fa-globe" style={{ fontSize: 14 }} /></button> </Tooltip> },
                { key: 'resultado', dataIndex: 'resultado', title: 'Resultados', render: (currentText: string, dataComplete: EntityData) => <Tooltip placement="left" title={"Resultado"} > <button onClick={() => openModalModeResult(dataComplete)}><EyeOutlined /></button> </Tooltip> },
                { key: 'preview', dataIndex: 'preview', title: 'Vista previa', render: (currentText: string, dataComplete: EntityData) => <Tooltip placement="left" title={"Vista Previa"} > <button onClick={() => openModalModePreview(dataComplete)}><EyeOutlined /></button> </Tooltip> },
            ],
            dataTable: []
        }))
        reloadList();
    }

    const onPagination = (evt: any) => {
        setListOption((prev) => ({...prev, currentPage: evt.current}))
    }
    const onChangeFilter = (name: string, value: any) => {
        console.log(value)
        if (name === 'estado' && `${value}` === "0") {
            formFilter.setFieldValue('fechaInicio', '');
            formFilter.setFieldValue('fechaFinal', '');
        }

        formFilter.setFieldValue(name, value);
    }
    const resetFormFilters = () => {
        formFilter.resetForm();
        reloadList();
    }

    const searchList = async () => {
        setLoadingFilter(true);
        try {
            // let urlBase = `${process.env.REACT_APP_SERVICE_VOTES}/api/vote`;
            let params = '';
            const entries = Object.entries(formFilter.values);
            entries.forEach(row => {
                if (row[0] === 'estado' && row[1]) {
                    if (params) params += '&'

                    let filterStatus: any = {
                        "0": 'active=false',
                        "1": 'active=true',
                        "2": 'closed=true'
                    }
                    params += filterStatus[row[1]];

                }
                if (row[0] === 'fechaFinal' && !!row[1]) {
                    let fechaParsed = AdapterGeneric.convertStringToDate(row[1]);
                    fechaParsed.setUTCHours(23, 59, 59, 999);
                    const fecha = fechaParsed.toISOString();
                    params += (params.length > 0 ? `&fechafin=${fecha}` : `fechafin=${fecha}`);
                }
                if (row[0] === 'fechaInicio' && !!row[1]) {
                    const fecha = AdapterGeneric.convertStringToDate(row[1]).toISOString();
                    params += (params.length > 0 ? `&fechainicio=${fecha}` : `fechainicio=${fecha}`);
                }
            })
            const response: null | EntityData[] = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/filter?${params}`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
            if (response !== null) setListOption((prev) => ({...prev, dataTable: response}));
            else setListOption((prev) => ({...prev, dataTable: []}));
        } catch (error) {

        } finally {
            setLoadingFilter(false);
        }
    }

    return ({
        ...rest,
        init,
        onPagination,
        onChangeFilter,
        resetFormFilters,
        searchList,
        
        formFilter,
        loadingFilter,
        listOption,
    })
}

interface ContainerProps {
    reloadList: () => Promise<void>;
};

export const ControllerItemForm = (props: ContainerProps) => {
    const [modalOptions, setModalOptions] = useState<EntityModalOptions>(EntityModalOptionsInit);
    const [loading, setLoading] = useState<Boolean>(false);
    const formItem = useFormik({
        initialValues: {
            propuesta: '',
            descripcion: '',
            diasPublicacion: 1,
            id: ''
        },
        onSubmit: () => {},
        validationSchema: Yup.object({
            propuesta: Yup.string().required('Campo Propuesta es requerido'),
            descripcion: Yup.string().required('Campo Descripción es requerido'),
            diasPublicacion: Yup.number().min(1).required('Campo Días de Publicación es requerido'),
        })
    })

    const onChangeFormItem = (name: string, value: any) => formItem.setFieldValue(name, value);

    const openModalModeCreate = () => {
        setModalOptions({
            key: 'create',
            loading: false,
            show: true,
            title: 'Crear propuesta'
        })
    }

    const openModalModeEdit = (payload: EntityData) => {
        setModalOptions({
            key: 'edit',
            loading: false,
            show: true,
            title: 'Editar propuesta'
        })

        formItem.setValues({
            descripcion: payload.proposal_description,
            diasPublicacion: payload.voting_period,
            propuesta: payload.vote_name,
            id: payload.id
        });
    }

    const openModalModePreview = (payload: EntityData) => {
        setModalOptions({
            key: 'preview',
            loading: false,
            show: true,
            title: 'Vista previa',
            dataComplete: payload
        })
    }

    const openModalModeResult = (payload: EntityData) => {
        const { absteined, against } = payload.options;
        const sumVotes = absteined + against + payload.options.for;
        setModalOptions({
            key: 'result',
            loading: false,
            show: true,
            title: 'Resultados',
            dataComplete: {
                ...payload,
                options: {
                    absteined: AdapterGeneric.calculatePercentage(absteined, sumVotes),
                    against: AdapterGeneric.calculatePercentage(against, sumVotes),
                    for: AdapterGeneric.calculatePercentage(payload.options.for, sumVotes)
                }
            }
        })
    }

    const publishProposal = async (payload: EntityData) => {
        try {
            await axios.put(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/publish/${payload.id}`, {}, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })
            message.success(`¡Registro ${payload.vote_name || payload.token_name} publicado!`);
        } catch(err) {}
    }

    const closeModal = () => {
        formItem.resetForm();
        setModalOptions(EntityModalOptionsInit)
    }
    const onChangeLoadingModal = (loading: boolean) => setModalOptions(prev => ({...prev, loading}))
    const onSaveFormItem = async (type: 1 | 2) => {
        try { await formItem.submitForm(); } catch (error) { }
        try { AdapterValidator.validate(await formItem.validateForm(), 'custom') } catch (error) { message.error((error as Error).message); return; }
        setLoading(true);
        try {
            if (modalOptions.key === 'create') {
                const payload: EntityDtoRequest = {
                    vote_name: formItem.values.propuesta,
                    token_name: 'Token',
                    token_symbol: 'TKN',
                    voting_period: parseInt(`${formItem.values.diasPublicacion}`),
                    quorum_percent: 50,
                    proposal_description: formItem.values.descripcion
                };
                await axios.post(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/`, payload, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })
            }
            else if (modalOptions.key === 'edit') {
                const payload: EntityDtoRequest = {
                    vote_name: formItem.values.propuesta,
                    voting_period: parseInt(`${formItem.values.diasPublicacion}`),
                    proposal_description: formItem.values.descripcion
                }
                await axios.put(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/update/${formItem.values.id}`, payload, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })
            } else {}

            props.reloadList();
        } catch(err) {}
        finally { setLoading(false) }
        message.success(`Se guardó el registro`);
        closeModal();
    }

    return ({
        modalOptions,
        formItem,
        loading,
        onChangeFormItem,
        openModalModeCreate,
        openModalModeEdit,
        openModalModePreview,
        openModalModeResult,
        closeModal,
        onChangeLoadingModal,
        onSaveFormItem,
        publishProposal
    })
}