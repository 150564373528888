import React from 'react';
import { Button, DatePicker, Select } from 'antd';
import ToggleMenuWrapper from '../../../../components/ToggleMenuWrapper';
import { TITLE__PROPUESTADAO } from '../../../../global/title-modules';
import { ElementBreadCrumb } from '../../../../shared/components/ElementBreadCrumb';
import { RedoOutlined } from '@ant-design/icons';
import { List } from './../../../../components/lists/list';
import './../../../../assets/styles/lists.scss';
import './style.scss';
import { EntityListOption } from '../Domain/EntityListOption';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';
import { AdapterGeneric } from '../../../../shared/Infraestructure/AdapterGeneric';
import moment from 'moment';

interface ContainerProps {
    listOption: EntityListOption;
    formFilters: EntityGenericForm;
    loadingFilter: boolean;
    onPagination(evt: any): void;
    onChangeFilter(name: string, value: any): void;
    resetFormFilter(): void;
    searchList(): void;

    openModalModeCreate(): void;
}

export const View = (props: ContainerProps) => {
    return (
        <ToggleMenuWrapper>
            <div className="ViewPropuestaDAO content">
                <div className="header_content">
                    <div className="title">
                        <ElementBreadCrumb options={[{ route: '', navigate: true, text: TITLE__PROPUESTADAO}]} />
                    </div>
                    <div className="button">
                        <Button onClick={props.openModalModeCreate}>Crear</Button>
                    </div>
                </div>

                <div className="content_list">
                    <div className="search ff-Mont-Family" style={{borderRadius: "10px 10px 0px 0px"}}>
                        <div className="inputs">
                            <p>ESTADO: </p>
                            <Select
                                allowClear  
                                style={{ width: '180px' }}
                                dropdownMatchSelectWidth={false}
                                value={props.formFilters.values.estado}
                                onChange={(evt) => props.onChangeFilter('estado', evt)}
                            >
                                <Select.Option key={0}>Borrador</Select.Option>
                                <Select.Option key={1}>Publicado</Select.Option>
                                <Select.Option key={2}>Cerrado</Select.Option>
                            </Select>
                        </div>
                        <div className="inputs">
                            <p>FECHA DE PUBLICACIÓN:</p>
                            <DatePicker
                                disabled={props.formFilters.values.estado === "0"}
                                format="YYYY-MM-DD"
                                value={AdapterGeneric.readDateFromInputsDate(props.formFilters.values.fechaInicio) as any}
                                style={{width:'120px'}}
                                onChange={(date, str) => props.onChangeFilter('fechaInicio', str)}
                                placeholder='Fecha Inicio'
                            />
                            <DatePicker disabled={props.formFilters.values.estado === "0"} format="YYYY-MM-DD" value={AdapterGeneric.readDateFromInputsDate(props.formFilters.values.fechaFinal) as any} style={{width:'120px'}} onChange={(date, str) => props.onChangeFilter('fechaFinal', str)} placeholder='Fecha Fin' />
                        </div>
                        <div className="inputs" style={{paddingLeft: '60px'}}>
                            <Button loading={props.loadingFilter} onClick={props.searchList}>Buscar</Button>
                        </div>
                        <div className="inputs">
                            <Button loading={props.loadingFilter} style={{ width:'50px' }} onClick={props.resetFormFilter}> <RedoOutlined/></Button>
                        </div>
                    </div>
                    <div className="list" style={{paddingBottom:'30px'}}> 
                        <List
                            datasource={props.listOption.dataTable}
                            columns={props.listOption.metadata}
                            total={props.listOption.total}
                            onPagination={props.onPagination}
                            current={props.listOption.currentPage}
                        /> 
                    </div>
                </div>
            </div>
        </ToggleMenuWrapper>
    );
};