import axios from 'axios';
import { useState } from 'react';
import { baseURLSet } from '../../../../services/constants';
import { DtoResponseCatalogo } from '../Domain/DtoResponseCatalogo';
import { EntityCatalogo2 } from '../Domain/EntityCatalogo';
import { useParams } from 'react-router';
import { EntityDataGeneral } from '../Domain/EntityUtils';

let touchStarX = 0;
let currentPosition = 0;

const configOnChangeScreen = (newPosition: number) => currentPosition = newPosition;
const dataGeneral: EntityDataGeneral = {
    tipo_de_fibra_producto: '',
    certificados: []
}

export const Controller = () => {
    const [stepContent, setStepContent] = useState(0);
    const [catalogoItems, setCatalogoItems] = useState<EntityCatalogo2[]>([]);
    const [showViewError, setShowViewError] = useState(false);
    const params = useParams();

    const onChangeStep = (position: number) => {
        currentPosition = position;
        setStepContent(position);
    }

    const callServiceCatalogo = async () => {
        const language = 'es';
        try {
            const response: DtoResponseCatalogo = (await axios.get(`${baseURLSet.default}client/portal/historia/${params.id}/${language}`)).data.result;
            const finded = response.lista.find(row => row.codeLP === 'sdf-produccion');
            if (finded) {
                dataGeneral['tipo_de_fibra_producto'] = (finded.data as any).tipo_de_fibra_producto.value || 'Drytex';

                const _certificados: string = (finded.data as any).certificados.value || '';
                let separator = '|';
                if (_certificados.includes(',')) separator = ',';
                if (_certificados.includes('|')) separator = '|';
                if (_certificados.includes(';')) separator = ';';
                dataGeneral['certificados'] = _certificados ? _certificados.split(separator).map(row => row.trim()) : [];
                
                // Cuando tenga DrytexCycle se posiciona al principio
                const DrytexCycle = 'RCS';
                const indexFinded = dataGeneral.certificados.findIndex(row => row === DrytexCycle);
                if (indexFinded >= 0) {
                    dataGeneral.certificados.splice(indexFinded, 1);
                    dataGeneral.certificados = [DrytexCycle, ...dataGeneral.certificados];
                }
            }

            setCatalogoItems(response.lista || []);
        } catch(err) {
            setShowViewError(true);
        }
    }

    const setupConfig = () => {
        //const Html = document.getElementsByTagName('html')[0]
        //Html.style.overflow = 'hidden';
        //Html.style.maxHeight = '100vh';

        const carousel = document.getElementsByClassName('CarouselCustom')[0];
        if (carousel) {
            carousel.addEventListener('touchstart', (evt: any) => touchStarX = evt.touches[0].clientX);
            carousel.addEventListener('touchend', (evt:any) => {
                const touchEndX = evt.changedTouches[0].clientX;
                const threshold = 50;
                
                // console.log(touchStarX, touchEndX, currentPosition);
                if (touchEndX === touchStarX) return;
                if (touchStarX - touchEndX < threshold && currentPosition === 0) {
                    setStepContent(0)
                }
            });
        }
    }

    const setupConfigCatalog = () => {
        if (catalogoItems.length === 0) return;

        const buttonInicio = document.getElementById('BtnInicio');
        if (buttonInicio) {
            buttonInicio?.addEventListener("click", (evt) => setStepContent(1))
        }

        const buttonFinal = document.getElementById('btnFinalProcess');
        if (buttonFinal) {
            buttonFinal?.addEventListener("click", (evt) => { setStepContent(0); })
        }
    }

    return ({
        stepContent,
        catalogoItems,
        showViewError,
        dataGeneral,
        onChangeStep,
        setupConfig,
        configOnChangeScreen,
        callServiceCatalogo,
        setupConfigCatalog
    })
}