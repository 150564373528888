// Library
import React from 'react';
import Carousel from 'react-multi-carousel';

// Domain
import { EntityCatalogo2 } from '../Domain/EntityCatalogo';
import { EntityDataGeneral } from '../Domain/EntityUtils';

// Infraestructure
import { AdapterDetectDevice } from '../../../../shared/Infraestructure/AdapterDetectDevice';
import { responsiveCarousel } from '../Infraestructure/AdapterData';

// View
import { ViewHistoryHome } from './Content/ViewHistoryHome';
import { ViewHistoryEnd } from './Content/ViewHistoryEnd';
import { ViewHistory1 } from './Content/ViewHistory1';
import { ViewHistory2 } from './Content/ViewHistory2';
import { ViewHistory3 } from './Content/ViewHistory3';
import { ViewHistory4 } from './Content/ViewHistory4';
import './Style.scss';
import { AdapterGeneric } from '../../../../shared/Infraestructure/AdapterGeneric';

interface ContainerProps {
    stepContent: number;
    catalogoItems: EntityCatalogo2[];
    showViewError: boolean;
    dataGeneral: EntityDataGeneral;
    onChangeStep: (position: number) => void;
    configOnChangeScreen: (newPosition: number) => void;
}

const ButtonGroup = ({ next, previous, onChangeStep, configOnChangeScreen, stepContent, ...rest }: any) => {
    const { carouselState: { currentSlide, slidesToShow, totalItems } } = rest;
    const showNextButton = (currentSlide + slidesToShow + 1) === totalItems;
    configOnChangeScreen(currentSlide);
    if (stepContent === 0) rest.goToSlide(0);

    return (
      <div className="carousel-button-group">
        <div className='btn-left btn-action' onClick={() => currentSlide === 0 ? onChangeStep(0) : previous() }><i className="fa-solid fa-angle-left" /></div>
        { showNextButton ? null : <div className='btn-right btn-action' onClick={next}><i className="fa-solid fa-angle-right" /></div> }
      </div>
    );
};

const dataLogoDinamicos: any = {
    'RCS': {
        url: 'https://sdef.com/drytex-cycle',
        image: '/assets/catalogo/footer-rcs.png',
    },
    'ISO 9001:2015': {
        url: 'https://sdef.com/wp-content/uploads/2023/01/PELIM_174619_GenericCertificate_Final.pdf',
        image: '/assets/catalogo/footer-iso.png',
    },
    'OEKO-TEX': {
        url: 'https://sdef.com/wp-content/uploads/2023/01/OEKOTEX-11.HPE_.95983.pdf',
        image: '/assets/catalogo/footer-oeko-tex.png',
    },
    'BASC': {
        url: 'https://sdef.com/wp-content/uploads/2023/01/SUDAMERICANA-DE-FIBRAS-S.A.-00729-1-7.pdf',
        image: '/assets/catalogo/footer-basc.png',
    },
    'OEA': {
        url: 'https://ww1.sunat.gob.pe/orientacionaduanera/oea/empresasCertificadas.html?numRuc=&tipoOperador=1&certificacionFechNumDesde=&certificacionFechNumHasta#',
        image: '/assets/catalogo/footer-oea.png',
    }
}

export const ViewMain = (props: ContainerProps) => {

    return (
        <div className='ViewHistory'>
            <div className='ViewContent'>
                {
                    props.stepContent === 0 ?
                        <ViewHistoryHome />
                        :
                        <Carousel
                            swipeable={true}
                            draggable={["Mobile", "Tablet"].includes(AdapterDetectDevice())}
                            responsive={responsiveCarousel}
                            infinite={false}
                            keyBoardControl={true}
                            transitionDuration={500}
                            showDots
                            customButtonGroup={<ButtonGroup {...props}/>}
                            beforeChange={() => {
                                AdapterGeneric.scrollTop();
                            }}
                        >
                            {
                                props.catalogoItems.map(row => {
                                    let propsGeneric = {
                                        keys: Object.keys(row.data),
                                        complete: row.data,
                                        dataGeneral: props.dataGeneral,
                                        url_traza: row.url_traza
                                    }

                                    let template = {
                                        "sdf-produccion": <ViewHistory1 {...propsGeneric} />,
                                        "sdf-producto-final": <ViewHistory2 {...propsGeneric} />,
                                        "hilanderia": <ViewHistory3 {...propsGeneric} />,
                                        "titulo_del_hilado": <ViewHistory4 {...propsGeneric} />,
                                        "confeccion-de-prenda": <ViewHistory4 {...propsGeneric} />
                                    }

                                    return template[row.codeLP];
                                })
                            }
                            <ViewHistoryEnd />
                        </Carousel>
                }
            </div>

            <div className='FooterHistoria'>
                <div className='ContentLinea'>
                    <div className='lineaGeneric linea1' />
                    <div className='lineaGeneric linea2' />
                    <div className='lineaGeneric linea3' />
                    <div className='lineaGeneric linea4' />
                </div>

                <div className='MainContent'>
                    <div className='content'>
                        <div className='logoGeneric logo-left'>
                            <a href='https://sdef.com/' target='_blank' rel="noreferrer">
                                <img src='/assets/catalogo/footer-logo.png' alt='Logo SDF' height={40}/>
                            </a>
                            <img src='/assets/catalogo/footer-marca-peru.png' alt='Logo Marga Perú' height={40}/>
                            <a href='https://sdef.com/wp-content/uploads/2022/10/Certificado-Sudamericana-de-Fibras.pdf' target='_blank' rel="noreferrer">
                                <img src='/assets/catalogo/footer-gptw.png' alt='Logo GPTW' height={40}/>
                            </a>
                        </div>
                        <div className='logoGeneric logo-right'>
                            <div id='logoDinamico' className='logoDinamico'>
                                {
                                    props.dataGeneral.certificados.map(row => 
                                        dataLogoDinamicos[row] ?
                                            <a href={dataLogoDinamicos[row].url} target='_blank' rel="noreferrer">
                                                <img src={dataLogoDinamicos[row].image} alt={`Logo ${row}`} height={40}/>
                                            </a>
                                        : null
                                    )
                                }
                            </div>
                            <div className='barraLateral' />
                            <a href='https://sdef.com' target='_blank' rel="noreferrer" className='fnt-semibold'>www.sdef.com</a>
                        </div>
                    </div>
                </div>

                <div className='CopyRight'>
                    <p className='fnt-regular'>
                        <span>SDF ® Todos los derechos reservados {new Date().getFullYear()}</span>
                        |
                        <span>Desarrollado por Magic Chain</span>
                    </p>
                </div>
            </div>
        </div>
    )
}