// Library
import { useState } from "react";
import { ethers } from 'ethers';
import { notification } from "antd";
import { useAddress } from "@thirdweb-dev/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// Adapters
import { AdapterGeneric } from "../../../../../shared/Infraestructure/AdapterGeneric";
import { AdapterWeb3 } from "../../../../../shared/Infraestructure/AdapterWeb3";
import { governorAbi } from "./AdapterData";

// Domain
import { EntityProposal, initEntityProposal } from "../Domain/EntityProposal";
import { EntityData } from "../../List/Domain/EntityData";

export const Controller = () => {
  const [proposals, setProposals] = useState<EntityProposal>(initEntityProposal);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<EntityProposal[]>([]);
  const [isVoting, setIsVoting] = useState(false);
  const [vote, onChangeVote] = useState('');
  const [hasVoted, setHasVoted] = useState(false);
  const [holderAPI, contextHolder] = notification.useNotification();
  const { changeChainCustom, connectWalletCustom } = AdapterWeb3();
  
  const address = useAddress();
  const params = useParams();
  const navigate = useNavigate();
    
    const getDetailVote = async () => {
      try {
        const response: EntityProposal = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/id/${params.id}`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
        if (!response.id) navigate('/propuestas', { replace: true })
        if (!response.isPublished) navigate('/propuestas', { replace: true })
        if (!response.publish_date) navigate('/propuestas', { replace: true })
        if (!(response.isPublished && (-AdapterGeneric.calculateDays(response.publish_date) - 1) <= response.voting_period)) navigate('/propuestas', { replace: true });

        setProposals(() => ({...response, publish_date: `${AdapterGeneric.calculateDays(AdapterGeneric.convertDateToString(AdapterGeneric.sumDays(response.voting_period, response.publish_date)))}`}))
        setHasVoted(false);
      } catch(error) {}
    }

    const reloadList = async () => {
      try {
          const response: null | EntityData[] = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/active`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
          if (response) {
            let tempate = response.map(row => ({
                ...row,
                publish_date: `${AdapterGeneric.calculateDays(AdapterGeneric.convertDateToString(AdapterGeneric.sumDays(row.voting_period, row.publish_date)))}`
              })).filter(row =>
                row.id !== params.id
              );

            setList((prev) => tempate as any)
          }
      } catch (err) {}
    }

    const redirectDetail = (payload: EntityData) => navigate(`/propuestas/${payload.id}`, { replace: true });
    
    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      
      let newAddress: string = await connectWalletCustom();

      if (!vote) throw Error('Seleccione una opción primero');
      if (!window.ethereum) throw Error('Ocurrió un error al conectarse con metamask')

      
      try {
        setLoading(true);
        await changeChainCustom();

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum as any);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(proposals.governor_address, governorAbi, signer);
        
        // Verificar si ya votó
        const hasVoted = await contract.hasVoted(proposals.proposal_id, address || newAddress);
        if (hasVoted) {
          holderAPI.info({
            message: `SDF`,
            description:
              'Ya votaste en esta propuesta. ¡Gracias por participar!',
            placement: "top",
          });
          return;
        }

        const templateValue: any = {
          "against": 0,
          "for": 1,
          "abstain": 2
        }

        const voteTxResponse = await contract.castVote(proposals.proposal_id, templateValue[vote], { gasLimit: 300000 });
        console.log('Transaction Sent:', voteTxResponse.hash);
        await voteTxResponse.wait();

      } catch (err) {
        console.error(err);
        return;
      } finally {
        setLoading(false);
      }
      
      try {
        setIsVoting(true);
        const result = (await axios.put(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/`, { action: vote, proposal_id: proposals.proposal_id, voter_address: address || newAddress }, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } }));
        setHasVoted(true);
        console.log(result);
      } catch(error: any) {
        // console.log(error.response);
      } finally {
        setIsVoting(false);
      }
      
      const response: EntityProposal = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/id/${params.id}`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
      if (!response.id) return;
      setProposals(() => ({...response, publish_date: `${AdapterGeneric.calculateDays(AdapterGeneric.convertDateToString(AdapterGeneric.sumDays(response.voting_period, response.publish_date)))}`}))
    };
    
    return ({
      onSubmit,
      getDetailVote,
      onChangeVote,
      redirectDetail,
      reloadList,
      
      list,
      vote,
      proposals,
      isVoting,
      address,
      hasVoted,
      params,
      loading,
      contextHolder
    })
  }