import { EntityGenericMetadata } from "../../../../shared/Domain/EntityGenericMetadata";

export interface EntityModalDetail {
    title: string;
    show: boolean;
    payload: any[];
    metadata: EntityGenericMetadata[];
}

export const EntityDataDetailInit: EntityModalDetail = {
    title: "",
    show: false,
    payload: [],
    metadata: []
}