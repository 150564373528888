import React from 'react';
import { EntityGenericForm } from '../../../../../shared/Domain/EntityGenericForm';

interface ContainerProps {
    handleAddNFT(evt: React.FormEvent<HTMLFormElement>): Promise<any>;
    formWallet: EntityGenericForm;
    show: boolean;
}

export const ViewContentSuccess = (props: ContainerProps) => {
    if (!props.show) return null;
    return (
        <>
            <div className='GroupText ff-Mont-Family' style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {/*<h1>¡TODO LISTO!</h1>*/}
                <p>Ya tienes tu NFT en tu wallet de Metamask. Puedes verlo haciendo clic aquí:</p>
                <button className='btn-primary' onClick={(evt: any) => props.handleAddNFT(evt)} style={{ marginTop: 40 }}> Ver NFT </button>
            </div>
        </>
    )
}