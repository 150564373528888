import React from 'react';
import './FooterLandingPage.scss';

export const FooterLandingPage = () => {
    return (
        <footer className='FooterLandingPage'>
            <div className='ContentFooter'>
                <p> Visita nuestra página web <a href="https://sdef.com" target="_blank" rel="noreferrer">https://sdef.com</a> </p>
                <p><a target="_blank" rel="noopener noreferrer" href="https://magia.digital/magicchain"> Desarrollado por Magic Chain </a></p>
            </div>
        </footer>
    )
}