import { useEffect } from 'react';
import ViewPropuesta from './Components/View';
import { Controller } from './Infraestructure/Controller';
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import { AdapterScroll } from '../../../../shared/Infraestructure/AdapterScroll';
import { ElementLoadingLanding } from '../../../../shared/components/ElementLoadingLanding';

const View = () => {
    const controller = Controller();
    useEffect(() => {
        controller.getDetailVote();
        controller.reloadList();
        AdapterScroll.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.params.id])
    
    return (
        <>
            {controller.contextHolder}
            <ViewPropuesta
                address={controller.address}
                isVoting={controller.isVoting}
                onSubmit={controller.onSubmit}
                onChangeVote={controller.onChangeVote}
                vote={controller.vote}
                hasVoted={controller.hasVoted}
                redirectDetail={controller.redirectDetail}
                proposals={controller.proposals}
                list={controller.list}
            />
            <ElementLoadingLanding
                show={controller.loading}
            />
        </>
    )
}
        
const ViewWebProvider = () => {
    const activeChainId = ChainId.Mumbai;
    return (
        <ThirdwebProvider desiredChainId={activeChainId}>
            <View />
        </ThirdwebProvider>
    )
}

export default ViewWebProvider;