import { AdapterGeneric } from "../../../../../shared/Infraestructure/AdapterGeneric";
import { EntityDataGeneral } from "../../Domain/EntityUtils";

interface ContainerProps {
    keys: string[]
    complete: any;
    dataGeneral: EntityDataGeneral;
    url_traza: string;
}

const keysListToShow = [
    'hilado_por',
    'tipo_de_hilo',
    'titulo_del_hilado',
    'fecha_de_hilado',
]

const customLabel: any = {
    'hilado_por': 'Hilado en',
    'tipo_de_hilo': 'Tipo de hilo',
    'titulo_del_hilado': 'Título del hilado (Nm)',
    'fecha_de_hilado': 'Fecha del hilado',
}

export const ViewHistory3 = (props: ContainerProps) => {

    return (
        <div className='ViewHistory3'>
            <header>
                <div className='GroupPage'>
                    <label className='fnt-bold'>3</label>
                </div>
                <div className='GroupBanner'>
                    <img src="/assets/catalogo/banner-3.png" alt='Not Source'/>
                </div>
            </header>
            <div className='MainContent'>
                <div className='content'>
                    <div className='GroupTitle'>
                        <p className='fnt-regular'>
                            PROCESO DE
                            <span className='fnt-bold'>&nbsp;HILATURA</span>
                        </p>
                        <p className='fnt-regular'>
                            Trabajamos de la mano de los mejores
                            hilanderos del país, garantizando un producto
                            de la más alta calidad
                        </p>
                    </div>
                    <div className='GroupInputs'>
                        <div className='GroupData'>
                            {
                                keysListToShow.map(row => {
                                    let data: { value: string; label: string; } | null = props.complete[row];
                                    if (!data) return data;
                                    if (row === 'fecha_de_hilado') data.value = AdapterGeneric.convertDateToString(AdapterGeneric.convertStringToDate(data.value), 3)

                                    return (
                                        <div className='GroupInput'>
                                            <label className='fnt-bold'>{customLabel[row]}</label>
                                            <div><span className="fnt-regular">{data.value}</span></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='GroupImage'>
                            <img src="/assets/catalogo/img-proceso3.png" alt="Not Source" />
                        </div>

                    </div>
                    <a className='AccederBlockchain fnt-bold' href={props.url_traza} rel="noreferrer" target='_blank'>Acceder al Blockchain</a>
                </div>
            </div>
        </div>
    )
}