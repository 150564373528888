import { FormikErrors } from "formik";

export class AdapterValidator {
    public static validate(validate: FormikErrors<object>, typeMessage: 'custom' | 'default'): void {
        let valueValidate: string[] = Object.values(validate);

        if (!!valueValidate.length) {
            let message: string = valueValidate.map(row => typeof row === 'object' ? 'No se encontró el campo' : row).join('<br />');
            throw Error(typeMessage === 'custom' ? 'Hay campo(s) sin llenar.': message);
        }
    }
}