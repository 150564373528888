import React from 'react';
import ToggleMenuWrapper from '../../../../components/ToggleMenuWrapper';
import { TITLE__CONFIGURACION } from '../../../../global/title-modules';
import { ElementBreadCrumb } from '../../../../shared/components/ElementBreadCrumb';
import { ElementTab } from '../../../../shared/components/ElementTab';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';
import { EntityListOptions } from '../Domain/EntityListOptions';
import { EntityTabOptions } from '../Domain/EntityTabOptions';
import { ViewContentVariables } from './ViewContentVariables';
import './View.scss';

interface ContainerProps {
    listOption: EntityListOptions;
    formFilters: EntityGenericForm;
    tabOption: EntityTabOptions;
    onChangeTab(newKey: string): void;
    onPagination(evt: any): void;
    onChangeFilter(name: string, value: any): void;
    resetFormFilter(): void;
    searchList(): void;
}

export const View = (props: ContainerProps) => {

    return (
        <ToggleMenuWrapper>
            <div className="ViewPropuestaDAO content">
                <div className="header_content">
                    <div className="title">
                        <ElementBreadCrumb options={[{ route: '', navigate: true, text: TITLE__CONFIGURACION}]} />
                    </div>
                </div>
                <ElementTab
                    className='tab_content'
                    currentKey={props.tabOption.currentKey}
                    onChange={props.onChangeTab}
                    tabs={props.tabOption.tabs}
                >
                    
                    <ViewContentVariables
                        listOption={props.listOption}
                        onPagination={props.onPagination}
                        resetFormFilter={props.resetFormFilter}
                        searchList={props.searchList}
                        formFilters={props.formFilters}
                        onChange={props.onChangeFilter}
                    />
                </ElementTab>
            </div>
        </ToggleMenuWrapper>
    )
};