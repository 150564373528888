import './ElementLoadingLanding.scss';

interface ContainerProps {
    text?: string;
    show:  boolean;
}

export const ElementLoadingLanding = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <div className="ElementLoadingLanding">
            <i className="fa-solid fa-spinner"></i>
            <span>
                { props.text || 'Cargando...' }
            </span>
        </div>
    )
}