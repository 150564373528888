import { useMetamask } from "@thirdweb-dev/react";

export const AdapterWeb3 = () => {
    const connectWallet = useMetamask();

    const connectWalletCustom = async (): Promise<string> => {
        const result: any = await connectWallet();
        if (result.error) throw Error('Ocurrió un error al conectarse con metamask');
        const newAddress: string = result?.data.account || '';
        return newAddress
    }

    const changeChainCustom = async (): Promise<void> => {

        if (!window.ethereum) throw Error('Ocurrió un error al conectarse con metamask')
        // Configurar la red de forma automática según el ambiente
        const requiredChainId = 80001; // 1 for Mainnet, 80001 for Mumbai

        // Obtenemos el ID de la red actual en MetaMask
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        // Comparamos el ID actual con el requerido y si no coinciden, solicitamos el cambio
        if (chainId !== requiredChainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${requiredChainId.toString(16)}`,
                  chainName: 'Mumbai Testnet', // process.env.NODE_ENV === 'production' ? 'Ethereum Mainnet' : 'Mumbai Testnet',
                  nativeCurrency: {
                    name: 'MATIC', //ETH
                    symbol: 'MATIC', //ETH
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc-mumbai.maticvigil.com']//[process.env.NODE_ENV === 'production' ? 'https://mainnet.infura.io/v3/YOUR_INFURA_KEY' : 'https://rpc-mumbai.maticvigil.com'],
                },
              ],
            });
          } catch (error) {
            console.error('Error al agregar la red a MetaMask:', error);
            return;
          }
        }
    }

    const reclaimNFTCustom = async (tokenID: string) => {
      await (window.ethereum as any).request({
          method: 'wallet_watchAsset',
          params: {
          type: 'ERC721',
          options: {
            address: '0xe212B73cF42Cd6dd5F63e396564FB19EB4BeA43E',
            tokenId: `${parseInt(tokenID) - 1}`,
          },
          },
      });
    }

    return ({
        changeChainCustom,
        connectWalletCustom,
        reclaimNFTCustom
    })
}