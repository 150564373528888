/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import TemplateLogin from './../components/Login';
import API_SERVICE from './../services/api';
import { Input } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ForgotPassword = () => {
	const [messages, setMessages] = useState({ messageError: null, messageSucceful: null });
	const [data, setData] = useState({ email: '', password: '', hash: '' })
	const [showMessageInputs, setShowMessageInputs] = useState({ email: false, password: false })
	const [loading, setLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		init();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const init = () => {
		const params = new URLSearchParams(location.search);
		const hash = params.get('guid') || '';

		API_SERVICE.Cuenta_Valida_Hash_Recupera_Password(hash)
		.then((res) => {
			const { result } = res.data;
			if(result === false) window.location.href = '/login';
			setData((prev) => ({...prev, hash}))
		})
		.catch(() => {
			window.location.href = '/login';
		})
	}

	const onSubmit = (evt:any) => {

		evt.preventDefault();
		setLoading(true);
		const validation = onValidate();

		if(validation.length > 0) {
			setMessages({ messageError: null, messageSucceful: null });
			setShowMessageInputs({ email: true, password: true });
			setLoading(false);
			return;
		}

		API_SERVICE.Cuenta_Recupera_Passsword(data)
		.then((res:any) => {
			const { status, data } = res;
			if(status === 200 || status === 201 ){
				setMessages({messageError: null, messageSucceful: data.message})
				// eslint-disable-next-line no-implied-eval
				setTimeout(window.location.href = '/login', 10000);
			}
		})
		.catch((err) => {
			const msg = err.response.data.responseException.exceptionMessage;
			msg.errors ? setMessages({messageError: msg.errors.Email[0], messageSucceful:null}) : setMessages({messageError: msg, messageSucceful:null})
		})
		.finally(() => {
			setLoading(false)
		})
	}

	const onChange = (payload:any) => {
		const { name, value, msg } = payload;
		setData((state:any) => ({ ...state, [name] : value }));
		setMessages((prev) => ({...prev, [msg]: true}))
	}

	const onValidate = () => {
		const { email, password } = data;
		const messagesError = [];
		if( email === undefined || email === null || email === '') messagesError.push('Correo');
		if( password === undefined || password === null || password === '') messagesError.push('Password');
		return messagesError;
	}

	return (
		<TemplateLogin>
				<form onSubmit={onSubmit}>
					<div>
						<div className="header">
							<label className="title">Restablecer nueva contraseña</label>
							<p className="info">
								Establece una nueva contraseña para recuperar tu cuenta.
							</p>
						</div>
						<div className="message">
								{ messages.messageError &&  <Spin spinning={loading}><div className="msgError"> <a>x</a><label>{ messages.messageError }</label> </div> </Spin>}
								{ messages.messageSucceful &&  <Spin spinning={loading}><div className="msgSuccess"> <a>✓</a><label>{ messages.messageSucceful }</label> </div> </Spin>}
						</div>
						<div className="body">
							<div className="block-input">
								<label>Correo Electrónico:</label>
								<Input 
									placeholder="Correo"
									value={ data.email }
									onChange={ (e:any) => onChange({name:'email', value:e.target.value, msg:'msgEmail'}) }
								/>
								{ showMessageInputs.email && !data.email && <p> Ingrese un Correo </p> }
							</div>
							<div className="block-input">
								<label>Nueva contraseña:</label>
								<Input.Password 
									type="password" 
									placeholder="contraseña"
									value={ data.password } 
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
									onChange={ (e:any) => onChange({name:'password', value:e.target.value, msg:'msgPassword'}) }
								/>
								{ showMessageInputs.password && !data.password && <p> Ingrese una Contraseña </p> }
							</div>
						</div>
						<div className="submit">
							<Spin indicator={antIcon} spinning={loading} size="small">
								<button> Establecer </button>
							</Spin>
						</div>
					</div>
				</form>
		</TemplateLogin>
	)
}

export default ForgotPassword;