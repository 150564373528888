import * as React from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Select, Input, Breadcrumb, Row, message, Col, Form } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { List } from './../../../components/lists/list'

class Usuario extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
            loading:false,
            List: [],
            onlyOne:{
                estado: 'A'
            },
            visible: false,
            editOrCreate: 1
		};
    }
    
    componentDidMount(){
        this.HandleSearch();
    }

    HandleSearch = () => {
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Lista_Usuario_Call(resolve, reject)
        })
        .then(() => {
            const { List } = this.props;
            this.setState({List});
        })
        .catch((err) => {})
    }

    /*---------------Funciones la Lista--------------------*/

    buttons = (val:any) => {
		return (
			<div>
				<button onClick={ () => this.functionsCRUD(4, val.id) }><EditOutlined /></button>
                <button style={{ backgroundColor: '#373536'}} onClick={ () => this.functionsCRUD(3, val.id) }><DeleteOutlined /></button>	
			</div>
		)
    }
    
    /*-----------------------------------------------------*/
    /*---------------------- Modal ------------------------*/

    HandleOpenModal = () => {
		this.setState({visible:true})
	}

	handleCloseModal  = () => {
		this.setState({visible:false, onlyOne:{estado: 'A'}, editOrCreate: 1})
	}

	renderModal = () => {
        const { visible } = this.state;  

		return(
			<Modal
                title={ this.renderHeaderModal("Usuario") }
                visible={visible}
                onCancel={ () => this.handleCloseModal() }
                footer={null}
                style={{width:'500px'}}
            >
			  { this.renderContentModal() }
			  <div className='buttons'>
				  <div className='cancel'>
				  	<button onClick={ () => this.handleCloseModal()}>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<button onClick = { () => this.handleSave() }>Guardar</button>
				  </div>
			  </div>
		  </Modal>
		)
	}

	renderContentModal = () => {
		const { onlyOne } = this.state;
		return(
			<div className='form__Modal'>
                <Form>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;CORREO: </label>
                            <Input onChange = { (e:any) => this.onChange({email: e.target.value }) } value = {onlyOne.email} type="email"/>
                        </Col>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;NOMBRE: </label>
                            <Input onChange = { (e:any) => this.onChange({nombre: e.target.value }) } value = {onlyOne.nombre} />
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;APELLIDO PATERNO: </label>
                            <Input onChange = { (e:any) => this.onChange({apellidoPaterno: e.target.value }) } value = {onlyOne.apellidoPaterno} />
                        </Col>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;APELLIDO MATERNO: </label>
                            <Input onChange = { (e:any) => this.onChange({apellidoMaterno: e.target.value }) } value = {onlyOne.apellidoMaterno} />
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;ESTADO: </label> 
                            <br/>
                            <Select 
                                value= {onlyOne.estado}
                                style={{width:'100%'}}
                                onChange = { (value:any) => this.onChange({estado: value})}
                                options = {[{label:'Activo', value: 'A'}, {label:'Inactivo', value: 'I'}]}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}

	renderHeaderModal = (title:string) => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item > <a style={{fontSize:'20px'}}>{ title }</a> </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
    }
    
    handleSave = () => {
        const { editOrCreate } = this.state;  

        const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}

        this.functionsCRUD(editOrCreate);
    }

	handleValidation = () => {
		const { onlyOne } = this.state;
		const listTemp = [];
		if(onlyOne.email == null || onlyOne.email == undefined || onlyOne.email == "" ) listTemp.push("Ingrese un Correo");
		if(onlyOne.nombre == null || onlyOne.nombre == undefined || onlyOne.nombre == "" ) listTemp.push("Ingrese un Nombre");
		if(onlyOne.apellidoPaterno == null || onlyOne.apellidoPaterno == undefined || onlyOne.apellidoPaterno == "" ) listTemp.push("Ingrese un Apellido Paterno");
		if(onlyOne.apellidoMaterno == null || onlyOne.apellidoMaterno == undefined || onlyOne.apellidoMaterno == "" ) listTemp.push("Ingrese un Apellido Materno");
		if(onlyOne.estado == null || onlyOne.estado == undefined || onlyOne.estado == "" ) listTemp.push("Ingrese un Estado");

		return listTemp
    }
    

    /*-----------------------------------------------------*/
    /*-------------------- functions ----------------------*/

    handleCreate = () => {
        const { onlyOne } = this.state;
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Agrega_Usuario_Call(onlyOne, resolve, reject)
        })
        .then((res:any) => {
            const { List } = this.props;
            this.setState({List});
            this.handleCloseModal();
            message.success(res)
        })
        .catch((err) => {
            message.error(err);
        })
    }

    handleUpdate = () => {
        const { onlyOne } = this.state;
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Actualiza_Usuario_Call(onlyOne, resolve, reject)
        })
        .then((res:any) => {
            const { List } = this.props;
            this.setState({List});
            this.handleCloseModal();
            message.success(res);
        })
        .catch((err) => {
            message.error(err);
        })
    }
    
    handleDelete = (Id:number) => {
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Elimina_Usuario_Call(Id, resolve, reject)
        })
        .then((res:any) => {
            message.success(res);
        })
        .catch((err) => {
            message.error(err);
        })
    }

    handleSelecciona = (Id:number) => {
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Selecciona_Usuario_Call(Id, resolve, reject)
        })
        .then(() => {
            const { onlyOne } = this.props;
            this.setState({onlyOne, editOrCreate: 2, visible: true})
        })
        .catch((err) => {

        })
    }

    functionsCRUD = (type: number, Id?:any) => {
        switch(type){
            case 1: this.handleCreate();break;
            case 2: this.handleUpdate();break;
            case 3: this.handleDelete(Id);break;
            case 4: this.handleSelecciona(Id);break;
        }
    }

    /*-----------------------------------------------------*/
	onChange = (value:any) => {
        this.setState((state:any) => ({
            ...state, onlyOne:{
                ...state.onlyOne,
                ...value
            }
        }))
    }

	render() {

		return (
			<ToggleMenuWrapper>
				<div className="content">
					<div className="header_content">
                        <div className="title">
                            <Breadcrumb separator="|">
									<Breadcrumb.Item> Usuarios </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="button" style={{paddingLeft:'175px'}}>
							<button onClick={() => this.HandleOpenModal()}>
								Crear
							</button>
						</div>
                    </div>		
                    { this.renderModal() }	
					<div className="content_list">
						<div className="search" style={{borderRadius: "10px 10px 0px 0px"}}>

						</div>
						<div className="list" style={{paddingBottom:'30px'}}> 
                            <List 
                                datasource={this.state.List}
                                columns={metadata(this.buttons)}
                                pagination={true}
                                bordered={false}
                                //bordered
                            /> 
						</div>
					</div>
				</div>
			</ToggleMenuWrapper>
		)
	}
}

const metadata = (buttons:any) => [
    {title: 'ID', dataIndex: 'id',key: 'id'},
    {title: 'Correo', dataIndex: 'email', key: 'email'},
    {title: 'Nombre', dataIndex: 'nombre', key: 'nombre'},
    {title: 'Estado', dataIndex: 'estado', key: 'estado'},
    {title: 'Tipo de usuario', dataIndex: 'idUsuarioTipo', key: 'idUsuarioTipo'},
    {title: 'Fecha de Registro', dataIndex: 'fechaRegistro', key: 'fechaRegistro'},
    {title: 'Acciones', dataIndex: '', key: '',   width:'150px', render: (text:any, record:any) => buttons(record)}
   ]

const mapStateToProps= (state:any) => ({
    List: state.cuenta.usuario.list,
    onlyOne: state.cuenta.usuario.onlyOne
})

const mapDispatchToProps = (dispatch:any) => {
    const { ACuenta_Lista_Usuario_Call, ACuenta_Selecciona_Usuario_Call, ACuenta_Actualiza_Usuario_Call,
            ACuenta_Agrega_Usuario_Call, ACuenta_Elimina_Usuario_Call } = Actions;
    return bindActionCreators({
        ACuenta_Lista_Usuario_Call,
        ACuenta_Selecciona_Usuario_Call,
        ACuenta_Actualiza_Usuario_Call,
        ACuenta_Agrega_Usuario_Call,
        ACuenta_Elimina_Usuario_Call
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);