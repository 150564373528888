
export const ViewHistoryEnd = () => {
    return (
        <div className='ViewHistoryEnd'>
            <div className='ContentThanks'>
                <h1 className='fnt-bold'>GRACIAS</h1>
                <p className='fnt-regular'>
                    Que conozcas todos los procesos por los que pasa esta
                    prenda, permite reconocer eficientemente el trabajo de
                    todos los artesanos e industrias que se dedican a
                    producir los hilos y los tejidos que visten a todo el
                    mundo.
                </p>
                <p className='fnt-regular'>
                    Desde ahora tú también eres parte de:
                    <span className='fnt-bold boldText'>La historia de la prenda</span>
                </p>
                <div className='ContentLine'>
                    <h1 className='fnt-regular'>Vive la experiencia <span className='fnt-bold'> BLOCKCHAIN</span></h1>
                    <p className='fnt-regular'>
                        Por la compra de esta prenda, serás acreedor
                        de un <span className='fnt-bold'>NFT</span>, una tecnología increíble que
                        registra esta prenda a nivel mundial como un
                        producto <span className='fnt-bold'>totalmente único</span>
                    </p>
                </div>
            </div>
        </div>
    )
}