import React, { useEffect } from 'react';
import { View } from "./components/View";
import { ViewModal } from './components/ViewModal';
import { Controller } from './Infraestructure/Controller';

const ViewMain = () => {
    const controller = Controller();

    useEffect(() => {
        controller.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <View
                listOption={controller.listOption}
                formFilters={controller.formFilter}
                loadingFilter={controller.loadingFilter}
                onPagination={controller.onPagination}
                onChangeFilter={controller.onChangeFilter}
                resetFormFilter={controller.resetFormFilters}
                searchList={controller.searchList}

                openModalModeCreate={controller.openModalModeCreate}
            />
            <ViewModal
                closeModal={controller.closeModal}
                modalOptions={controller.modalOptions}
                formItem={controller.formItem}
                onChangeFormItem={controller.onChangeFormItem}
                onSaveFormItem={controller.onSaveFormItem}
                loading={controller.loading}
            />
        </>
    )
}

export default ViewMain;