import React from 'react';
import { EntityGenericForm } from '../../../../../shared/Domain/EntityGenericForm';

interface ContainerProps {
    onSubmit(evt: React.FormEvent<HTMLFormElement>): Promise<any>;
    formWallet: EntityGenericForm;
    show: boolean;
    loading: boolean;
}

export const ViewContentForm = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <>
            <div className='GroupText ff-Mont-Family'>
                <p>¡Felicidades por tu compra! Gracias a ella, apoyas el trabajo constante de la industria textil, bajo un enfoque sostenible y ecoamigable.</p>
                <p>Ahora, es momento de recibir tu NFT. Si tienes dudas, <a href="https://sdf-trazabilidad.magiadigital.com/assets/SDF%20-%20C%C3%B3mo%20usar%20mi%20NFT.pdf" target="_blank" rel="noreferrer">puedes revisar nuestra guía</a>; si no, da clic en el botón para agregar tu cuenta y continuar.</p>
            </div>
            <div className='GroupInput ff-Mont-Family'>
                <form className={`InputIcon ${(props.formWallet.touched['wallet'] && props.formWallet.errors['wallet']) ? 'inputError' : ''}`} onSubmit={(evt) => props.onSubmit(evt)}>
                    <input
                        placeholder='Billetera Virtual'
                        type="text"
                        value={props.formWallet.values['wallet']}
                        disabled
                    />
                    { (props.formWallet.touched['wallet'] && props.formWallet.errors['wallet']) ? <span className='messageError'>{props.formWallet.errors['wallet']}</span> : null }
                    <button disabled={props.loading} type='submit'> Obtener NFT </button>
                </form>
            </div>
        </>
    )
}