export class AdapterLocalStorage {

    public static get(params: string | string[]) {
        let data: any = {};
        let templateName = `${process.env.REACT_APP_NAME}_${process.env.REACT_APP_VERSION}_`;
        if (Array.isArray(params)) {
            params.forEach(row => {
                let response = localStorage.getItem(`${templateName}${row}`);
                Object.assign(data, { [row]: response })
            })
        } else {
            Object.assign(data, {[params]: localStorage.getItem(`${templateName}${params}`)});
        }

        return data;
    }

    public static set(params: { key: string; value: string; } | { key: string; value: string; }[]) {
        let templateName = `${process.env.REACT_APP_NAME}_${process.env.REACT_APP_VERSION}_`;

        if (Array.isArray(params)) {
            params.forEach(row => localStorage.setItem(`${templateName}${row.key}`, row.value))    
        } else {
            localStorage.setItem(`${templateName}${params.key}`, params.value);
        }
    }

    public static remove(params: string | string[]) {
        let templateName = `${process.env.REACT_APP_NAME}_${process.env.REACT_APP_VERSION}_`;
        if (Array.isArray(params)) {
            params.forEach(row => localStorage.removeItem(`${templateName}${row}`))
        } else {
            localStorage.removeItem(`${templateName}${params}`);
        }
    }

    public static clearAll() {
        localStorage.clear();
    }
}