import { Button } from 'antd';
import React from 'react';
import './ElementTab.scss';

interface ContainerProps {
    tabs: {
        key: string;
        label: string;
    }[];
    onChange(key: string): void;
    currentKey: string;
    className?: string;
    children: JSX.Element | JSX.Element[];
}

export const ElementTab = (props: ContainerProps) => {
    

    return (
        <div className={`ElementTab ff-Mont-Family ${props.className || ''}`}>
            <div className='HeaderElementTab'>
                {
                    props.tabs.map(row => <Button className={`ElementTabButton ${row.key === props.currentKey ? 'ElementTabButton-Active' : ''}`} key={row.key} onClick={() => props.onChange(row.key)}> {row.label} </Button> )
                }
            </div>
            <div className='ContentElementTab'>
                {props.children}
            </div>
        </div>
    )
}