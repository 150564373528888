import React from 'react';
import Proceso from './../../assets/images/Menu/Proceso.svg';
import stakeholder from './../../assets/images/Menu/Stakeholder.svg';
import comercio from './../../assets/images/Menu/Comercializacion.png';
import Reporte from './../../assets/images/Menu/Reporte.svg';
import Propuesta from './../../assets/images/Menu/Proposals.svg';

interface IModuleButton {
    title: string,
    url: string,
    icon: string,
    Action: Function
}

const TemplateTitle = (title:string) => {
    return title.toUpperCase();
}

const Button = (props: IModuleButton) => {
    let icon = "";
    let title = TemplateTitle(props.title);
    switch(props.icon){
        case "menu-cadenavalor": icon = "fa-solid fa-shirt"; break;
        case "menu-stakeholders": icon = "fa-solid fa-users"; break;
        case "menu-reportes": icon = "fa-solid fa-sheet-plastic"; break;
        case "menu-propuestas": icon = "fa-solid fa-square-poll-horizontal"; break;
        case "menu-configuracion": icon = "fa-solid fa-gears"; break;
        default: icon = "fa-solid fa-shirt";
    }

    return(
        <div>
            <button type="button" onClick={() => props.Action(props.url)}> 
                <i className={icon} style={{ fontSize: 45 }}/>
                <p>{title}</p>
            </button>
        </div>
    )   
}

export default Button;