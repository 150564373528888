import React, { PureComponent } from 'react';
import './../assets/styles/login.scss'
import MagicChain from './../assets/images/logo/logo-MagicChain.svg';
import Empresa from './../assets/images/logo/logo-empresa.svg';

interface Ilogin {
    children:React.ReactNode
}

class Login extends PureComponent<Ilogin> {

    render(){ 
        return (
            <div className="main__login">
                <div>
                    <div className="content_image">
                        <img height={55} src={MagicChain} alt="not source magicchain"/>
                    </div>
                    <div className="panel">
                        <div className="form">
                            {this.props.children}
                        </div>
                        <div className="image"> 
                            <div className="panel">
                                <div className="logo">
                                    <img src={Empresa} height={80} width='auto' alt="logo enterprise"/>
                                </div>
                                <div className="info">
                                    <label>Bienvenido al Sistema de Trazabilidad</label>
                                    <p>Iniciar sesión</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;