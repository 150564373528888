import { AdapterLocalStorage } from "../shared/Infraestructure/AdapterLocalStorage";

const menu = JSON.parse(AdapterLocalStorage.get('menu').menu) || [];

const findTitle = (route:any, menu:[]) => {
    let temp = ''
    menu?.map((item:any) => {
        if(item.routing === route) temp = item.title;
        return item;
    })

    return temp;
}

export const TITLE__PROCESO = 'Prendas'; //findTitle('procesos', menu);
export const TITLE__STAKEHOLDER = findTitle('stakeholders', menu);
export const TITLE__COMERCIO = findTitle('comercializacion', menu);
export const TITLE__REPORTE = findTitle('reporte-trazabilidad', menu);
export const TITLE__PROPUESTADAO = findTitle('propuestas-dao', menu);
export const TITLE__CONFIGURACION = findTitle('configuracion', menu);