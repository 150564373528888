import React from 'react';
import { Breadcrumb } from "antd"

interface ContainerProps {
    options: {
        route?: string;
        navigate: boolean;
        text: string;
    }[]
}

export const ElementBreadCrumb = (props: ContainerProps) => {
    return (
        <Breadcrumb separator="|" className='ff-Mont-Family'>
            {
                props.options.map(row => <Breadcrumb.Item href={`${row.route}`}> {row.navigate ? row.text : <a> {row.text} </a>} </Breadcrumb.Item> )
            }
        </Breadcrumb>
    )
}