import { Button, Modal } from 'antd';
import React from 'react';
import { ElementUpload } from '../../../../shared/components/ElementUpload';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';
import { EntityModalOptions } from '../Domain/EntityModalOptions';
import './ViewModal.scss'

interface ContainerProps {
    modalOptions: EntityModalOptions;
    formModal: EntityGenericForm;
    loadingFormModal: boolean;
    closeModal(): void;
    onChange(name: string, value: any): void;
    onSaveModal(): void;
}

export const ViewModal = (props: ContainerProps) => {

    return (
        <Modal
            title={props.modalOptions.title}
			visible={props.modalOptions.show}
			onCancel={props.closeModal}
            footer={null}
            width={600}
            destroyOnClose
            className='ViewModalConfiguracion ff-Mont-Family'
        >
            <>
                <div className='form__Modal'>
                    <p className='messageConfiguracion'>
                        <span>IMPORTANTE:</span> Recuerda que al cargar un nuevo archivo, todos los valores del actual serán sobrescritos; sin embargo, los registros ya existentes no cambiarán su información. Además, el archivo a cargar debe respetar el formato indicado en el Manual de Usuario; de lo contrario, no será admitido por el Sistema.
                    </p>
                    <div className='uploadConfiguracion'>
                        <label>Cargar nuevo*</label>
                        <ElementUpload onChange={props.onChange} name='file' value={props.formModal.values['file']} formatUpload={['csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']} />
                    </div>

                </div>
                <div className='buttons'>
                    <div className='cancel'>
                    <Button onClick={props.closeModal}>Cancelar</Button>
                    </div>
                    <div className='save'>
                    <Button 
                        onClick={props.onSaveModal}
                        loading={props.loadingFormModal}
                        disabled={props.loadingFormModal}
                        className={ props.loadingFormModal ? 'btn-disabled' : '' }
                    >
                        Guardar
                    </Button>
                    </div>
                </div>
            </>
        </Modal>
    )
}