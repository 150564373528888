import React, { Component } from 'react';
import { DatePicker, Select, Breadcrumb, Table, Spin   } from 'antd';
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './../../../assets/styles/lists.scss';
import { List } from './../../../components/lists/list';
import { EyeOutlined } from '@ant-design/icons';

const { Option } = Select;

class ReportProcess extends Component<any,any>{

    constructor(props:any){
        super(props);
        this.state = {
            search:{
                Etapas: []
            },
            loading: false,
            listE: []
        }
    }

    componentDidMount(){

        const Grupo = 'Joyas';

        this.setState({search:{...this.state.search, Grupo}});
        
        new Promise((resolve:any, reject:any) => {
			this.props.AProceso_Lista_Proceso_Metadata_Call({}, resolve, reject, () => console.log('Not use'));
		})
		.then(() => {
            
            this.setState({listE: this.props.list_E.etapas, loading:false});
            this.HandleSearch();
		})
		.catch(() => {
			this.setState({loading:false})
			alert('error')
        })
    }

    onChange = (payload:any) => {
        const { name, value } = payload;
        this.setState((state:any) => ({
            ...state, search:{
                ...state.search,
                [name] : value
            }
        }))
    }

    HandleSearch = () => {
        const { search } = this.state, formData = new URLSearchParams();

        this.setState({loading:true})

        /*
            const ids = new URLSearchParams();
            selectedRowKeys.forEach((item) => {
            ids.append('ids', item);
            });
        */
       
        search.Etapas.forEach((item:any) => formData.append('Etapas', item));
        formData.append('Grupo', search.Grupo);

        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Lista_Procesos_Call(formData, resolve, reject)
        })
        .then((res:any) => {
            this.setState({loading:false})
        })
        .catch(() => {
            this.setState({loading:false})
        })
    }

    handleDownload = () => {
        const { search } = this.state;

        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Descarga_Excel_Proceso_Call(search, resolve, reject)
        })
        .then((res:any) => {
            this.DowloadExcel(this.props.Base64Excel);
        })
        .catch(() => {
        })
    }

    DowloadExcel = (base64:any) => {
        const { grupo, lote } = this.props.match.params;    
        //const { CodLote } = this.state;
        var date = new Date();
        var Fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        var Tiempo = date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

        
        const Nombre = `reporte-${grupo.toLowerCase()} ${Fecha} ${Tiempo}`;

        var a = document.createElement("a");
        a.href = base64;
        a.download = `${Nombre}.xlsx`;
        a.click();
    }

    renderButtons = (value:any) => (
        <button onClick={() => console.log(value)}> <EyeOutlined /> </button>
    )

    render(){

        return(
            <ToggleMenuWrapper>
                <div className="content">
                    <div className="content_list">
                        <div className="header_content">
                            <div  className="title">
                                <div style={{whiteSpace: 'nowrap'}}>
                                    <Breadcrumb separator="|">
                                        <Breadcrumb.Item href={`/procesos`}> Joyas de Oro y Piedras </Breadcrumb.Item>
                                        <Breadcrumb.Item href={''}> <a> Reporte  </a> </Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div>
                                <h1 style={{fontSize:'14px'}}>Para generar un Reporte, completa la siguiente información:</h1>
                                </div>
                            </div>
                        </div>
                        <div className="search" style={{borderRadius:'10px 10px 0px 0px'}}>
							<div className="inputs">
								<p>FECHA DE REGISTRO:</p>
								<DatePicker placeholder='Fecha Inicio' onChange={(value) => this.onChange({name:'FechaIncicial', value})}/>
								<DatePicker placeholder='Fecha Fin' onChange={(value) => this.onChange({name:'FechaFinal', value}) }/>
							</div>
							<div className="inputs">
                                <p>PROCESO: </p>
                                <Select mode="tags" 
                                    allowClear  
                                    style={{width:'180px'}}
                                    dropdownMatchSelectWidth={false}
                                    onChange={(value:any) => this.onChange({name:'Etapas', value})}
                                >
                                    { this.state.listE.map((item:any) =>  <Option value={item.id} key={item.id}>{ item.nombre }</Option>) }
                                </Select>
							</div>
                            <div className="inputs" style={{paddingTop:'55px'}}>
                                <Spin spinning={this.state.loading}>
                                    <button style={{ marginLeft: '10px' }} onClick={() => this.HandleSearch()}>Buscar</button>
                                </Spin>
                            </div>
                            <div className="inputs" style={{paddingTop:'55px'}}>
                                <button style={{ marginLeft: '90px', backgroundColor:'#00aeef' }} onClick={() => this.handleDownload()}>Descargar</button>
                            </div>
						</div>
						<div className="list">
                            <List 
                                datasource={this.props.List_Proceso}
                                columns={metadata(this.renderButtons)}
                            /> 
						</div>
					</div>
                </div>
            </ToggleMenuWrapper>
        )
    }
}

const metadata = (buttons:any) => [
    {title: 'ID', dataIndex: 'id',key: 'id'},
    //{title: 'Nombre del registro', dataIndex: 'codeLote', key: 'codeLote'},
    {title: 'Proceso', dataIndex: 'etapa', key: 'etapa'},
    {title: 'Fecha', dataIndex: 'fechaRegistro', key: 'fechaRegistro'},
    {title: 'Lugar Geográfico', dataIndex: 'lugar_geografico', key: 'lugar_geografico'},
    {title: 'Proveedor (Minera)', dataIndex: 'proveedor_minera', key: 'proveedor_minera'},
    {title: 'N° Factura', dataIndex: 'nro_factura', key: 'id'},
    {title: 'N° Guía de Remisión', dataIndex: 'guia_remision', key: 'id'},
    {title: 'Blockchain', dataIndex: '', key: '', render: (text:any, record:any) => buttons(record)}
   ]

const mapStateToProps= (state:any) => ({
    List_Etapa: state.reporte.etapa,
    List_Proceso: state.reporte.proceso,
    Base64Excel: state.reporte.excel,
    list_E: state.proceso.metadata
})

const mapDispatchToProps = (dispatch:any) => {
	const { AReporte_Descarga_Excel_Proceso_Call, AReporte_Lista_Etapa_Call, AReporte_Lista_Procesos_Call, AProceso_Lista_Proceso_Metadata_Call } = Actions;
    return bindActionCreators({
        AReporte_Descarga_Excel_Proceso_Call,
        AReporte_Lista_Etapa_Call,
        AReporte_Lista_Procesos_Call,
        AProceso_Lista_Proceso_Metadata_Call
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportProcess);