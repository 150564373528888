import * as functionsCuenta from './functions/cuenta';
import * as functionsProceso from './functions/proceso';
import * as functionStakeholder from './functions/stakeholders';
import * as functionsCadena from './functions/cadena-valor';
import * as functionsReporte from './functions/reporte';
import * as functionsComercio from './functions/comercio';
import IApiPropTypes from './proptypes';
import { AdapterLocalStorage } from '../shared/Infraestructure/AdapterLocalStorage';

const getHeader = () => {
    return {
        headers : {
            Authorization: `Bearer ${AdapterLocalStorage.get('token').token}`
        }
    };
}

const API_SERVICE: IApiPropTypes = {} as IApiPropTypes;
/**
 * functions for Cuenta api
 * @keyword Cuenta
 */
 API_SERVICE.Cuenta_Login = (Body:object) => functionsCuenta.Cuenta_Login(Body);
 API_SERVICE.Cuenta_Login_Hash = (hash:string) => functionsCuenta.Cuenta_Login_Hash(hash);

 API_SERVICE.Cuenta_Solicita_Recupera_Password = (Body:object) => functionsCuenta.Cuenta_Solicita_Recupera_Cuenta(Body);
 API_SERVICE.Cuenta_Valida_Hash_Recupera_Password = (hash:string) => functionsCuenta.Cuenta_Valida_Hash_Recupera_Password(hash);
 API_SERVICE.Cuenta_Valida_Hash_Activa_Cuenta = (hash:string) => functionsCuenta.Cuenta_Valida_Hash_Activa_Cuenta(hash);
 API_SERVICE.Cuenta_Activa_Cuenta = (body:object) => functionsCuenta.Cuenta_Activa_Cuenta(body);
 API_SERVICE.Cuenta_Recupera_Passsword = (Body:object) => functionsCuenta.Cuenta_Recupera_Passsword(Body);
 API_SERVICE.Cuenta_Cambia_Password = (Body:object) => functionsCuenta.Cuenta_Cambia_Password(Body, getHeader()); 
 API_SERVICE.Cuenta_Tablero_Control = () => functionsCuenta.Cuenta_Tablero_Control(getHeader());
 API_SERVICE.Cuenta_Menu_Lateral = () => functionsCuenta.Cuenta_Menu_Lateral(getHeader());  

 API_SERVICE.Cuenta_Agrega_Usuario = (Body:object) => functionsCuenta.Cuenta_Agrega_Usuario(Body, getHeader());
 API_SERVICE.Cuenta_Actualiza_Usuario = (Body:object) => functionsCuenta.Cuenta_Actualiza_Usuario(Body, getHeader());
 API_SERVICE.Cuenta_Elimina_Usuario = (Id:number) => functionsCuenta.Cuenta_Elimina_Usuario(Id, getHeader());
 API_SERVICE.Cuenta_Lista_Usuario = () => functionsCuenta.Cuenta_Lista_Usuario(getHeader());
 API_SERVICE.Cuenta_Selecciona_Usuario = (Id:number) => functionsCuenta.Cuenta_Selecciona_Usuario(Id, getHeader());

 API_SERVICE.Cuenta_Agrega_Usuario_stakeholder = (Id:number, Body:object) => functionsCuenta.Cuenta_Agrega_Usuario_Stakeholder(Id, Body, getHeader());
 API_SERVICE.Cuenta_Actualiza_Usuario_stakeholder = (Id:number, Body:object) => functionsCuenta.Cuenta_Actualiza_Usuario_Stakeholder(Id, Body, getHeader());
 API_SERVICE.Cuenta_Elimina_Usuario_stakeholder = (Id:number) => functionsCuenta.Cuenta_Elimina_Usuario_Stakeholder(Id, getHeader());
 API_SERVICE.Cuenta_Lista_Usuario_stakeholder = (data:any) => functionsCuenta.Cuenta_Lista_Usuario_Stakeholder(data, getHeader());
 API_SERVICE.Cuenta_Selecciona_Usuario_stakeholder = (Id:number) => functionsCuenta.Cuenta_Selecciona_Usuario_Stakeholder(Id, getHeader());

 API_SERVICE.Cuenta_Descarga_Qr = (id:number) => functionsCuenta.Cuenta_Descarga_Qr(id, getHeader());
 API_SERVICE.Cuenta_Descarga_Qr_Zip = (params:any) => functionsCuenta.Cuenta_Descarga_Qr_Zip(params, getHeader());

/**
 * functions for Cuenta api
 * @keyword Proceso
 */
 API_SERVICE.Proceso_Selecciona = (Id:number) => functionsProceso.Proceso_Selecciona(Id, getHeader());
 API_SERVICE.Proceso_Actualiza = (Body:object) => functionsProceso.Proceso_Actualiza(Body, getHeader()); 
 API_SERVICE.Proceso_Agrega = (Body:object) => functionsProceso.Proceso_Agrega(Body, getHeader()); 
 API_SERVICE.Proceso_Lista_Proceso_Metadata = () => functionsProceso.Proceso_Lista_Proceso_Metadata(getHeader());
 API_SERVICE.Proceso_Lista_Proceso_Etapa = (Body:object ) => functionsProceso.Proceso_Lista_Proceso_Etapa(Body, getHeader());
 API_SERVICE.Proceso_Lista_Etapas_Parent = (Id:number ) => functionsProceso.Proceso_Lista_Etapas_Parent(Id, getHeader());
 API_SERVICE.Proceso_Lista_Registros_Parent = (Body:object ) => functionsProceso.Proceso_Lista_Registros_Parent(Body, getHeader());
 API_SERVICE.Proceso_Registros_Confeccion = (Body:object) => functionsProceso.Proceso_Registros_Confeccion(Body, getHeader());
 API_SERVICE.Proceso_Genera_Producto_Code = (Body:string) => functionsProceso.Proceso_Genera_Producto_Code(Body, getHeader());
 API_SERVICE.Proceso_Lista_Producto_Code = (Id:number ) => functionsProceso.Proceso_Lista_Producto_Code(Id, getHeader());
 API_SERVICE.Proceso_Descargar_QR = (Id:number) => functionsProceso.Proceso_Descarga_Qr(Id, getHeader());
 API_SERVICE.Proceso_Lista_Codesku = () => functionsProceso.Proceso_Lista_Codesku(getHeader());
 API_SERVICE.Proceso_Descarga_Pin = (Id:number) => functionsProceso.Proceso_Descarga_Pin(Id, getHeader());
 API_SERVICE.Proceso_Actualizar_Trazabilidad_Blockchain = () => functionsProceso.Proceso_Actualizar_Trazabilidad_BlockChain(getHeader());

 /**
 * @keyword Stakeholder
 */
 API_SERVICE.Stakeholder_agrega_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Agrega_Stakeholdder(Body, getHeader());
 API_SERVICE.Stakeholder_actualiza_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Actualiza_Stakeholdder(Body, getHeader());
 API_SERVICE.Stakeholder_elimina_stakeholder = (Id:number) => functionStakeholder.Stakeholder_Elimina_Stakeholder(Id, getHeader());
 API_SERVICE.Stakeholder_lista_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Lista_Stakeholder(Body, getHeader());
 API_SERVICE.Stakeholder_lista_stakeholder2 = () => functionStakeholder.Stakeholder_Lista_Stakeholder2(getHeader());
 API_SERVICE.Stakeholder_selecciona_Stakeholder = (Id:number) => functionStakeholder.Stakeholder_Selecciona_Stakeholder(Id, getHeader());

 API_SERVICE.Stakeholder_agrega_tipo_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Agrega_Tipo_Stakeholdder(Body, getHeader());
 API_SERVICE.Stakeholder_actualiza_tipo_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Actualiza_Tipo_Stakeholdder(Body, getHeader());
 API_SERVICE.Stakeholder_elimina_tipo_stakeholder = (Id:number) => functionStakeholder.Stakeholder_Elimina_Tipo_Stakeholder(Id, getHeader());
 API_SERVICE.Stakeholder_lista_tipo_stakeholder = (Body:object) => functionStakeholder.Stakeholder_Lista_Tipo_Stakeholder(Body, getHeader());
 API_SERVICE.Stakeholder_selecciona_tipo_Stakeholder = (Id:number) => functionStakeholder.Stakeholder_Selecciona_Tipo_Stakeholder(Id, getHeader());
 /**
 * @keyword Cadena Valor
 */
API_SERVICE.Cadena_Valor_Lista_Etapa = () => functionsCadena.Cadena_Valor_Lista_Etapa(getHeader()); 

 API_SERVICE.Cadena_Valor_Agrega = (Body:object) => functionsCadena.Cadena_Valor_Agrega(Body, getHeader());
 API_SERVICE.Cadena_Valor_Actualiza = (Body:object) => functionsCadena.Cadena_Valor_Actualiza(Body, getHeader());
 API_SERVICE.Cadena_Valor_Elimina = (Id:number) => functionsCadena.Cadena_Valor_Elimina(Id, getHeader());
 API_SERVICE.Cadena_Valor_Lista = (Grupo:string) => functionsCadena.Cadena_Valor_Lista(getHeader(), Grupo);

 API_SERVICE.Cadena_Valor_Agrega_Lote = (Body:object) => functionsCadena.Cadena_Valor_Agrega_Lote(Body, getHeader());
 API_SERVICE.Cadena_Valor_Actualiza_Lote = (Body:object) => functionsCadena.Cadena_Valor_Actualiza_Lote(Body, getHeader());
 API_SERVICE.Cadena_Valor_Elimina_Lote = (Id:number) => functionsCadena.Cadena_Valor_Elimina_Lote(Id, getHeader());
 API_SERVICE.Cadena_Valor_Selecciona_Lote = (Id:number) => functionsCadena.Cadena_Valor_Seleciona_Lote(getHeader(), Id);
 /**
 * @keyword Reporte
 */
 API_SERVICE.Reporte_Lista_Etapa = (Body:string) => functionsReporte.Reporte_Lista_Etapa(Body, getHeader()); 
 API_SERVICE.Reporte_Lista_Proceso = (Body:object) => functionsReporte.Reporte_Lista_Proceso(Body, getHeader());
 API_SERVICE.Reporte_Descarga_Excel_Proceso = (Body:object) => functionsReporte.Reporte_Descargar_Excel_Proceso(Body, getHeader());  
 API_SERVICE.Reporte_Detalle_Proceso = (Id:number) => functionsReporte.Reporte_Detalle_Proceso(Id, getHeader());
 /**
 * @keyword Comercio
 */
 API_SERVICE.Comercio_Actualiza = (Body:object) => functionsComercio.Comercio_Actualiza(Body, getHeader());
 API_SERVICE.Comercio_Agrega = (Body:object) => functionsComercio.Comercio_Agrega(Body, getHeader());
 API_SERVICE.Comercio_Elimina = (Id:number) => functionsComercio.Comercio_Elimina(Id, getHeader());
 API_SERVICE.Comercio_Selecciona = (Id:number) => functionsComercio.Comercio_Selecciona(Id, getHeader());
 API_SERVICE.Comercio_Lista = (Body:object) => functionsComercio.Comercio_Lista(getHeader(), Body);
 API_SERVICE.Comercio_Lista_Cadena_Valor = (Grupo:string) => functionsComercio.Comercio_Lista_Cadena_Valor(Grupo, getHeader());
 API_SERVICE.Comercio_Lista_Lote = (Id:number) => functionsComercio.Comercio_Lista_Lote(Id, getHeader());
 API_SERVICE.Comercio_Descarga_Excel = (Body:any) => functionsComercio.Comercio_Descarga_Excel(Body, getHeader()); 

export default API_SERVICE;