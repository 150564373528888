export const DictionaryDataType:any = {
    "01": "text",
    "02": "number",
    "03": "number",
    "04": "date",
    "05": "email"
}

//'[0-9]{5}(-[0-9]{4})?'
//^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$
export const DictionaryValidationType:any = {
    "01": { pattern: '^[0-9]{5}[0-9]+$', message: 'Ingrese un DNI válido'},
    "02": { pattern: '[A-Z][0-9][A-Z] [0-9][A-Z][0-9]', message: 'Ingrese un RUC válido'},
    "03": { pattern: '[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}', message: 'Ingrese un correo válido'}
}

export const DictionaryValidation:any = {
    "number": /^[0-9]*$/,
    "minNumber": 0
}