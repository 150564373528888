import { EntityGenericMetadata } from "../../../../shared/Domain/EntityGenericMetadata";
import { EntityData } from "./EntityData";

export interface EntityListOption {
    dataTable: EntityData[];
    metadata: EntityGenericMetadata[];
    total: number;
    currentPage: number;
}

export const EntityListOptionInit: EntityListOption = {
    dataTable: [],
    metadata: [],
    total: 0,
    currentPage: 1,
}