import { useState } from "react";
import { useMetamask } from "@thirdweb-dev/react";
import { useAddress, useContract } from "@thirdweb-dev/react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { EntityData } from "../Domain/EntityData";
import axios from "axios";
import { AdapterGeneric } from "../../../../../shared/Infraestructure/AdapterGeneric";


export const Controller = () => {

    const connectWallet = useMetamask();
    const address = useAddress();
    const navigate = useNavigate();
    const [list, setList] = useState<EntityData[]>([]);
    const [hasClaimedNFT, setHasClaimedNFT] = useState(false);
    const editionDrop = useContract("0x4Cf16116fbf19e25cC27796cE4E7C3B7e9c7d80b", "edition-drop").contract;

    const checkBalance = async () => {
        if (!address) return;
        try {
          const balance = await editionDrop?.balanceOf(address, 0);
          if (!balance) return;
          balance.gt(0) ? setHasClaimedNFT(true) : setHasClaimedNFT(false);
        } catch (error) {
          setHasClaimedNFT(false);
          message.error("No se pudo obtener el saldo de NFT");
        }
    };

    const reloadList = async () => {
      try {
          const response: null | EntityData[] = (await axios.get(`${process.env.REACT_APP_SERVICE_VOTES}/api/vote/active`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_VOTES}` } })).data;
          if (response) {
            let tempate = response.map(row => ({...row, publish_date: `${AdapterGeneric.calculateDays(AdapterGeneric.convertDateToString(AdapterGeneric.sumDays(row.voting_period, row.publish_date)))}`}))
            setList((prev) => tempate)
          }
      } catch (err) {}
    }

    const redirectDetail = (payload: EntityData) => navigate(payload.id);
  
    return ({
        connectWallet,
        checkBalance,
        redirectDetail,
        reloadList,
        hasClaimedNFT,
        editionDrop,
        address,
        list
    })
}