import { EntityModalDetail } from '../Domain/EntityModalDetail';
import { Modal, Table } from 'antd';
import './ViewModal.scss';

interface ContainerProps {
    modalDetail: EntityModalDetail;
    loadingDetailModal: boolean;
    closeModal(): void;
}

export const ViewModalDetail = (props: ContainerProps) => {
    let cont = 0;
    const sizeX = props.modalDetail.metadata.length * 125;
    const style = () => {
        cont += 1;
        return cont % 2 === 0 ? "ant-table-row-1" : "ant-table-row-2";
    }

    return (
        <Modal
            title={props.modalDetail.title}
			visible={props.modalDetail.show}
			onCancel={props.closeModal}
            footer={null}
            width={800}
            destroyOnClose
            className='ViewModalConfiguracion ff-Mont-Family'
        >
            <>
                <div className='form__Modal CostumeList'>
                    <Table
                        size='small'
                        dataSource={props.modalDetail.payload}
                        loading={props.loadingDetailModal}
                        columns={props.modalDetail.metadata}
                        scroll={{x:`${sizeX}px`}}
                        rowClassName={(record, index) => style()}
                        bordered={false}
                        rowSelection={undefined}
                    />
                </div>
            </>
        </Modal>
    )
}