import { EntityDataGeneral } from "../../Domain/EntityUtils";

interface ContainerProps {
    keys: string[]
    complete: any;
    dataGeneral: EntityDataGeneral;
    url_traza: string;
}

const keysListToShow = [
    // 'descripcion',
    'certificado_por_sdf',
]

const customLabel: any = {
    // 'descripcion': 'Número de Partida',
    'certificado_por_sdf': 'Certificado por SDF',
    "TITULO PROMEDIO": "Título promedio",
    "RESISTENCIA A LA ROTURA": "Resistencia a la rotura",
    "ALARGAMIENTO A LA ROTURA": "Alargamiento a la rotura",
    "PESO POR METRO PROMEDIO": "Peso por metro promedio",
    "HUMEDAD PROMEDIO": "Humedad promedio",
    "ENSIMAJE PROMEDIO": "Ensimaje promedio",
    "ENCOGIMIENTO": "Encogimiento",
    "LONGITUD PROMEDIO": "Longitud promedio",
    "RESISTENCIA METRICA": "Resistencia métrica",
    "IRREGULARIDAD PROMEDIO": "Irregularidad promedio",
    "FIBRAS MENORES A 20 mm": "Fibras menores a 20 mn",
    "INDICE TOTAL SF PARA TEÑIDO": "Índice total SF para teñido"
}

export const ViewHistory2 = (props: ContainerProps) => {

    return (
        <div className='ViewHistory2'>
            <header>
                <div className='GroupPage'>
                    <label className='fnt-bold'>2</label>
                </div>
                <div className='GroupBanner'>
                    <img src="/assets/catalogo/banner-2.png" alt='Not Source'/>
                </div>
            </header>
            <div className='MainContent'>
                <div className='content'>
                    <div className='GroupTitle'>
                        <p className='fnt-regular'>
                            PREPARACIÓN DE LA
                            <span className='fnt-bold' style={{ textTransform: 'uppercase' }}>FIBRA DYTREX</span>
                        </p>
                        <p className='fnt-regular'>
                            Garantizamos la calidad de nuestra fibra con
                            los procesos adecuados que mantienen los
                            siguientes valores técnicos:
                        </p>
                    </div>
                    <div className='GroupInputs'>
                        {
                            keysListToShow.map(row => {
                                let data: { value: string; label: string; } | null = props.complete[row];
                                if (!data) return data;

                                if (row === 'certificado_por_sdf') {
                                    if (!data.value.includes('{')) return null;

                                    if (Array.isArray(JSON.parse(data.value))) {
                                        return JSON.parse(data.value).map((_row: any) => {
                                            return Object.entries(_row).map(([label, value]: any) => 
                                                <div className='GroupInput'>
                                                    <label className='fnt-bold'>{customLabel[label]}</label>
                                                    <div><span className="fnt-regular">{value}</span></div>
                                                </div>
                                            )
                                        })
                                    } else {
                                        if (data.value.includes('{')) {
                                            return Object.entries(JSON.parse(data.value)).map(([label, value]: any) => 
                                                <div className='GroupInput'>
                                                    <label className='fnt-bold'>{customLabel[label]}</label>
                                                    <div><span className="fnt-regular">{value}</span></div>
                                                </div>
                                            )
                                        }
                                    }
                                }

                                return (
                                    <div className='GroupInput'>
                                        <label className='fnt-bold'>{customLabel[row]}</label>
                                        <div><span className="fnt-regular">{data.value}</span></div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <a className='AccederBlockchain fnt-bold' href={props.url_traza} rel="noreferrer" target='_blank'>Acceder al Blockchain</a>
                </div>
            </div>
        </div>
    )
}