import React, { useEffect, useState } from 'react';
import OptionTemplate from '../../../components/optionTemplate';
import { Input, Col, Row, message, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined  } from '@ant-design/icons';
import API_SERVICE from "../../../services/api";
import { AdapterLocalStorage } from '../../../shared/Infraestructure/AdapterLocalStorage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

const ChangePassword = () => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [email, setEmail] = useState('');

    const formGeneric = useFormik({
        initialValues: { email, actualPassword: '', nuevoPassword: '', nuevoPassword2: '' },
        onSubmit: () => {},
        validationSchema: Yup.object({
            email: Yup.string().email().required('El campo CORREO ELECTRÓNICO es requerido'),
            actualPassword: Yup.string().required('El campo CONTRASEÑA ACTUAL es requerido'),
            nuevoPassword: Yup.string().required('El campo NUEVA CONTRASEÑA es requerido'),
            nuevoPassword2: Yup.string().oneOf([Yup.ref('nuevoPassword'), null], 'La contraseña deben coincidir'),
        }),
        enableReinitialize: true
    });

    useEffect(() => {
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = () => {
        let { user } = AdapterLocalStorage.get('user');
        if (!user) return;
        user = JSON.parse(user);
        setEmail(user.email);
    }

    const onChange = (key: string, value: any) => formGeneric.setFieldValue(key, value);
    
    const onSubmit = async () => {
        try { await formGeneric.submitForm(); } catch (error) { }
		try { AdapterValidator.validate(await formGeneric.validateForm(), 'default') } catch (error) { message.error((error as Error).message); return; }
		setLoadingForm(true);

		try {
			await API_SERVICE.Cuenta_Cambia_Password(formGeneric.values)
            message.success('Contraseña cambiada');
			formGeneric.resetForm();
		} catch (err) {
			const msg = (err as any).response.data.responseException.exceptionMessage;
            return message.error(msg);
		} finally {
			setLoadingForm(false)
		}
    }

    return (
        <OptionTemplate
            title="Cambiar contraseña"
            height='350px'
        >
            <>
                <div className="change-password ff-Mont-Family">
                    <div className="cp-inf">
                        Para cambiar tu contraseña, por favor, completa los siguientes datos.
                    </div>
                    <div className="cp-form">
                        <div>
                            <Row gutter={[8,16]}>
                                <Col span={24}>
                                    <label>&nbsp;CORREO ELECTRÓNICO:</label>
                                    <Input disabled value={ formGeneric.values.email }/>
                                </Col>
                            </Row>
                            <Row gutter={[8,16]}>
                                <Col span={24}>
                                    <label>&nbsp;CONTRASEÑA ACTUAL:</label>
                                    <Input.Password
                                        type='password'
                                        value={ formGeneric.values.actualPassword } 
                                        onChange={ (e:any) =>  onChange('actualPassword', e.target.value)}
                                        iconRender = {(visible:any) => visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[8,16]}>
                                <Col span={24}>
                                    <label>&nbsp;NUEVA CONTRASEÑA:</label>
                                    <Input.Password
                                        type='password'
                                        value={ formGeneric.values.nuevoPassword } 
                                        onChange={ (e:any) =>  onChange('nuevoPassword', e.target.value)}
                                        iconRender = {visible => visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[8,16]}>
                                <Col span={24}>
                                    <label>&nbsp;REPITE LA NUEVA CONTRASEÑA :</label>
                                    <Input.Password
                                        type='password'
                                        value={ formGeneric.values.nuevoPassword2 }
                                        onChange={ (e:any) =>  onChange('nuevoPassword2', e.target.value)}
                                        iconRender = {visible => visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                    />
                                    { formGeneric.touched.nuevoPassword2 && formGeneric.errors.nuevoPassword2 && <p>{ formGeneric.errors.nuevoPassword2 }</p> }
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="button-save ff-Mont-Family" style={{borderRadius: '0 0 10px 10px'}}>
                        <Button loading={loadingForm} onClick={onSubmit}> Guardar </Button>
                    </div>
                </div>
            </>
        </OptionTemplate>
    )
}


export default ChangePassword;