import React from "react";
import logoEmpresa from './../../assets/images/logo/logo-empresa-color.svg';
import magicChain from './../../assets/images/logo/logo-MagicChain-color.svg';
import { MoreOutlined, UserOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Popover } from 'antd';
import './../../assets/styles/dashboard.scss';
import { useNavigate } from "react-router-dom";
import { AdapterLocalStorage } from "../../shared/Infraestructure/AdapterLocalStorage";

const Dashboard = (props: { children: JSX.Element }) => {

    const nombre = JSON.parse(AdapterLocalStorage.get('user').user);
    const firstLetterName =  (nombre.nombre.substr(0,1)).toUpperCase();
    const firstLetterLastName =  (nombre.apellido.substr(0,1)).toUpperCase();
    const NameComplete = `${nombre.nombre} ${nombre.apellido}`;
    const navigate = useNavigate();

    const handleOptions = (value:any) => {

        switch(value){
            case "account": navigate("/mi-cuenta"); break;
            case "changepass": navigate("/cambiar-password"); break;
            case "logout":
                AdapterLocalStorage.remove(["token", "menu", "nombre", "dashboard"]);
				window.location.href = `/login`; 
            break;
            default: break;
        }
    }

    const renderContent = () => {
        return( 
            <div >
                <p onClick={() => handleOptions("account")} style={{cursor:"pointer"}} > <UserOutlined /> Mi cuenta</p>
                <p onClick={() => handleOptions("changepass")} style={{cursor:"pointer"}}><LockOutlined /> Cambiar contraseña</p>
                <p onClick={() => handleOptions("logout")} style={{cursor:"pointer"}}><PoweroffOutlined /> Cerrar sesión</p>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="header">
                <div className="section_left">
                    <img src={logoEmpresa} alt="Logo Empresa"/> 
                </div>
                <div className="section_center">
                    <img src={magicChain} alt="Logo MagicChain"/> 
                </div>
                <div className="section_rigth">
                    <div className="datos">
                        <div>
                            <label>{ NameComplete }</label>
                            <Avatar>{ `${firstLetterName}${firstLetterLastName}` }</Avatar>
                        </div>
                    </div>
                    <div className="options">
                        <Popover placement="bottomRight" content={renderContent} trigger="click">
                            <MoreOutlined />
                        </Popover>
                    </div>
                </div>
            </div>
            <div className="menu">
                <div>
                    <label>Sistema de Trazabilidad</label>
                </div>
                <div>
                    <Breadcrumb separator=">" className="breadcrumb">
                        <Breadcrumb.Item href="/tablero-control">Dashboard</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            { props.children }
        </div>
    )
}

export default Dashboard;