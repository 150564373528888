/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './style.scss';
import { ViewHome } from '../../List/Components/ViewHome';
import { EntityData } from '../../List/Domain/EntityData';
import { AdapterGeneric } from '../../../../../shared/Infraestructure/AdapterGeneric';
import { FooterLandingPage } from '../../../../../shared/components/FooterLandingPage';

interface ContainerProps {
    address: string | undefined;
    isVoting: boolean;
    onSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void>;
    onChangeVote: React.Dispatch<React.SetStateAction<string>>;
    vote: string;
    hasVoted: boolean;
    redirectDetail: (payload: EntityData) => void;
    proposals: EntityData;
    list: EntityData[];
}

const ViewPropuesta = (props: ContainerProps) => {
    const { options: { absteined, against }, options } = props.proposals;
    const sumVotes = absteined + against + options.for;
    let maxNumber = (absteined > against) ? ((absteined > options.for) ? absteined : options.for) : ((against > options.for) ? against : options.for);

    return (
        <div className='ViewPropuestaDetail'>

            <div className='ContainerVote'>
                {
                    props.hasVoted ? 
                    <div className='ContentVote'>
                        <div className='GroupInfoVoted'>
                            <h1>¡GRACIAS POR PARTICIPAR!</h1>
                            <p>Tu opinión es muy importante en este camino <br/> de sostenibilidad y compromiso por el Perú</p>
                        </div>
                        {/* <div className='GroupPercent'>
                            <ElementProgressBar percent={AdapterGeneric.calculatePercentage(options.for, sumVotes)} title='A favor' fontSizePercent={maxNumber === options.for ? 50 : 30 } />
                            <ElementProgressBar percent={AdapterGeneric.calculatePercentage(against, sumVotes)} title='En contra' fontSizePercent={maxNumber === against ? 50 : 30} />
                            <ElementProgressBar percent={AdapterGeneric.calculatePercentage(absteined, sumVotes)} title='No opina' fontSizePercent={maxNumber === absteined ? 50 : 30} />
                        </div> */ }
                    </div>
                    :
                    <div className='ContentVote'>
                        <div className='GroupInfo'>
                            <h1>{props.proposals.vote_name}</h1>
                            <p>{props.proposals.proposal_description}</p>
                            <p style={{ marginTop: 25 }}>Termina en {props.proposals.publish_date} días</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th align='left' style={maxNumber === options.for ? { fontFamily: 'Mont-Bold' } : {}}>A favor</th>
                                        <th align='right' style={maxNumber === options.for ? { fontFamily: 'Mont-Bold' } : {}}>{AdapterGeneric.calculatePercentage(options.for, sumVotes)}%</th>
                                    </tr>
                                    <tr>
                                        <th align='left' style={maxNumber === against ? { fontFamily: 'Mont-Bold' } : {}}>En contra</th>
                                        <th align='right' style={maxNumber === against ? { fontFamily: 'Mont-Bold' } : {}}>{AdapterGeneric.calculatePercentage(against, sumVotes)}%</th>
                                    </tr>
                                    <tr>
                                        <th align='left' style={maxNumber === absteined ? { fontFamily: 'Mont-Bold' } : {}}>No opina</th>
                                        <th align='right' style={maxNumber === absteined ? { fontFamily: 'Mont-Bold' } : {}}>{AdapterGeneric.calculatePercentage(absteined, sumVotes)}%</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='GroupQuestions'>
                            <button className={`btnOption ${props.vote === 'for' ? 'btnChecked' : ''}`} onClick={() => props.onChangeVote('for')}>A favor</button>
                            <button className={`btnOption ${props.vote === 'against' ? 'btnChecked' : ''}`} onClick={() => props.onChangeVote('against')}>En contra</button>
                            <button className={`btnOption ${props.vote === 'abstain' ? 'btnChecked' : ''}`} onClick={() => props.onChangeVote('abstain')}>No opina</button>
                            <button className='btnVote' disabled={props.hasVoted} onClick={props.onSubmit}>VOTAR</button>
                        </div>
                    </div>
                }
            </div>
            <div className='ContainerTemplate1'>
                <ViewHome list={props.list} redirectDetail={props.redirectDetail} title={<> AYÚDANOS EN LAS NUEVAS <br/> PROPUESTAS QUE TENEMOS PARA TI</>}/>
            </div>
            <FooterLandingPage />
        </div>
    )
}

export default ViewPropuesta;