import React from 'react';
import { EntityData } from '../Domain/EntityData';

interface ContainerProps {
    payload: EntityData;
}

export const ViewContentResultModal = (props: ContainerProps) => {
    const { options: { absteined, against }, options } = props.payload;
    const maxNumber = (absteined > against) ? ((absteined > options.for) ? absteined : options.for) : ((against > options.for) ? against : options.for);

    return (
        <>
            <div className='form__Modal ViewContentPreviewModal'>
                <h1 className='fw-MontBold' style={{ lineHeight: 'normal', marginBottom: 12 }}>{props.payload.vote_name}</h1>
                <p>{props.payload.proposal_description}</p>

                <div style={{ margin: '20px 0', border: '2px solid #ebebeb' }} />
                <div className='group-quest'>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: 120 }}></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={maxNumber === options.for ? { fontFamily: 'Mont-Bold' } : {}} align='left'>A favor</th>
                            <th style={maxNumber === options.for ? { fontFamily: 'Mont-Bold' } : {}}>{props.payload.options.for}%</th>
                        </tr>
                        <tr>
                            <th style={maxNumber === against ? { fontFamily: 'Mont-Bold' } : {}} align='left'>En contra</th>
                            <th style={maxNumber === against ? { fontFamily: 'Mont-Bold' } : {}}>{props.payload.options.against}%</th>
                        </tr>
                        <tr>
                            <th style={maxNumber === absteined ? { fontFamily: 'Mont-Bold' } : {}} align='left'>No opina</th>
                            <th style={maxNumber === absteined ? { fontFamily: 'Mont-Bold' } : {}}>{props.payload.options.absteined}%</th>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </>
    )
}