import React from 'react';
import * as TControl from './inputs'

interface IProps {
    etiqueta: string;
    identificador: string;
    onChange: Function;
    tipoDato: string;
    value: any;
    tipoControl: string;
    obligatorio: boolean;
    textoMaxLength?: number | null;
    readOnly: boolean;
    tipoValidacion: any; 
    loadingBtnModal: boolean;
    options?: any;
    multipleSelect?: boolean
}

const TipoControl = (props:IProps) => {
    switch(props.tipoControl){
        case "01": return <TControl.CTextfield {...props}/>;
        case "02": return <TControl.CTextArea {...props}/>;
        case "03": return <TControl.CDropdownlist {...props}/>;
        case "04": return <TControl.CCheckBox {...props}/>;
        case "05": return <TControl.CRadioButton {...props}/>;
        case "06": return <TControl.CDate {...props}/>;
        case "07": return <TControl.CDateTime {...props}/>;
        case "08": return <TControl.CCompare {...props}/>;
        case "09": return <TControl.CUpload {...props}/>;
        case "10": return <TControl.CUbigeo {...props}/>;
        case "11": return <TControl.CConcatenation {...props}/>;
        case "12": return <TControl.CCalculated {...props}/>;
        case "13": return <TControl.CMultipleSelect {...props}/>;
        default: return <TControl.CTextfield {...props}/>;
    }
}

export default TipoControl;