import { EntityData } from "./EntityData";

export interface EntityModalOptions {
    title: string;
    key: 'preview' | 'create' | 'edit' | 'result' | '';
    loading: boolean;
    show: boolean;
    dataComplete?: EntityData;
}

export const EntityModalOptionsInit: EntityModalOptions = {
    title: "",
    key: "",
    loading: false,
    show: false,
    dataComplete: {
        box_address: '',
        vote_name: '',
        creation_date: '',
        creator_id: '',
        errors: 0,
        governor_address: '',
        id: '',
        options: {
            for: 0,
            against: 0,
            absteined: 0
        },
        proposal_description: '',
        proposal_id: '',
        quorum_percent: 0,
        token_address: '',
        token_name: '',
        token_symbol: '',
        updated_date: '',
        voting_period: 0,
        publish_date: '',
        already_voters: null,
        isPublished: false
    }
}