import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../components/Dashboard';
import Button from '../../components/Dashboard/Button';
import { AdapterLocalStorage } from '../../shared/Infraestructure/AdapterLocalStorage';
import { useNavigate } from 'react-router-dom';

const ViewDashboard = () => {
	const [dashboardOption, setDashboardOption] = useState<any[]>([])
	const navigate = useNavigate();

	useEffect(() => {
		const response = JSON.parse(AdapterLocalStorage.get('dashboard').dashboard);
		setDashboardOption(response);
	}, []);

	const handleNavigate = (route?: string) => {
        if(route !== undefined && route !== null) navigate('/' + route)
    }

	return (
		<DashboardTemplate>
			<div className="list_button">
				<div className="title">
					<h1>¿Qué deseas ver hoy?</h1>
				</div>
				<div className="button">
					{
						dashboardOption.map((item:any, index:any) => 
							<Button 
								key={ index }
								title={item.title}
								url={item.routing}
								Action={handleNavigate}
								icon={item.icon.classname}
							/>
						)
					}
				</div>
			</div>
		</DashboardTemplate>
	)
}

export default ViewDashboard;