import * as React from 'react'
import ToggleMenuWrapper from '../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import * as Actions from '../../../redux/actions';
import { List } from '../../../components/lists/list';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DatePicker, Breadcrumb, Input, Modal, Row, Col,message, Button } from 'antd';
import { EyeOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { TITLE__COMERCIO } from './../../../global/title-modules';
import moment from 'moment'; 
const { TextArea } = Input;

class Commercialization extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			Cadena_Valor:{
				List:[]
			},
			search: {
				//Grupo:'Joyas'
			},
			Lote: {
				List:[]
			},
			LoteModal: {
				List:[]
			},
			List_Comercio:[],
			visible:false,
			onlyOne:{
				idCadenaValorLote: 1
			},
			editDedicatoria: true,
			total:0,
			loadingBtn2: false,
			loadingBtn1: false,
			current: 1
		};
	}

	componentDidMount(){
		const payload = {
			NumeroPagina: 1,
			TamanioPagina: 10
		}
	
		this.onSearch(payload);
	}

	onChange = (payload:any, Modal?:boolean) => {
		if(Modal){
			this.setState((state:any) => ({
				...state, onlyOne:{
					...state.onlyOne,
					...payload
				}
			}))
			return;
		}
        this.setState((state:any) => ({
            ...state, search:{
                ...state.search,
                ...payload
            }
		}))
	}

	getCadenaValor = (Id:any, Modal?:boolean) => {
		const { Cadena_Valor } = this.state;
		const IdCadenaValor = (Cadena_Valor.List.find((item:any) => item.Id === Id)).Id;

		if(Modal){
			this.onChange({IdCadenaValor}, true);
			this.onChange({IdCadenaValor: ''});
		}
		else {
			this.onChange({IdCadenaValor})
			this.onChange({IdCadenaValor: ''}, true)
		}

		//limpiamos el cbo de la lista lote
		this.onChange({IdCadenaValorLote:''})
		this.onChange({IdCadenaValorLote:''}, true)

		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Lista_Lote_Call(IdCadenaValor, resolve, reject);
		})
		.then(() => {
			if(Modal) this.setState({LoteModal: this.props.List_Lote});
			else this.setState({Lote: this.props.List_Lote});
		})
		.catch(() => {

		})
	}

	onSearch = (search:any, reloadPagination?:any) => {
		this.setState({loadingBtn1: true})
		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Lista_Call(search, resolve, reject)
		})
		.then(() => {
			const { List : { total, items } } = this.props.List_table;
			this.setState({
				total: total,
				List_Comercio: items,
				loadingBtn1: false,
				current: reloadPagination ? 1 : search.current
			})
		})
		.catch(() => {
			this.setState({loadingBtn1: false})
		})
	}

	onDownloadExcel = (data:any) => {

		const validate = this.validationOnDownloadExcel();
		if(validate) return;

		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Descarga_Excel_Call(data, resolve, reject)
		})
		.then(() => {
			const { Excel } = this.props;

			var date = new Date();
			var Fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
			var Tiempo = date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

			var a = document.createElement("a");
            a.href = `${Excel}`;
            a.download = `${TITLE__COMERCIO} ${Fecha} ${Tiempo}`;
            a.click();
		})
		.catch((err) => {
			if(Array.isArray(err)) 
				err.map((item:any) => message.error(item));
			else
				message.error(err);
		})
	}

	validationOnDownloadExcel = () => {
		const { List_Comercio } = this.state;
		if(List_Comercio.length === 0){ 
			message.error('La búsqueda no arrojó resultados');
			return true
		}
		else 
			return false;
	}

	/*---------------------------------------------------------------*/

	onRestore = () => {
		this.setState({loadingBtn2: true})
		const payload = {
			NumeroPagina: 1,
			TamanioPagina: 10
		}

		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Lista_Call(payload, resolve, reject)
		})
		.then(() => {
			this.setState({
				total: this.props.List_table.List.total,
				List_Comercio: this.props.List_table.List.items ,
				loadingBtn2: false,
				search:{},
				current: 1
			})
		})
		.catch(() => {
			this.setState({ loadingBtn2: false })
		})
	}

	getDateFormat = (date:any): any => {
		var d = moment(date);
		return date && d.isValid() ? d : null;
	};

	/*-----------Modal------------ */

	HandleOpenModal = (val?:any) => {
		if(val) this.setState({onlyOne:val})
		else this.setState({edit:false})

		this.setState({visible:true})
	}

	handleCloseModal  = () => {
		this.setState({visible:false, onlyOne:{dedicatoria:'',idCadenaValorLote: 1}, editDedicatoria: true})
	}

	renderModal = () => {
		const { edit } = this.state;
		return(
			<Modal
				title={ this.renderHeaderModal("Detalle dedicatoria") }
				visible={this.state.visible}
				onCancel={ () => this.handleCloseModal() }
				footer={null}
				width={'450px'}
			>
			  { this.renderContentModal() }
			  <div className='buttons'>
				  <div className='cancel'>
				  	<button onClick={ () => this.handleCloseModal()}>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<button onClick = { () => this.functionsCRUD(edit ? 3 : 1)}>Guardar</button>
				  </div>
			  </div>
		  </Modal>
		)
	}

	renderHeaderModal = (title:string) => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item > <a style={{fontSize:'20px'}}>{ title }</a> </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}

	renderContentModal = () => {
		const { onlyOne, editDedicatoria } = this.state;
		const urlDinamic = window.location.origin;

		return(
			<div className='form__Modal'>
				<Row gutter={[6,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;PIN: </label>
						<Input value={onlyOne.CodePIN} disabled/>
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;CÓDIGO SKU: </label>
						<Input value={onlyOne.CodeSKU} disabled/>
					</Col>
				</Row>
				<Row gutter={[6,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;ID CONFECCIÓN: </label>
						<Input value={onlyOne.IdProceso} disabled/>
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;ID VENTA: </label>
						<Input value={onlyOne.IdProcesoVenta} disabled/>
					</Col>
				</Row>
				<Row gutter={[6,16]}>
					<Col span={24} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;FECHA DE GENERACIÓN: </label> <br/>
						<DatePicker 
							value={moment(onlyOne.FechaRegistro) as any} 
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={[6,16]}>
					<Col span={24} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;DEDICATORIA: </label> <button style={{marginLeft: '59%'}} className="save" onClick={() => this.setState({editDedicatoria: !editDedicatoria})}> <EditOutlined/> </button>
						<TextArea 
							value={onlyOne.Dedicatoria} 
							onChange={(e:any) => this.onChange({Dedicatoria: e.target.value}, true)}
							disabled={editDedicatoria}
							autoSize={{ minRows: 4, maxRows: 4 }}/>
					</Col>
				</Row>
				<Row gutter={[6,16]}>
					<Col span={24} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;CATÁLOGO: </label>
						<br/>
						<a href={`${urlDinamic}/historia/?hash=${onlyOne.CodeUnique}`} target='_blank'>
							{`${urlDinamic}/historia?hash=${onlyOne.CodeUnique}`}
						</a>
					</Col>
				</Row>
			</div>
		)
	}

	/*------------------------------ */

	functionsCRUD = (type:number, Id?:any) => {
		switch(type){
			case 1: this.HandleCreate();break;
			case 2: this.HandleSelecciona(Id);break;
			case 3: this.HandleUpdate();break;
			case 4: this.HandleDelete(Id);break;
		}
	}

	HandleCreate = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Agrega_Call({data: this.state.onlyOne, lista: {Grupo:'Joyas'} }, resolve, reject)
		})
		.then((res) => {
			this.setState({
				List_Comercio: this.props.List_table.List.items 
			})
			message.success('Exitoso');
			this.handleCloseModal();

		})
		.catch((err) => {
			message.error('ERROR');
		})
	}

	HandleSelecciona = (Id:any) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Selecciona_Call(Id, resolve, reject)
		})
		.then(() => {
			this.setState({edit:true})
			this.HandleOpenModal(this.props.onlyOne);
		})
		.catch((err:any) => {
		})
	}

	HandleUpdate = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}

		const { onlyOne } = this.state;
		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Actualiza_Call({data: onlyOne, lista: {Grupo:'Joyas'}}, resolve, reject)
		})
		.then(() => {
			this.setState({
				List_Comercio: this.props.List_table.List.items 
			})
			message.success('success');
			this.handleCloseModal();
		})
		.catch((err) => {
			if(Array.isArray(err)) 
				err.map((item:any) => message.error(item));
			else
				message.error(err);
		})
	}

	HandleDelete = (Id:number) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AComercio_Elimina_Call({data: Id, lista: {Grupo:'Joyas'}}, resolve, reject);
		})
		.then(() => {
			this.setState({
				List_Comercio: this.props.List_table.List.items 
			})
			message.success('success');
		})
		.catch((err:any) => {
			console.log(err);
		})
	}

	/*------------------------------ */

	handleValidation = () => {
		const { onlyOne } = this.state;

		const listTemp = [];
		//if(onlyOne.IdCadenaValorLote == null || onlyOne.IdCadenaValorLote == undefined || onlyOne.IdCadenaValorLote == "" ) listTemp.push("LOTE");
		//if(onlyOne.codeProducto == null || onlyOne.codeProducto == undefined || onlyOne.codeProducto == "" ) listTemp.push("CODIGO PRODUCTO");
		//if(onlyOne.codeCliente == null || onlyOne.codeCliente == undefined || onlyOne.codeCliente == "" ) listTemp.push("CODIGO CLIENTE");
		if(onlyOne.Dedicatoria == null || onlyOne.Dedicatoria == undefined || onlyOne.Dedicatoria == "" ) listTemp.push("DEDICATORIA");

		return listTemp;
	}
	
	buttons = (val:any, type:number) => {
		const urlDinamic = window.location.origin;
		if(type === 1)
			return (
				val.Dedicatoria ? 
					<div>
						<button onClick={ () => this.functionsCRUD(2, val.Id) }><EditOutlined /></button>
					</div>
				:
				null
			)
		else 
			return (
				<div>
					<a style={{color:'transparent'}} href={`${urlDinamic}/historia/?hash=${val.CodeUnique}`} target="_blank"> 
						<button style={{ backgroundColor: '#373536'}} ><EyeOutlined /></button> 
					</a>
				</div>
			)
	}

	onPagination = (Pagination:any) => {
		const { search } = this.state;
		const payload = {
			...search,
			NumeroPagina: Pagination.current,
			TamanioPagina: Pagination.pageSize
		}

		this.onSearch(payload);
	}

	/*------------------------------ */
	render() {
		const { search: {CodePIN, FechaIncicial, FechaFinal }, List_Comercio, total, search, loadingBtn2, loadingBtn1, current } = this.state;
		return (
			<ToggleMenuWrapper>
				
				<div className="content">
				<div className="header_content">
                        <div className="title">
                            <Breadcrumb separator="|">
									<Breadcrumb.Item> {TITLE__COMERCIO} </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
					</div>
					<div className="content_list">
						<div className="search" style={{borderRadius:'10px 10px 0px 0px'}}>
							<div className="inputs">
                                <p>CÓDIGO DE PIN: </p>
                                <Input onChange={(e:any) => this.onChange({CodePIN: e.target.value}) } value={CodePIN}/>
                            </div>
							<div className="inputs">
								<p>FECHA DE REGISTRO:</p>
								<DatePicker style={{width:'120px'}} value={FechaIncicial} placeholder='Fecha Inicio' onChange={(value) => this.onChange({FechaIncicial: value})} />
								<DatePicker style={{width:'120px'}} value={FechaFinal} placeholder='Fecha Fin' onChange={(value) => this.onChange({FechaFinal: value})} />
							</div>
                            <div className="inputs" style={{marginTop:'55px'}}>
                                <Button loading={loadingBtn1} style={{marginLeft:'100px'}} onClick={() => this.onSearch(search, true)}>Buscar</Button>
								<Button style={{backgroundColor:'#00aeef', marginLeft:'20px'}} onClick={() => this.onDownloadExcel(search)}>Descargar</Button>
                            </div>
							<div className="inputs" style={{marginTop:'55px'}}>
								<Button loading={loadingBtn2} style={{width: '50px'}} onClick={() => this.onRestore()}> <RedoOutlined/> </Button>
							</div>
						</div>
						<div className="list">
							<List 
								datasource={List_Comercio}
								columns={metadata(this.buttons)}
								total={total}
								onPagination={this.onPagination}
								current={current}
							/>
						</div>
					</div>
					{ this.renderModal() }
				</div>
			</ToggleMenuWrapper>
		)
	}
}

const mapStateToProps= (state:any) => ({
	List_Cadena_Valor: state.comercio.cadena_valor.List,
	List_Lote: state.comercio.lote.List,
	List_table: state.comercio.comercio.List,
	onlyOne: state.comercio.comercio.onlyOne,
	Excel: state.comercio.comercio.Excel
})

const mapDispatchToProps = (dispatch:any) => {
	const {  AComercio_Lista_Lote_Call, AComercio_Lista_Call, AComercio_Selecciona_Call, 
		AComercio_Actualiza_Call, AComercio_Elimina_Call, AComercio_Descarga_Excel_Call } = Actions;
    return bindActionCreators({
		AComercio_Lista_Lote_Call,
		AComercio_Lista_Call,
		AComercio_Selecciona_Call,
		AComercio_Actualiza_Call,
		AComercio_Elimina_Call,
		AComercio_Descarga_Excel_Call
    }, dispatch)
}

const metadata = (buttons:any) => ([

	{title: 'PIN', dataIndex: 'CodePIN',key: 'CodePIN'},
	{title: 'Code SKU', dataIndex: 'CodeSKU', key: 'CodeSKU'},
	{title: 'ID Confección', dataIndex: 'IdProceso',key: 'IdProceso'},
	{title: 'ID Venta', dataIndex: 'IdProcesoVenta', key: 'IdProcesoVenta'},
	{title: 'Fecha de Generación', dataIndex: 'FechaRegistro', key: 'FechaRegistro'},
	{title: 'Acciones', dataIndex: '', key: '', render: (text:any, record:any) => buttons(record, 1)},
	{title: 'Historia', dataIndex: '', key: '', render: (text:any, record:any) => buttons(record, 2)}
])

export default connect(mapStateToProps,mapDispatchToProps)(Commercialization);