
export const ViewHistoryHome = () => {
    return (
        <div className='ViewStepHome'>
            <header>
                <img src='/assets/catalogo/banner-home.png' className='BannerHeader' alt='not source'/>
                <img src='/assets/catalogo/banner-home-slogan.png' className='SlongBannerHeader' alt='not source'/>
            </header>
            <div className='MainContent'>
                <div className='content'>
                    <h1 className='title fnt-bold'>GRACIAS POR TU COMPRA</h1>
                    <p className='text-simple fnt-regular'>
                        En Sudamericana de fibras, reconocemos el duro trabajo que realizan
                        los actores de la cadena textil peruana que día a día confeccionan estas
                        prendas para vestir al mundo entero con la fibra textil de mejor calidad
                        y producida en Perú: Drytex.
                    </p>
                    <p className='text-simple fnt-regular'>
                        Por ello ponemos a su disposición la historia de esta prenda con cada
                        proceso respaldado y certificado por la más alta tecnología: <span className='fnt-semibold'>Blockchain</span>
                    </p>
                    <button id='BtnInicio' className='btn-init fnt-semibold'> INICIAR </button>
                </div>
            </div>
        </div>
    )
}