import { Button } from 'antd';
import React from 'react';
import { InputNumber, InputText, InputTextArea } from '../../../../shared/components/ElementInputs';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';

interface ContainerProps {
    formItem: EntityGenericForm;
    onChange(name: string, value: any): void;
    closeModal(): void;
    onSaveFormItem(type: 1 | 2): void;
}

export const ViewContentFormModal = (props: ContainerProps) => {

    return (
        <>
            <div className='form__Modal'>
                <InputText 
                    label='Propuesta'
                    name='propuesta'
                    onChange={props.onChange}
                    value={props.formItem.values['propuesta']}
                    required
                />
                <InputTextArea
                    label='Descripción'
                    name='descripcion'
                    onChange={props.onChange}
                    value={props.formItem.values['descripcion']}
                    required
                />
                <InputNumber
                    label='Número de días para votar'
                    name='diasPublicacion'
                    onChange={props.onChange}
                    value={props.formItem.values['diasPublicacion']}
                    required
                />
                <div style={{ margin: '20px 0', border: '2px solid #ebebeb' }} />
                <p style={{ fontSize: 12 }}><em>Todas las propuestas manejan las opciones de respuesta <strong className='fw-MontBold'>A favor</strong>, <strong className='fw-MontBold'>En contra</strong> y <strong className='fw-MontBold'>No opina</strong>.</em></p>
            </div>

            <div className='content-buttons ff-Mont-Family'>
                <Button className='custom-btn custom-btn-cancel' onClick={props.closeModal}>Cancelar</Button>
                <Button className='custom-btn custom-btn-save' onClick={() => props.onSaveFormItem(1)}>Guardar</Button>
                {/* <Popconfirm
                    title={'Esta acción guardará la propuesta en Blockchain y la hará visible a los usuarios. ¿Continuar?'}
                    okText={'Aceptar'}
                    cancelText={'Cancelar'}
                    onConfirm={() => props.onSaveFormItem(2)}
                >
                    <Button className='custom-btn custom-btn-blockchain'>Publicar en Blockchain</Button>
                </Popconfirm> */}
            </div>
        </>
    )
}