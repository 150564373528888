import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import jwtDecode from 'jwt-decode';
import { AdapterLocalStorage } from "../shared/Infraestructure/AdapterLocalStorage";
import { EntityUser } from "../shared/Domain/EntityUser";

const ValidaRutasConAcceso = () => {
  const location = useLocation();
  let dashboard: { title: string; routing: string; }[] = [];
  let userData: EntityUser = JSON.parse(AdapterLocalStorage.get('user').user)
  dashboard.push({title: 'estatico', routing: 'mi-cuenta'})
  dashboard.push({title: 'estatico', routing: 'cambiar-password'})
  dashboard = [...JSON.parse(AdapterLocalStorage.get('dashboard').dashboard), ...dashboard];

  if (userData.procesos?.some(row => row === "confeccion-de-prenda") || userData.tipo === 2)
    dashboard.push({title: 'estatico', routing: 'procesos/generar-codigos'})

  if (dashboard.some(row => row.routing === 'reporte-trazabilidad'))
    dashboard.push({title: 'dinamico', routing: 'reporte-trazabilidad/:id'})

  if (dashboard.some(row => row.routing === 'stakeholders')) {
    dashboard.push({title: 'estatico', routing: 'tipo-stakeholder'})
    dashboard.push({title: 'estatico', routing: 'usuarios-stakeholder'})
  }

  const { pathname } = location;

  for(let i=0; i < dashboard.length; i++){
    const routing = dashboard[i].routing;
    const title = dashboard[i].title;
    const routingValidate = `/${routing}`;
    if(routingValidate === pathname || title === 'dinamico') return; 
  }
  if(pathname === '/tablero-control') return;

  return window.location.pathname = '/tablero-control'
}

export const JwtVerify = () => {
  const { token } = AdapterLocalStorage.get('token');
  if (!token) return false;

  const payload = JSON.stringify(jwtDecode(token));
  const exp = JSON.parse(payload).exp;
  if (exp < Date.now() / 1000) {
    AdapterLocalStorage.clearAll();
    return false;
  }
  ValidaRutasConAcceso();

  return true;
}

export const PrivateRoute = () => {
  return JwtVerify() ? <Outlet /> : <Navigate to="/login" />;
};