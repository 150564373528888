import { EntityData } from "./EntityData";

export interface EntityModalOptions {
    title: string;
    show: boolean;
    payload?: EntityData;
}

export const EntityModalOptionsInitial: EntityModalOptions = {
    title: "",
    show: false,
    payload: undefined
} 