/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import { ListCollapse } from './../../../components/lists/list'
import * as Actions from '../../../redux/actions'
import { useDispatch } from 'react-redux';
import { Breadcrumb, message } from 'antd';
import { useParams } from 'react-router-dom';


const ViewDetail = () => {
	const [data, setData] = useState<any>({});
	const params = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		onSearch();
	}, [])

	const onSearch = () => {
		new Promise((resolve:any, reject:any) => {
			dispatch(Actions.AReporte_Detalle_Proceso_Call(parseInt(`${params.id || 0}`), resolve, reject));
		})
		.then((res) => {
			const reporte:any = res;
			console.log(reporte);
			setData(() => ({...reporte.detalle, reporte: reporte.lista, id: params.id}))
		})
		.catch((err:any) => {
			if(Array.isArray(err))
				err.map((item:any) => message.error(item));
			else
				message.error(err);
		})
	}
	
	return (
		<ToggleMenuWrapper>
			<div className="content">
				<div className="header_content">
					<div className="title">
						<Breadcrumb separator="|" className='ff-Mont-Family'>
							<Breadcrumb.Item href={`/reporte-trazabilidad`}> Reportes </Breadcrumb.Item>
							<Breadcrumb.Item href={``}> <a> Detalle de trazabilidad </a> </Breadcrumb.Item>
						</Breadcrumb>
						<div>ID {data.id} - {data.etapa} - {data.descripcion}</div>
					</div>
				</div>

				<div className="list__Collapse">
					{
						data.reporte?.map((item:any) => 
							<ListCollapse 
								key={item.id}
								header={item.nombre}
								columns={item.headers || []}
								datasource={item.items || []}
								getData={() => {}}
								edit = {false}
							/>
						)
					}
				</div>
			</div>
		</ToggleMenuWrapper>
	)
}

export default ViewDetail;