export interface EntityTabOptions {
    currentKey: string;
    tabs: {
        key: string;
        label: string;
    }[];
}

export const EntityTabOptionsInitial: EntityTabOptions = {
    currentKey: "",
    tabs: []
}