import React from "react";
import locale from 'antd/lib/locale/es_ES';
import 'react-multi-carousel/lib/styles.css';
import { ConfigProvider } from 'antd';
import "./App.scss";
import Main from "./containers/main";

const App: React.FC = () => {
	return (
		<div className="App">
			<ConfigProvider locale={locale}>
				<Main />
			</ConfigProvider>
		</div>
	);
}

export default App;
