/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DatePicker, Select, Input, Breadcrumb, Modal, Row, Col, message, Tooltip, Form, Button } from 'antd';
import { List } from './../../../components/lists/list';
import * as Actions from './../../../redux/actions';
import { TITLE__STAKEHOLDER } from './../../../global/title-modules';
import { EditOutlined, RedoOutlined } from '@ant-design/icons';
import { DictionaryValidation } from './../../../components/Form/Dictionary';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './list.min.scss';

const { Option } = Select;

class list extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			visible:false,
			onlyOne: {
				estado: 'A',
				ruc:'',
				dnilegal:'',
				numeroTelefono:'',
				numeroCelular:''
			},
			search: {},
			editOrCreate: 1,
			loadingBtn1: false, 
			loadingBtn2: false,
			current: 1,
			loadingBtnModal: false
		};
	}

	componentDidMount(){
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Lista_StakeHolder_Call(null, resolve, reject);
		})
	}

	buttons = (val:any) => {
		//return this.setState({typeFunctions:2})
		return (
			<div>
				<Tooltip placement="left" title={"Editar"} >
					<button onClick={ () =>  this.functionsCRUD(4, val.id) }><EditOutlined /></button>
				</Tooltip>
				{/*<Tooltip placement="left" title={"Eliminar"} >	
					<Popconfirm 
						title={ 
								`Esta acción no se puede
								deshacer, ¿quieres continuar?` 
							} 
						placement="left"
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						onConfirm = { (e:any) => this.functionsCRUD(3, val.id) }
					>
						<button style={{ backgroundColor: '#373536'}}><DeleteOutlined /></button>	
					</Popconfirm>
				</Tooltip>*/}		
			</div>
		)
	}

/*--------------------------------------------------------------------------- */

	HandleSearch = () => {
		const { search } = this.state;
		this.setState({loadingBtn1:true})
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Lista_StakeHolder_Call(search, resolve, reject);
		})
		.then((res) => {
			this.setState({loadingBtn1:false})
		})
		.catch((err) => {
			this.setState({loadingBtn1:false})
		})
	}

/*-----------------------------MODAL----------------------------------------- */
	HandleOpenModal = () => {
		this.setState({visible:true})
	}

	handleCloseModal  = () => {
		this.setState({
			visible:false, 
			onlyOne:{
				estado:'A',
				ruc:'',
				dnilegal:'',
				numeroTelefono:'',
				numeroCelular:''
			}, 
			editOrCreate: 1,
			loadingBtnModal: false
		})
	}

	renderModal = () => {
		const { editOrCreate, loadingBtnModal } = this.state;  
		return(
			<Modal
			title={ this.renderHeaderModal("Stakeholder") }
			visible={this.state.visible}
			onCancel={ () => this.handleCloseModal() }
            footer={null}
            style={{width:'500px'}}
		  >
			  { this.renderContentModal() }
			  <div className='buttons'>
				  <div className='cancel'>
				  	<button onClick={ () => this.handleCloseModal()}>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<Button 
					  onClick = { () => this.functionsCRUD(editOrCreate) }
					  loading={ loadingBtnModal }
					>
						Guardar
					</Button>
				  </div>
			  </div>
		  </Modal>
		)
	}

	renderContentModal = () => {
		const { onlyOne } = this.state;
		return(
			<div className='form__Modal ff-Mont-Family'>
			<Form>
				<Row gutter={[6,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;TIPO: <a>{` *`}</a> </label>
						<br/>
						<Select style={{width:'100%'}} onChange = { (value:any) => this.onChange({name: 'idTipoStakeholder', value }) } value = {onlyOne.idTipoStakeholder}>
							{
								this.props.List_Type.map((item:any, index:number) => 
									<Option value={ item.id } style={{width:'200px'}} key={ item.id }> { item.nombre } </Option>
								) 
							}

						</Select>
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;RAZÓN SOCIAL: <a>{` *`}</a></label>
						<Input onChange = { (e:any) => this.onChange({name: 'razonSocial', value: e.target.value }) } value = {onlyOne.razonSocial} />
					</Col>
				</Row>
				<Row gutter={[8,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;RUC: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'ruc', value: e.target.value}) } value = {onlyOne.ruc} />
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;DESCRIPCIÓN: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'descripcion', value: e.target.value }) } value = {onlyOne.descripcion}	/>
					</Col>
				</Row>
				<Row gutter={[8,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;REPRESENTANTE LEGAL: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'representanteLegal', value: e.target.value }) } value = {onlyOne.representanteLegal} />
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;DNI LEGAL: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'dnilegal', value: e.target.value }) } value = {onlyOne.dnilegal} />
					</Col>
				</Row>
				<Row gutter={[8,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;DOMICILIO LEGAL: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'domicilioLegal', value: e.target.value }) } value = {onlyOne.domicilioLegal} />
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;CORREO: <a>{` *`}</a> </label>
						<Input type="email" onChange = { (e:any) => this.onChange({name: 'correoElectronico', value: e.target.value }) } value = {onlyOne.correoElectronico} />
					</Col>
				</Row>
				<Row gutter={[8,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;NÚMERO TELEFONO: </label>
						<Input onChange = { (e:any) => this.onChange({name: 'numeroTelefono', value: e.target.value}) } value = {onlyOne.numeroTelefono} />
					</Col>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;NÚMERO CELULAR:  </label>
						<Input onChange = { (e:any) => this.onChange({name: 'numeroCelular', value: e.target.value}) } value = {onlyOne.numeroCelular} />
					</Col>
				</Row>
				{/*<Row gutter={[8,16]}>
					<Col span={12} style={{padding:'8px 12px'}}>
						<label>&nbsp;&nbsp;ESTADO:  </label>
						<br/>
						<Select style={{width:'100%'}} value={ onlyOne.estado } onChange={ (value:any) => this.onChange({name: 'estado', value}) }>
							<Option value="A"> Activo </Option>
							<Option value="I"> Inactivo </Option>
						</Select>
					</Col>
				</Row> */}
			</Form>
		</div>
		)
	}

	renderHeaderModal = (title:string) => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item > <a style={{fontSize:'20px'}}>{ title }</a> </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}
/**---------------------------------------------------------------------------------------------- */
	handleValidation = () => {
		const { onlyOne } = this.state;
		const listTemp = [];
		if(onlyOne.idTipoStakeholder === null || onlyOne.idTipoStakeholder === undefined || onlyOne.idTipoStakeholder === "" ) listTemp.push("Tipo Stakeholder");
		if(onlyOne.razonSocial === null || onlyOne.razonSocial === undefined || onlyOne.razonSocial === "" ) listTemp.push("Razón Social");
		if(onlyOne.correoElectronico === null || onlyOne.correoElectronico === undefined || onlyOne.correoElectronico === "" ) listTemp.push("Correo Electrónico");
		return listTemp
	}

	handleSave = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
		this.setState({loadingBtnModal: true});

		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Agrega_StakeHolder_Call(this.state.onlyOne, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false});

			if(err.errors)
				message.error(err.errors.CorreoElectronico[0])
			else
				message.error(err);
		})
	}

	handleUpdate = () => {
		const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
		this.setState({loadingBtnModal: true});

		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Actualiza_Stakeholder_Call(this.state.onlyOne, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			this.setState({loadingBtnModal: false});
			
			if(err.errors)
				message.error(err.errors.CorreoElectronico[0])
			else
				message.error(err);
		})
	}

	handleDelete = (id:any) => { 
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Elimina_Stakeholder_Call(id, resolve, reject)
		})
		.then((res:any) => {
			this.handleCloseModal();
			message.success(res);
		})
		.catch((err) => {
			message.error(err);
		})
	}

	handleSelecciona = (id:any) => {
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Selecciona_StakeHolder_Call(id, resolve, reject)
		})
		.then((res:any) => {
			const { OnlyOne } = this.props;
			this.setState({onlyOne: OnlyOne, visible:true, editOrCreate:2});
		})
		.catch((err) => {
			message.error(err);
		})
	}

	functionsCRUD = (type:number, id?:number) => {
		switch(type){
			case 1: this.handleSave();break;
			case 2: this.handleUpdate();break;
			case 3: this.handleDelete(id);break;
			case 4: this.handleSelecciona(id);break;
			default: return;
		}
	}

	onChange = (payload:any, search?:boolean) => {
		if(search){
			this.setState((state:any) => ({
				...state, search:{
					...state.search, 
					...payload
				}
			}))
			return;
		}

		const {name, value} = payload;
		if(name === "ruc" || name === "dnilegal" || name === "numeroTelefono" || name === "numeroCelular"){
			const validate01 = DictionaryValidation["number"];
			if(!validate01.test(value)) return; 
		} 
		
		this.setState((state:any) => ({
			...state, onlyOne:{
				...state.onlyOne, 
				[name] : value
			}
		}))

	}

	formatDatasoruce = (data:any) => {
		data.map((item:any, index:number) => {
			const name = (this.props.List_Type.find((itemT:any) => itemT.id === item.idTipoStakeholder)) || {nombre:item.id};
			data[index] = {...item, idTipoStakeholder:name.nombre}
			return item;
		})
		return data;
	}

	/*-------------------------------------------------------------------*/

	onRestore = () => {
		this.setState({loadingBtn2: true})
		new Promise((resolve:any, reject:any) => {
			this.props.AStakeholder_Lista_StakeHolder_Call({}, resolve, reject);
		})
		.then((res) => {
			this.setState({loadingBtn2:false, search:{}})
		})
		.catch((err) => {
			this.setState({loadingBtn2:false})
		})
	}

	getDateFormat = (date:any): any => {
		var d = moment(date);
		return date && d.isValid() ? d : null;
	};

	onPagination = (value:any) => {}

	/*-------------------------------------------------------------------*/

	render() {
		const { search: {Tipo, RUC, FechaIncicial, FechaFinal}, loadingBtn1, loadingBtn2 } = this.state;

		return (
			<ToggleMenuWrapper>
				<>
					<div className="content">
						<div className="header_content">
							<div className="title">
								<Breadcrumb separator="|" className='ff-Mont-Family'>
									<Breadcrumb.Item> {TITLE__STAKEHOLDER} </Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div className="button">
								<Link to='/usuarios-stakeholder' className='buttonCustom'>
									Usuarios stakeholder
								</Link>
							</div>
							<div className="button">
								<Link to='/tipo-stakeholder' className='buttonCustom'>
									Tipos de stakeholder
								</Link>
							</div>
							<div className="button">
								<button onClick={() => {this.HandleOpenModal(); return this.setState({typeFunctions:1})}}>
									Crear
								</button>
							</div>
						</div>
						<div className="content_list">
							<div className="search ff-Mont-Family" style={{borderRadius:'10px 10px 0px 0px'}}>
								<div className="inputs">
									<p>FECHA DE REGISTRO:</p>
									<DatePicker placeholder='Fecha Inicio' value={FechaIncicial} onChange={(value) => this.onChange({FechaIncicial : value}, true)} />
									<DatePicker placeholder='Fecha Fin' value={FechaFinal} onChange={(value) => this.onChange({FechaFinal: value}, true)} />
								</div>
								<div className="inputs">
									<p>TIPO: </p>
									<Select style={{width:'180px'}} allowClear onChange={(value:any) => this.onChange({Tipo:value}, true)} value={ Tipo }>

										{
											this.props.List_Type.map((item:any, index:number) => 
												<Option value={ item.id } key={ item.id }> { item.nombre } </Option>
											) 
										}
										
									</Select>
								</div>
								<div className="inputs">
									<p>RUC: </p>
									<Input style={{width: '160px'}} onChange={(e:any) => this.onChange({RUC: e.target.value}, true)} value={ RUC }/>
								</div>
								<div className="inputs" style={{marginTop:'55px'}}>
									<Button loading={loadingBtn1} onClick={() => this.HandleSearch()}>Buscar</Button>
								</div>
								<div className="inputs" style={{marginTop:'55px'}}>
									<Button style={{width:'50px' }} loading={loadingBtn2} onClick={() => this.onRestore()}><RedoOutlined/></Button>
								</div>
							</div>
							<div className="list">
								<List 
									datasource={this.formatDatasoruce(this.props.List)}
									onPagination={this.onPagination}
									columns={metadata(this.buttons)}
								/>
							</div>
						</div>
					</div>
					{  this.renderModal() }
				</>
			</ToggleMenuWrapper>
		)
	}
}

const mapStateToProps= (state:any) => ({
	List: state.stakeholder.stakeholder.Lista,
	List_Type: state.stakeholder.stakeholder_Type.Lista,
	OnlyOne: state.stakeholder.stakeholder.onlyOne
})

const mapDispatchToProps = (dispatch:any) => {
	const { AStakeholder_Lista_StakeHolder_Call, AStakeholder_Agrega_StakeHolder_Call,
			AStakeholder_Actualiza_Stakeholder_Call, AStakeholder_Elimina_Stakeholder_Call,
			AStakeholder_Selecciona_StakeHolder_Call } = Actions;
    return bindActionCreators({
		AStakeholder_Lista_StakeHolder_Call,
		AStakeholder_Agrega_StakeHolder_Call,
		AStakeholder_Actualiza_Stakeholder_Call,
		AStakeholder_Elimina_Stakeholder_Call,
		AStakeholder_Selecciona_StakeHolder_Call
    }, dispatch)
}

const metadata = (buttons:any) => [
 {title: 'ID', dataIndex: 'id',key: 'id', width: '8%'},
 {title: 'Tipo', dataIndex: 'idTipoStakeholder', key: 'idTipoStakeholder'},
 {title: 'Razón Social', dataIndex: 'razonSocial', key: 'razonSocial',},
 {title: 'RUC', dataIndex: 'ruc', key: 'ruc'},
 {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'},
 {title: 'Representante Legal', dataIndex: 'representanteLegal', key: 'representanteLegal'},
 {title: 'DNI Legal', dataIndex: 'dnilegal', key: 'dnilegal'},
 {title: 'Acciones', dataIndex: '', key: '',   width:'150px', render: (text:any, record:any) => buttons(record)}
]

export default connect(mapStateToProps,mapDispatchToProps )(list);