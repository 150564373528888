import { AdapterGeneric } from "../../../../../shared/Infraestructure/AdapterGeneric";
import { EntityDataGeneral } from "../../Domain/EntityUtils";

interface ContainerProps {
    keys: string[]
    complete: any;
    dataGeneral: EntityDataGeneral;
    url_traza: string;
}

const keysListToShowIndustrial = [
    'descripcion',
    'tejido',
    'tipo_de_maquina',
    'codigo_del_tejedor',
    'tipo_de_tejido',
    'lugar',
    'fecha_de_confeccion'
]

const keysListToShowArtesanal = [
    'descripcion',
    'tejido',
    'tipo_de_maquina',
    'nombre_del_tejedor',
    'tipo_de_tejido_punto',
    'lugar',
    'fecha_de_confeccion'
]

const customLabel: any = {
    'descripcion': 'Descripción',
    'tejido': 'Tejido',
    'tipo_de_maquina': 'Tipo de máquina',
    'codigo_del_tejedor': 'Código del tejedor',
    'tipo_de_tejido': 'Tipo de tejido',
    'nombre_del_tejedor': 'Nombre del tejedor',
    'tipo_de_tejido_punto': 'Tipo de tejido / punto',
    'lugar': 'Lugar de confección',
    'fecha_de_confeccion': 'Fecha de confección'
}

export const ViewHistory4 = (props: ContainerProps) => {

    return (
        <div className='ViewHistory4'>
            <header>
                <div className='GroupPage'>
                    <label className='fnt-bold'>4</label>
                </div>
                <div className='GroupBanner'>
                    <img src={props.complete.tejido.value === 'Industrial' ? "/assets/catalogo/banner-41.png" : "/assets/catalogo/banner-42.png"} alt='Not Source'/>
                </div>
            </header>
            <div className='MainContent'>
                <div className='content'>
                    <div className='GroupTitle'>
                        <p className='fnt-regular'>
                            ELABORACIÓN DEL
                            <span className='fnt-bold'>&nbsp;TEJIDO</span>
                        </p>
                        <p className='fnt-regular'>
                            Tejedores industriales y artesanales
                            confeccionan prendas de temporada y en
                            tendencia para el gusto de sus clientes
                        </p>
                    </div>
                    <div className='GroupInputs'>
                        {
                            (props.complete.tejido.value === 'Industrial' ? keysListToShowIndustrial : keysListToShowArtesanal).map(row => {
                                let data: { value: string; label: string; } | null = props.complete[row];
                                let className = '';
                                if (!data) return data;
                                if (row === 'fecha_de_confeccion') data.value = AdapterGeneric.convertDateToString(AdapterGeneric.convertStringToDate(data.value), 3); 
                                if (['codigo_del_tejedor', 'nombre_del_tejedor'].includes(row)) className = 'textNegritaMobile'

                                return (
                                    <div className='GroupInput'>
                                        <label className='fnt-bold'>{customLabel[row]}</label>
                                        <div><span className={`fnt-regular ${className}`}>{data.value}</span></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <a className='AccederBlockchain fnt-bold' href={props.url_traza} rel="noreferrer" target='_blank'>Acceder al Blockchain</a>
                </div>
            </div>
        </div>
    )
}