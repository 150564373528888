import { Modal } from 'antd';
import React from 'react';
import { EntityGenericForm } from '../../../../shared/Domain/EntityGenericForm';
import { EntityModalOptions } from '../Domain/EntityModalOptions';
import { ViewContentFormModal } from './ViewContentFormModal';
import { ViewContentPreviewModal } from './ViewContentPreviewModal';
import { ViewContentResultModal } from './ViewContentResultModal';

interface ContainerProps {
    modalOptions: EntityModalOptions;
    loading: Boolean;
    closeModal(): void;
    formItem: EntityGenericForm;
    onChangeFormItem(name: string, value: any): void;
    onSaveFormItem(type: 1 | 2): void;
}

export const ViewModal = (props: ContainerProps) => {
    console.log(props.loading)
    return (
        <Modal
            title={props.modalOptions.title}
			visible={props.modalOptions.show}
			onCancel={props.closeModal}
            footer={null}
            width={600}
            destroyOnClose
            className='CustomModalDAO'
        >
            <div className={`${props.loading ? 'loading-modal' : 'remove-loading-modal'}`}>
                <p>Cargando...</p>
            </div>
            { ["create", "edit"].includes(props.modalOptions.key) ? <ViewContentFormModal formItem={props.formItem} onChange={props.onChangeFormItem} closeModal={props.closeModal} onSaveFormItem={props.onSaveFormItem} /> : null }
            { ["preview"].includes(props.modalOptions.key) && props.modalOptions.dataComplete ? <ViewContentPreviewModal payload={props.modalOptions.dataComplete} /> : null }
            { ["result"].includes(props.modalOptions.key) && props.modalOptions.dataComplete ? <ViewContentResultModal payload={props.modalOptions.dataComplete} /> : null }
        </Modal>
    )
}