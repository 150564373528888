/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TITLE__STAKEHOLDER } from './../../../global/title-modules';
import { DictionaryValidationType } from './../../../components/Form/Dictionary';
import { Modal, Select, Input, Breadcrumb, Row, message, Tooltip, Col, Form, Checkbox, Button } from 'antd';
import { EditOutlined, RedoOutlined, EyeOutlined } from '@ant-design/icons';
import { List } from './../../../components/lists/list'
 
const { Option } = Select;
const optionsModoIngreso = [
    { label: 'Login', value: 'L' },
    { label: 'QR', value: 'Q' }
];
const optionsEstado = [
    {label:'Activo', value: 'A'}, 
    {label:'Inactivo', value: 'I'}, 
    {label:'Pendiente', value:'P'}
];

class Usuario extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
            loading:false,
            List: [],
            ListStakeholder: [],
            onlyOne:{
                estado: 'A',
                apellidoMaterno: ''
            },
            visible: false,
            IdStakeholder: null,
            editOrCreate: 1,
            visibleOption:false,
            loadingBtnModal: false,
            search: {
            }
		};
    }
    
    componentDidMount(){
        this.onInit();
    }

    onInit = () => {
        this.setState({loadingBtn1: true, loadingBtn2: true});
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Lista_Usuario_Stakeholder_Call({search:false}, resolve, reject)
        })
        .then(() => {
            const { List, ListStakeholder } = this.props;
            this.setState({
                List, 
                ListStakeholder,
                search: {
                },
                loadingBtn1: false, 
                loadingBtn2: false
            });
        })
        .catch((err) => {
            this.setState({loadingBtn1: false, loadingBtn2: false});
            console.log(err);
        })
    }

    /*---------------------Search-------------------------- */

    onRestore = () => {
        this.onInit();
    }

    onSearch = () => {
        const { search } = this.state;
        this.setState({loadingBtn1: true});
        new Promise((resolve, reject) => {
            this.props.ACuenta_Lista_Usuario_Stakeholder_Call({search:true, data:search}, resolve, reject)
        })
        .then(() => {
            const { List } = this.props;
            this.setState({
                List, 
                loadingBtn1: false,
            });
        })
        .catch((err) => {
            this.setState({loadingBtn1: false, loadingBtn2: false});
            console.log(err);
        })
    }

    /*---------------Funciones la Lista--------------------*/

    buttons = (val:any, type:number) => {

        switch(type){
            case 1: return (
                <div>
                    <Tooltip placement="left" title={"Editar"}>
                        <button onClick={ () => this.functionsCRUD(4, val.id) }><EditOutlined /></button>
                    </Tooltip>
                </div>
            );
            case 2: return (
                <div>
                    {
                        val.modoIngreso === "A" || val.modoIngreso === "Q" ?
                            <Tooltip placement="left" title={"QR"}>
                                <button onClick={() => this.functionsCRUD(5, val)}><EyeOutlined /></button>
                            </Tooltip> :
                            null
                    }
                </div>
            );
            default: return(
                <div>
                    <Tooltip placement="left" title={"Editar"}>
                        <button onClick={ () => this.functionsCRUD(4, val.id) }><EditOutlined /></button>
                    </Tooltip>
                </div>
            );
        }
    }
    
    /*----------------Funcion exportar zip-----------------*/

    handleExportZip = () => {
        const { search, List } = this.state;

        if(List && List?.length <= 0) return message.error('No se puede generar un archivo sin data resultante');
        
        new Promise((resolve, reject) => {
            this.props.ACuenta_Descarga_Qr_Zip(search, resolve, reject)
        })
        .then((res:any) => {
            const { fileContents, fileDownloadName, contentType } = res;
            var a = document.createElement("a");
            //a.href = `content-type:${contentType};base64,${fileContents}`;
            a.href = `data:text/plain;base64,${fileContents}`;
            a.download = `${fileDownloadName}`;
            a.click();
            console.log(res);
        })
        .catch((err) => {
            message.error(err)
        })
    }

    /*---------------------- Modal ------------------------*/

    HandleOpenModal = () => {
		this.setState({visible:true, visibleOption:false})
	}

	handleCloseModal  = () => {
		this.setState({
            visible:false, 
            onlyOne:{
                estado: 'A', 
                apellidoMaterno: '',
                modoIngreso: []
            }, 
            editOrCreate: 1, 
            idStakeholder: null,
            loadingBtnModal: false
        })
	}

	renderModal = () => {
        const { visible, loadingBtnModal } = this.state;  

		return(
			<Modal
                title={ this.renderHeaderModal("Usuario") }
                visible={visible}
                onCancel={ () => this.handleCloseModal() }
                footer={null}
                style={{width:'600px'}}
            >
			  { this.renderContentModal() }
			  <div className='buttons'>
				  <div className='cancel'>
				  	<button onClick={ () => this.handleCloseModal()}>Cancelar</button>
				  </div>
				  <div className='save'>
				  	<Button 
                      onClick = { () => this.handleSave() }
                      loading = { loadingBtnModal }
                    >
                        Guardar
                    </Button>
				  </div>
			  </div>
		  </Modal>
		)
	}

	renderContentModal = () => {
		const { onlyOne, ListStakeholder, idStakeholder, visibleOption } = this.state;
		return(
			<div className='form__Modal ff-Mont-Family'>
                <Form>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;STAKEHOLDER: <a>*</a></label> 
                            <br/>
                            <Select 
                                value= {idStakeholder}
                                style={{width:'100%'}}
                                onChange = { (value:any) => this.onChange({idStakeholder : value}, true)}
                                options = {ListStakeholder}
                            />
                        </Col>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;NOMBRE: <a>*</a></label>
                            <Input onChange = { (e:any) => this.onChange({nombre: e.target.value }) } value = {onlyOne.nombre} />
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;APELLIDO PATERNO: <a>*</a></label>
                            <Input onChange = { (e:any) => this.onChange({apellidoPaterno: e.target.value }) } value = {onlyOne.apellidoPaterno} />
                        </Col>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;APELLIDO MATERNO: </label>
                            <Input onChange = { (e:any) => this.onChange({apellidoMaterno: e.target.value }) } value = {onlyOne.apellidoMaterno} />
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={24} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;DNI: <a>*</a></label>
                            <Input onChange = { (e:any) => this.onChange({numeroDocumento: e.target.value }) } value = {onlyOne.numeroDocumento} />
                        </Col>
                        {/*<Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;NODO: </label>
                            <Input onChange = { (e:any) => this.onChange({nodo: e.target.value }) } value = {onlyOne.nodo} />
                        </Col> */}
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={12} style={{padding:'8px 12px'}}> 
                            <label>&nbsp;&nbsp;MODO DE INGRESO: <a>*</a> </label>
                            <Checkbox.Group style={{display: 'flex'}} options={optionsModoIngreso} value={onlyOne.modoIngreso} onChange={(e) => this.onChange({modoIngreso: e})} />
                        </Col>
                        <Col span={12} style={{padding:'8px 12px'}}>
                            <label>&nbsp;&nbsp;CORREO: {onlyOne.modoIngreso?.length === 2 || (onlyOne.modoIngreso?.length === 1 && onlyOne.modoIngreso[0] === 'L') ? <a>*</a> : null}</label>
                            <Input onChange = { (e:any) => this.onChange({email: e.target.value }) } value = {onlyOne.email} type="email"/>
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        {visibleOption ? 
                            <Col span={24} style={{padding:'8px 12px'}}>
                                <label>&nbsp;&nbsp;ESTADO: <a>*</a></label> 
                                <br/>
                                <Select 
                                    value= {onlyOne.estado}
                                    style={{width:'100%'}}
                                    onChange = {(value:any) => this.onChange({estado: value})}
                                    options = {optionsEstado}
                                />
                            </Col>
                            :
                            null
                        }
                    </Row>
                </Form>
            </div>
		)
	}

	renderHeaderModal = (title:string) => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item > <a style={{fontSize:'20px'}}>{ title }</a> </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
    }
    
    handleSave = () => {
        const { editOrCreate } = this.state; 
        const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i = 0; i < validation.length; i++){
				message.error(validation[i]);
			}
			return;
		}
 
        this.functionsCRUD(editOrCreate);
    }

	handleValidation = () => {
		const { onlyOne, idStakeholder } = this.state;
        const validationEmail = DictionaryValidationType["03"];
        const validationDNI = DictionaryValidationType["01"];
		const listTemp = [];
		//if(onlyOne.email === null || onlyOne.email === undefined || onlyOne.email === "" ) listTemp.push("Ingrese un Correo");
		if(onlyOne.nombre === null || onlyOne.nombre === undefined || onlyOne.nombre === "" ) listTemp.push("Ingrese un Nombre");
		if(onlyOne.apellidoPaterno === null || onlyOne.apellidoPaterno === undefined || onlyOne.apellidoPaterno === "" ) listTemp.push("Ingrese un Apellido Paterno");
		//if(onlyOne.apellidoMaterno === null || onlyOne.apellidoMaterno === undefined || onlyOne.apellidoMaterno === "" ) listTemp.push("Ingrese un Apellido Materno");
        if(onlyOne.estado === null || onlyOne.estado === undefined || onlyOne.estado === "" ) listTemp.push("Ingrese un Estado");
        if(idStakeholder === null || idStakeholder === undefined || idStakeholder === "" ) listTemp.push("Ingrese un Stakeholder");

        if(onlyOne.numeroDocumento === null || onlyOne.numeroDocumento === undefined || onlyOne.numeroDocumento === "") listTemp.push("Ingrese un DNI");
        if(onlyOne.numeroDocumento && onlyOne.numeroDocumento.match(validationDNI?.pattern) === null) listTemp.push(validationDNI.message)

        if(!onlyOne.modoIngreso || onlyOne.modoIngreso?.length === 0) listTemp.push("Ingrese un modo de Ingreso");
        if(onlyOne.modoIngreso?.length === 2 || (onlyOne.modoIngreso?.length === 1 && onlyOne.modoIngreso[0] === 'L')) {
            if(onlyOne.email === null || onlyOne.email === undefined || onlyOne.email === "" ||  onlyOne.email.match(validationEmail?.pattern) === null ) listTemp.push("Ingrese un Correo válido");
        }

		return listTemp
    }
    

    /*-----------------------------------------------------*/
    /*-------------------- functions ----------------------*/

    templateModoIngreso = (onlyOne:any) => {
        if(onlyOne.modoIngreso.length === 2){
            onlyOne.modoIngreso = 'A'
        } else if (onlyOne.modoIngreso[0] === 'L' ) {
            onlyOne.modoIngreso = 'L'
        } else {
            onlyOne.modoIngreso = 'Q'
        }
    }

    handleCreate = () => {
        const { onlyOne, idStakeholder } = this.state;
        this.setState({loadingBtnModal: true});
        this.templateModoIngreso(onlyOne);        
        
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Agrega_Usuario_Stakeholder_Call({id: idStakeholder, data: onlyOne}, resolve, reject)
        })
        .then((res:any) => {
            const { List } = this.props;
            this.setState({List});
            this.handleCloseModal();
            message.success(res)
        })
        .catch((err) => {
            this.setState({loadingBtnModal: false})
            message.error(err);
        })
    }

    handleUpdate = () => {
        const { onlyOne, idStakeholder } = this.state;
        this.setState({loadingBtnModal: true})
        this.templateModoIngreso(onlyOne);

        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Actualiza_Usuario_Stakeholder_Call({id: idStakeholder, data: onlyOne}, resolve, reject)
        })
        .then((res:any) => {
            const { List } = this.props;
            this.setState({List});
            this.handleCloseModal();
            message.success(res);
        })
        .catch((err) => {
            this.setState({loadingBtnModal: false})
            message.error(err);
        })
    }
    
    handleDelete = (Id:number) => {
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Elimina_Usuario_Stakeholder_Call(Id, resolve, reject)
        })
        .then((res:any) => {
            const { List } = this.props;
            this.setState({List});
            message.success(res);
        })
        .catch((err) => {
            message.error(err);
        })
    }

    handleSelecciona = (Id:number) => {
        new Promise((resolve:any, reject:any) => {
            this.props.ACuenta_Selecciona_Usuario_Stakeholder_Call(Id, resolve, reject)
        })
        .then(() => {
            const { onlyOne } = this.props;
            const modoIngreso = onlyOne.ModoIngreso && onlyOne.ModoIngreso[0] === 'A' ? ['Q', 'L'] : [...onlyOne.ModoIngreso]; 
            const newOnlyOne = {
                id: onlyOne.Id,
                email: onlyOne.Email,
                nombre: onlyOne.Nombre,
                apellidoPaterno: onlyOne.ApellidoPaterno,
                apellidoMaterno: onlyOne.ApellidoMaterno,
                estado: onlyOne.Estado,
                numeroDocumento: onlyOne.NumeroDocumento,
                //nodo: onlyOne.Nodo,
                modoIngreso
            }
            this.setState({onlyOne: newOnlyOne, editOrCreate: 2, visible: true, idStakeholder: onlyOne.IdStakeholder, visibleOption: true})
        })
        .catch((err) => {

        })
    }

    handleDescargarQr = (data:any) => {
        const { id, numeroDocumento } = data;
        new Promise((resolve, reject) => {
            this.props.ACuenta_Descarga_Qr(id, resolve, reject)
        })
        .then((res:any) => {
            var a = document.createElement("a");
            a.href = res;
            //${new Date().getTime()}
            a.download = `${numeroDocumento}.png`;
            a.click();
        })
        .catch((err) => {
            message.error(err)
        })
    }

    functionsCRUD = (type: any, Id?:any) => {
        switch(type){
            case 1: this.handleCreate();break;
            case 2: this.handleUpdate();break;
            case 3: this.handleDelete(Id);break;
            case 4: this.handleSelecciona(Id);break;
            case 5: this.handleDescargarQr(Id);break;
        }
    }

    /*-----------------------------------------------------*/
	onChange = (value:any, type?:boolean) => {
        if(type) {
            this.setState((state:any) => ({
                ...state, 
                ...value
            }))
            return;
        }

        this.setState((state:any) => ({
            ...state, onlyOne:{
                ...state.onlyOne,
                ...value
            }
        }))
    }

    onChangeSearch = (value:any) => {
        this.setState((state:any) => ({
            ...state,
            search:{
                ...state.search,
                ...value
            }
        }))
    }

    onPagination = (value:any) => {}

	render() {
        const { search: { Tipo, Texto }, loadingBtn1, loadingBtn2, loadingBtn3 } = this.state;
		return (
			<ToggleMenuWrapper>
				<div className="content">
					<div className="header_content">
                        <div className="title">
                        <Breadcrumb separator="|" className='ff-Mont-Family'>
                            <Breadcrumb.Item href={'/stakeholders'}> {TITLE__STAKEHOLDER} </Breadcrumb.Item>
                            <Breadcrumb.Item href={''}> <a> Usuarios Stakeholders  </a> </Breadcrumb.Item>
                        </Breadcrumb>
                        </div>
                        <div className="button" style={{paddingLeft:'175px'}}>
							<button onClick={() => this.HandleOpenModal()}>
								Crear
							</button>
						</div>
                    </div>		
                    { this.renderModal() }	
					<div className="content_list">
						<div className="search ff-Mont-Family" style={{borderRadius: "10px 10px 0px 0px"}}>
                            <div className="inputs">
								<p>NOMBRES / APELLIDOS:</p>
                                <Input value={Texto} onChange={(e) => this.onChangeSearch({Texto: e.target.value})}/>
							</div>
							<div className="inputs">
                                <p>TIPO DE STAKEHOLDER: </p>
								<Select style={{width:'180px'}} allowClear onChange={(value:any) => this.onChangeSearch({Tipo:value})} value={ Tipo }>

									{
										this.props.List_Type?.map((item:any, index:number) => 
											<Option value={ item.id } key={ item.id }> { item.nombre } </Option>
										) 
									}
									
                                </Select>
							</div>
                            <div className="inputs" style={{marginTop:'55px'}}>
								<Button loading={loadingBtn1} onClick={() => this.onSearch()}>Buscar</Button>
                            </div>
							<div className="inputs" style={{marginTop:'55px'}}>
								<Button style={{width:'50px' }} loading={loadingBtn2} onClick={() => this.onRestore()}><RedoOutlined/></Button>
                            </div>
                            <div className="inputs" style={{marginTop:'55px'}}>
								<Button style={{ backgroundColor:'#00aeef', width: 'max-content' }} loading={loadingBtn3} onClick={this.handleExportZip}>Descargar QR</Button>
                            </div>
						</div>
						<div className="list" style={{paddingBottom:'30px'}}> 
                            <List 
                                datasource={this.state.List}
                                columns={metadata(this.buttons)}
                                onPagination={this.onPagination}
                                pagination={true}
                                bordered={false}
                                //bordered
                            /> 
						</div>
					</div>
				</div>
			</ToggleMenuWrapper>
		)
	}
}

const metadata = (buttons:any) => [
    {title: 'ID', dataIndex: 'id',key: 'id', width: 80},
    {title: 'Nombre', dataIndex: 'nombre', key: 'nombre'},
    {title: 'Apellido Paterno', dataIndex: 'apellidoPaterno', key: 'apellidoPaterno'},
    {title: 'Apellido Materno', dataIndex: 'apellidoMaterno', key: 'apellidoMaterno'},
    {title: 'DNI', dataIndex: 'numeroDocumento', key: 'numeroDocumento'},
    //{title: 'Nodo', dataIndex: 'nodo', key: 'nodo'},
    {title: 'Modo de ingreso', dataIndex: 'modoIngreso', key: 'modoIngreso'},
    {title: 'Correo', dataIndex: 'email', key: 'email'},
    {title: 'Stakeholder', dataIndex: 'stakeholder', key: 'stakeholder'},
    {title: 'Tipo Stakeholder', dataIndex: 'tipoStakeholder', key: 'tipoStakeholder'},
    {title: 'Estado', dataIndex: 'estado', key: 'estado'},
    {title: 'Acciones', dataIndex: '', key: 'actions', render: (text:any, record:any) => buttons(record, 1), width: 120},
    {title: 'QR', dataIndex: '', key: 'QR', render: (text:any, record:any) => buttons(record, 2), with: 120}
   ]
//listStkhldr
const mapStateToProps= (state:any) => ({
    List: state.cuenta.userStkdlr.list,
    onlyOne: state.cuenta.userStkdlr.onlyOne,
    ListStakeholder: state.cuenta.userStkdlr.listStkhldr,
    List_Type: state.stakeholder.stakeholder_Type.Lista
})

const mapDispatchToProps = (dispatch:any) => {
    const { ACuenta_Lista_Usuario_Stakeholder_Call, ACuenta_Selecciona_Usuario_Stakeholder_Call, ACuenta_Actualiza_Usuario_Stakeholder_Call,
            ACuenta_Agrega_Usuario_Stakeholder_Call, ACuenta_Elimina_Usuario_Stakeholder_Call, ACuenta_Descarga_Qr, ACuenta_Descarga_Qr_Zip } = Actions;
    return bindActionCreators({
        ACuenta_Lista_Usuario_Stakeholder_Call,
        ACuenta_Selecciona_Usuario_Stakeholder_Call,
        ACuenta_Actualiza_Usuario_Stakeholder_Call,
        ACuenta_Agrega_Usuario_Stakeholder_Call,
        ACuenta_Elimina_Usuario_Stakeholder_Call,
        ACuenta_Descarga_Qr_Zip,
        ACuenta_Descarga_Qr
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Usuario);