import dayjs from 'dayjs';

export class AdapterGeneric {

    private static getExeception(err: any) {
        if(err.errors){
            const values = Object.entries(err.errors);
            const getMessages: Array<string> = [];
    
            
            values?.forEach((item:any) => {
                const newMessages = item[1];
                let temp = "";
                newMessages?.map((message:any) => temp += `${message} <br/> `);
                getMessages.push(temp);
            })
    
            return getMessages;
        }
        else if(err.message === 'Not Authorized'){
            return 'Usuario no autorizado';
        }
        else if(err.exceptionMessage) {
            return `${err.exceptionMessage}`;
        }
        else {
            return err;
        }
    }

    public static convertStringToDate(stringDate: string) {
        try {
            let i = 0;
            let f = 0;
            let y: any = '';
            let m: any = '';
            let d: any = '';
            let h: any = '';
            let mi: any = '';
            let s: any = '';
            let Tipo = 0;

            Tipo = stringDate.indexOf('-') !== -1
                ? 1
                : stringDate.indexOf('/') !== -1
                    ? 2
                    : 0;
            switch (Tipo) {
                case 1:
                    f = 4;
                    y = stringDate.slice(i, f);
                    if (y.indexOf('-') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('-') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 2;

                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f -= 1; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                case 2:
                    f = 2;
                    d = stringDate.slice(i, f);
                    if (d.indexOf('/') !== -1) { f--; d = stringDate.slice(i, f); }
                    else { d = d === '' ? 1 : parseInt(d); }
                    i = f + 1;
                    f = i + 2;

                    m = stringDate.slice(i, f);
                    if (m.indexOf('/') !== -1) { f -= 1; m = stringDate.slice(i, f); }
                    else { m = m === '' ? 0 : parseInt(m) - 1; }
                    i = f + 1;
                    f = i + 4;

                    y = stringDate.slice(i, f);
                    if (y.indexOf('/') !== -1) { f--; y = stringDate.slice(i, f); }
                    else { y = y === '' ? 1 : parseInt(y); }
                    i = f + 1;
                    f = i + 2;

                    h = stringDate.slice(i, f);
                    h = h === '' ? 0 : parseInt(h);
                    i = f + 1;
                    f = i + 2;

                    mi = stringDate.slice(i, f);
                    mi = mi === '' ? 0 : parseInt(mi);
                    i = f + 1;
                    f = i + 2;

                    s = stringDate.slice(i, f);
                    s = s === '' ? 0 : parseInt(s);
                    break;
                default:
                    throw new Error('formato desconocido');
            }
            let resp: Date = new Date(y, m, d, h, mi, s, 0);
            if (Number.isNaN(resp.getTime())) {
                throw Error('Fecha no es Válida')
            }
            return resp;
        } catch (error) {
            throw error;
        }
    }

    public static convertDateToString(Fecha: Date = new Date(), Tipo: number = 1) {

        try {
            Fecha = typeof Fecha === 'string' ? new Date(Fecha) : Fecha;
            let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            let y = Fecha.getFullYear();
            let m = Fecha.getMonth() + 1;
            let d = Fecha.getDate();

            let h = Fecha.getHours();
            let mi = Fecha.getMinutes();
            let s = Fecha.getSeconds();

            let am = h > 11 ? false : true;

            let h2 = h > 12 ? h - 12 : h;

            let result = '';

            switch (Tipo) {
                case 1:
                    result = Fecha.toISOString();
                    break;
                case 2:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h < 10 ? '0' : '') + h}:${(mi < 10 ? '0' : '') + mi}:${(s < 10 ? '0' : '') + s}`;
                    break;
                case 3:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y}`;
                    break;
                case 4:
                    result = `${(d < 10 ? '0' : '') + d} de ${meses[m - 1]} del ${y}`;
                    break;
                case 5:
                    result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h2 < 10 ? '0' : '') + h2}:${(mi < 10 ? '0' : '') + mi} ${am ? 'AM' : 'PM'}`;
                    break;
                case 6:
                    result = `${y}-${(m < 10 ? '0' : '') + m}-${(d < 10 ? '0' : '') + d}`;
                    break;
                default:
                    throw new Error('Datos no correctos');
            }
            return result;
        } catch (error) {
            throw error;
        }
    }

    public static readErrorException (error: unknown): string {
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        return this.getExeception(errMessage) as string;
    }

    public static formatDate(): string {
        let date = new Date();
        let Fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        let Tiempo = date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

        return `${Fecha} ${Tiempo}`;
    }

    public static downloadExcel(nameFile: string, content: string): void {
        let link = document.createElement("a");
        link.href = content;
        link.download = `${nameFile}.xlsx`;
        link.click();
    }

    public static reloadArrayReference<T>(payload: any[]){
        return (Array.isArray(payload) ? JSON.parse(JSON.stringify(payload)) : []) as T[];
    }

    public static readDateFromInputsDate = (date: string | null | Date) => {
        if (!date) return null;
        if (typeof date !== 'string') return date;
        return dayjs(date, 'YYYY-MM-DD');
    };

    public static calculatePercentage(task: number = 0, TotalTask: number = 0): number {
        if (task === 0 || TotalTask === 0) return 0;
        return parseFloat(( (100 * task) / TotalTask ).toFixed(1))
    }

    public static sumDays(days: number, date: string): Date{
        let parseDate = new Date();
        if (typeof date === 'string') parseDate = this.convertStringToDate(date);

        parseDate.setDate(parseDate.getDate() + days);
        return parseDate;
    }

    public static calculateDays(fecha: string) {
        const fechaProporcionada = new Date(fecha).getTime();
        const fechaActual = new Date().getTime();
        const diferenciaMilisegundos = fechaProporcionada - fechaActual;
        const dias = Math.round(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
      
        return dias;
    }

    public static scrollTop(coordX: number = 0, coordY: number = 0) {
        window.scrollTo(coordX, coordY);
    }

    public static copyTextToClipboard(text: string) {
        navigator.clipboard.writeText(text);
    }
}