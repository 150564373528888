import React, { Component } from 'react';
import OptionTemplate from '../../../components/optionTemplate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../../redux/actions';
import { UserOutlined } from '@ant-design/icons';
import './../../../assets/styles/options.scss'
import { AdapterLocalStorage } from '../../../shared/Infraestructure/AdapterLocalStorage';

class MyAccount extends Component<any,any>{

    constructor(props:any){
        super(props);
        this.state = {

        }
    }

    componentDidMount(){

    }

    onSave = () => {
    }

    render(){
        const user = JSON.parse(AdapterLocalStorage.get('user').user);
        const nombreCompleto = `${user.nombre} ${user.apellido} ${user.apellidoM || ''}` ;
        const usuario = `${user.email}`;

        return(
            <OptionTemplate
                title="Mi cuenta"
                height='150px'
                //200px
            >
                <>
                    <div className="my-account">
                        <div className="ma-icon">
                            <div>
                                <UserOutlined />
                            </div>
                        </div>
                        <div className="ma-content">
                            <div>
                                <div className="cont-name">
                                    <strong> { nombreCompleto } </strong>
                                </div>
                                <div className="cont-inf">
                                    <p>Usuario: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ usuario }</p>
                                    <div> <p style={{width:'70%'}}> Contraseña: ٭٭٭٭٭٭٭ </p>  {/*<a>editar</a>/*/} </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-save" style={{borderRadius: '0 0 10px 10px'}}>
                        { /*<button onClick={() => this.onSave}>Guardar</button> */}
                    </div>
                </>
            </OptionTemplate>
        )
    }
}

const mapStateToProps = (state:any) => ({
    list_Menu: state.cuenta.myaccount.menu
})

const mapToDispatchProps = (dispatch:any) => {
    const { ACuenta_Menu_Lateral_Call } = Actions;
    return bindActionCreators({
        ACuenta_Menu_Lateral_Call
    }, dispatch)
}

export default connect(mapStateToProps, mapToDispatchProps)(MyAccount);