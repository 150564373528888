import React from 'react';
import { Table, Collapse, Checkbox } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const List = (props:any) => {

    let cont = 0;
    const sizeX = props.columns.length * 125;
    const style = () => {
        cont += 1;
        return cont % 2 === 0 ? "ant-table-row-1" : "ant-table-row-2"
    }
    const confRowSelection = rowSelection(props.onChangeSelection, props.selectedRowKeys);
    //props: datasource, columns, pagination(true or false), bordered(true or false), showCheckbox(true or false).
    return(
        <Table 
            dataSource={props.datasource}
            columns={props.columns}
            size='small'
            scroll={{x:`${sizeX}px`}}
            pagination={{
                total: props.total || props.datasource.length,
                //pageSize: props.datasource.length,
                pageSize: 10,
                hideOnSinglePage: props.pagination,
                current: props.current 
            }}

            onChange={(e) => props.onPagination(e)}

            bordered={props.bordered}
            rowClassName={(record, index) => style()}
            rowKey={record => record.Id}
            /*onRow={(record, rowIndex) => {
                return {
                    onMouseEnter: event => {}
                };
            }}*/
            /*expandable={{
                rowExpandable: () => true,
                onExpand:(expanded:any, record:any) =>console.log(expanded)
            }}*/
            //expandedRowRender={true}
            //expandIcon={(props) => customExpandIcon(props)}
            rowSelection={ props.showCheckbox ? {
                type: 'checkbox',
                ...confRowSelection
            } : undefined}
        />
    )
}
 
export const ListCollapse = (props:any) => {
    const sizeX = props.columns.length * 125;

    const header = (title:string) => {
        const { buttonsDelete_Update } = props;
        if(buttonsDelete_Update)
            return(
                <div className="header">
                    <label>{title}</label>
                    <div>
                        <button onClick={(e:any) => buttonsDelete_Update(e, 1)}><EditOutlined /></button>
                        <button onClick={(e:any) => buttonsDelete_Update(e, 2)}><DeleteOutlined/></button>
                    </div>
                </div>
            )
        else 
            return (
                <div className="header">
                    <label>{title}</label>
                    <div></div>
                </div>
            )
    }

    const style = (index: number) => {
        return index % 2 === 0 ? "ant-table-row-1" : "ant-table-row-2"
    }

    //props: getData(), datasource, columns,NombreButton, header, style, functionButton, enabledHeader, buttonsDelete_Update, edit, asignaFuente
    return(
        <Collapse
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined /> }
            onChange={(val) => props.getData()}
            style={props.style || {}}
        >
            <Panel 
                key="1"
                header={header(props.header)}  
                extra={ props.edit && <button onClick={(e:any) => props.functionButton(e, props.header)}>{props.NombreButton || 'Crear'}</button>}
            >
                <Table 
                    dataSource={props.datasource}
                    columns={props.columns}
                    size='small'
                    scroll={{y: 240, x:`${sizeX}px`}}
                    className='ant-table-scroll-horizontal'
                    pagination={{
                        total: props.datasource.length,
                        pageSize: props.datasource.length,
                        hideOnSinglePage: true
                    }}
                    rowClassName={(record, index) => style(index)}
                    rowKey={record => record.Id}
                    showHeader={ props.enabledHeader }
                    bordered
                />
            </Panel>
        </Collapse>
    )
}

export const ListCollapseInactive = (props:any) => {

    const header = (title:string) => {
        return (
            <div className="header_inactive">
                <label>{title}</label>
                <div></div>
            </div>
        )
    }

    //props: NombreButton, header, style, functionButton
    return(
        <Collapse
            expandIcon={() =>  <PlusOutlined /> }
            style={props.style || {}}
        >
            <Panel 
                key="1"
                header={header(props.header)}  
                extra={<Checkbox onClick={(e:any) => props.functionButton(e, props.header)}/>}
                disabled
            >
            </Panel>
        </Collapse>
    )
}

const rowSelection = (onChange:any, data:any) => ({
    onChange: (value: any, newValue:any) => {
        onChange(newValue);
    },
    selectedRowKeys: data || [],
    getCheckboxProps: (record: any) => ({
      disabled: !record.urlTraza
    }),
});