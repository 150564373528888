import React, { Component } from 'react';
import { Breadcrumb, Modal, Row, Col, Input, DatePicker, message, Button } from 'antd';
import { List } from './../../../components/lists/list';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ToggleMenuWrapper from '../../../components/ToggleMenuWrapper';
import { TITLE__PROCESO } from './../../../global/title-modules';
import './../../../assets/styles/lists.scss';
import { RedoOutlined } from '@ant-design/icons';
import { ElementBreadCrumb } from '../../../shared/components/ElementBreadCrumb';
import { AdapterLocalStorage } from '../../../shared/Infraestructure/AdapterLocalStorage';
import { EntityUser } from '../../../shared/Domain/EntityUser';
import axios from 'axios';

interface EntityDownload {
    contentType: string;
    enableRangeProcessing: string;
    entityTag: string;
    fileContents: string;
    fileDownloadName: string;
}

class genCode extends Component<any,any> {
    constructor(props:any){
        super(props);
        this.state = {
            loadingBtn1:false,
            search:{

            },
            listRegistro : [],
            visible: false,
            data:{

            },
            listCodesku: []
        }
    }

	componentDidMount(){
        this.handleSearch(true);
    }

    onChange = (value:any, type:number) => {

        switch(type){
            case 1: 
                this.setState((state:any) => ({
                    ...state, 
                    search : {
                        ...state.search,
                        ...value
                    }
                }))
            ;break;
            case 2:
                if(value.Cantidad && value.Cantidad < 1) return;
                this.setState((state:any) => ({
                    ...state, 
                    data : {
                        ...state.data,
                        ...value
                    }
                }))
            ;break;
        }
    }

    /*------------------------Functions--------------------------------*/
    handleSearch = (active:boolean) => {
        const { search } = this.state;
        this.setState({loadingBtn1:true});

        return new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Registros_Confeccion_Call({search, active}, resolve, reject)
        })
        .then(() => {
            const { listRegistro, listCodesku } = this.props;
            this.setState({listRegistro: listRegistro.items, listCodesku, loadingBtn1:false})
        })
        .catch((err) => {
            this.setState({loadingBtn1:false});
            message.error(err);
        })
    }

    handleGenCode = () => {
        const { data } = this.state;

        const payload = `${data.Id}/${data.Cantidad}?codeSKU=${data.CodeSKU || ""}`
        new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Genera_Producto_Code_Call(payload, resolve, reject);
        })
        .then(() => {
            this.handleSearch(false);
            this.setState({visible: false});
        })
        .catch((err) => {
            message.error(err);
        })
    }

    handleDescargaQr = (value:any) => {
        new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Descarga_Qr_Call(value.Id, resolve, reject);
        })
        .then(() => {
            const { descQR } = this.props;

            var a = document.createElement("a");
            a.href = `data:text/plain;base64,${descQR.fileContents}`;
            a.download = descQR.fileDownloadName;
            a.click();
        })
        .catch(() => {
            
        })
    }

    handleDescargaPIN = (value:any) => {
        new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Descarga_Pin_Call(value.Id, resolve, reject);
        })
        .then(() => {
            const { descPIN } = this.props;
            var a = document.createElement("a");
            a.href = descPIN;
            a.download = `${value.Id}_codes`;
            a.click();
        })
        .catch((err) => {
            
        })
    }
    /*---------------------------------------------------------------*/
    /*------------------------Table--------------------------------*/
    downloadQR = async (idProceso: number, type: 'his' | 'nft') => {
        const urlDownload = `https://sdf-api.magiadigital.com/${type === 'his' ? 'api/v1/client/proceso/descarga-qr/' : 'api/v1/client/proceso/descarga-qr-nft/'}${idProceso}`;
        try {
            const result: EntityDownload = (await axios.get(urlDownload, { headers: { Authorization: `Bearer ${AdapterLocalStorage.get('token').token}` } })).data.result;
            const a = document.createElement("a");
            a.href = `data:${result.contentType};base64,${result.fileContents}`;
            a.download = `${result.fileDownloadName}`;
            a.click();
        } catch (error) {
            message.error((error as any)?.response.data.responseException.exceptionMessage || 'Ocurrió un error no controlado')
        }
    }

    handleMetadata = (): any[] => {
        //const disabled = value.Estado === 'G' ? false : true;
        /*
        const ComponentType2 = (record: any) => (
            <div>
                <button onClick={() => this.handleDescargaPIN(value)}>PIN</button> 
                <button onClick={()=> this.handleDescargaQr(value)}>QR</button>
            </div>
        );
        /*switch(type){
            case 1: return ComponentType1;
            case 2: return ComponentType2;//(disabled ? null : ComponentType2);
        }*/
        const dataUser: EntityUser = JSON.parse(AdapterLocalStorage.get('user').user);
        const isAdmin = dataUser.tipo === 2;
        const isStakeHolderConfeccionPrenda = dataUser.procesos?.some(row => row === 'confeccion-de-prenda');

        let metadata = [
            {title: 'ID', dataIndex: 'Id',key: 'FechaRegistro'},    
            {title: 'Registro de Confección', dataIndex: 'Descripcion',key: 'Descripcion'},
            {title: 'Cantidad', dataIndex: 'Cantidad',key: 'Cantidad'},
            {title: 'Fecha del Registro', dataIndex: 'FechaRegistro',key: 'FechaRegistro'},
            {title: 'Códigos QR', dataIndex: 'codeqr',key: 'codeqr', render: (text:any, record:any) =>
                (
                    <div className='custonButtomsTableCell'>
                        <button className={`${!isAdmin ? 'btn-disabled' : ''}`} disabled={!isAdmin} onClick={() => this.setState({visible:true, data: record})}> Generar </button>
                    </div>
                )
            },
            {title: 'Historia', dataIndex: 'gene',key: 'gene', render: (text:any, record:any) =>
                (
                    (isAdmin || isStakeHolderConfeccionPrenda) ?
                        <div className='custonButtomsTableCell'>
                            <button onClick={() => this.downloadQR(record.Id, 'his')} disabled={!(isAdmin || isStakeHolderConfeccionPrenda)}> Descargar </button>
                        </div>
                        : null
                )
            },
            {title: 'Link NFT', dataIndex: 'code', key: 'code', render: (text:any, record:any) => 
                (
                    (isAdmin || isStakeHolderConfeccionPrenda) ?
                        <div className='custonButtomsTableCell'> 
                            <button onClick={() => this.downloadQR(record.Id, 'nft')} disabled={!(isAdmin || isStakeHolderConfeccionPrenda)}> Descargar </button>
                        </div>
                        : null
                )
            },
        ]

        if (!isAdmin && !isStakeHolderConfeccionPrenda) metadata = metadata.filter(row => !['gene', 'code'].includes(row.key))

        return metadata;
    }

    /*---------------------------------------------------------------*/
    /*------------------------Modal--------------------------------*/

    renderModal = () => {
        const { visible, data } = this.state;

		return(
			<Modal
                title={ this.renderHeaderModal() }
                visible={ visible }
                onCancel={ () => this.handleCloseModal() }
                footer={null}
                width={400}
            >
            {data.Descripcion}
            
            <br/>
            {/* Ingrese la cantidad de códigos a crear */}
            { this.renderContent() }
            <br/>

            <div className='buttons'>
                <div className='cancel'>
                    <button style={{ width: 130 }} onClick={() => this.handleCloseModal() }>Cancelar</button>
                </div>
                <div className='save'>
                    <button style={{ width: 130 }} onClick={() => this.handleSaveModal()}>Generar</button>
                </div>
            </div>
		  </Modal>
		)
    }

    renderHeaderModal = () => {
		return (
			<div>
				<Breadcrumb separator="|">
					<Breadcrumb.Item className='ff-Mont-Family'> Registro de Confección </Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}
    
    handleCloseModal = () => {
        this.setState({visible: false, data: {}})
    }

    handleSaveModal = () => {
        const validation = this.handleValidation();
		if(validation.length > 0) {
			for(var i=0;i < validation.length; i++)
				message.error(validation[i]);
			return;
        }
        
        this.handleGenCode();
    }

    renderContent = () => { 
        let { data : { Cantidad } } = this.state;

        return (
            <div className='form__Modal ff-Mont-Family'>
                <Row gutter={[6,16]}>
                    <Col span={24}>
                        <label>&nbsp;&nbsp;Cantidad</label>
                        <Input type='number' min={1} value={Cantidad} onChange={(e:any) => this.onChange({Cantidad: e.target.value}, 2)}/>
                    </Col>
                </Row>
            </div>
        );
    }

    handleValidation = () => {
        const { data } = this.state;
        const Validation: Array<string> = [];
        
        if(data.Cantidad <= 0 || data.Cantidad === null || data.Cantidad === '' || data.Cantidad === undefined) Validation.push('Ingrese una cantidad');
        //if(data.CodeSKU === null || data.CodeSKU === undefined) Validation.push('Ingrese un código SKU');

        return Validation;
    }

    /*---------------------------------------------------------------*/

    onRestore = () => {
        this.setState({loadingBtn2: true})
        return new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Registros_Confeccion_Call({search: {}, active:false}, resolve, reject)
        })
        .then(() => {
            const { listRegistro } = this.props;
            this.setState({listRegistro: listRegistro.items, search:{}, loadingBtn2: false});
        })
        .catch((err) => {
            this.setState({loadingBtn2: false});
        })
    }
    /*---------------------------------------------------------------*/
 
    render(){
        const { listRegistro, loadingBtn1, loadingBtn2, search:{FechaIncicial, FechaFinal} } = this.state;
        return(
            <ToggleMenuWrapper>
                <div className="content">
                    <div className="header_content">
                        <div  className="title">
                            <div style={{whiteSpace: 'nowrap'}}>
                                <ElementBreadCrumb options={[ {navigate: true, text: TITLE__PROCESO, route: '/procesos'}, {navigate: false, text: 'Códigos QR', route: ''} ]} />
                            </div>
                            <div>
                            {/* <h1 style={{fontSize:'14px'}}>Para generar codigos de registros:</h1> */}
                            </div>
                        </div>
                    </div>
                    <div className="content_list">
                        <div className="search ff-Mont-Family" style={{borderRadius:'10px 10px 0px 0px'}}>
                            <div className="inputs">
                                <p>FECHA DE REGISTRO:</p>
                                <DatePicker placeholder='Fecha Inicio' value={FechaIncicial} onChange={(value) => this.onChange({FechaIncicial: value}, 1)}/>
                                <DatePicker placeholder='Fecha Fin' value={FechaFinal} onChange={(value) => this.onChange({FechaFinal: value}, 1) }/>
                            </div>
                            <div className="inputs" style={{paddingTop:'55px'}}>
                                <Button loading={loadingBtn1} style={{ marginLeft: '280px' }} onClick={() => this.handleSearch(false)}>Buscar</Button>
                            </div>
                            <div className="inputs" style={{paddingTop:'55px'}}>
                                <Button loading={loadingBtn2} onClick={() => this.onRestore()} ><RedoOutlined />&nbsp; Limpiar</Button>
                            </div>
                        </div>
                        <div className="list">
                            <List 
                                datasource={listRegistro}
                                columns={this.handleMetadata()}
                            /> 
                        </div>
                        { this.renderModal() }
                    </div>
                </div>
            </ToggleMenuWrapper>
        )
    }
}

const mapStateToProps= (state:any) => ({
    listRegistro: state.proceso.listConfeccion,
    descQR: state.proceso.descQR,
    listCodesku: state.proceso.codesku,
    descPIN: state.proceso.descPIN
})

const mapDispatchToProps = (dispatch:any) => {
    const { AProceso_Registros_Confeccion_Call, AProceso_Genera_Producto_Code_Call,
            AProceso_Descarga_Qr_Call, AProceso_Descarga_Pin_Call } = Actions;

    return bindActionCreators({
        AProceso_Registros_Confeccion_Call, 
        AProceso_Genera_Producto_Code_Call,
        AProceso_Descarga_Qr_Call,
        AProceso_Descarga_Pin_Call
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(genCode);