import { EntityDataGeneral } from "../../Domain/EntityUtils";

interface ContainerProps {
    keys: string[]
    complete: any;
    dataGeneral: EntityDataGeneral;
    url_traza: string;
}

const resuorceTipoFibra: any = {
    Drytex: {
        title: 'Drytex',
        text: 'Es la fibra acrílica hilada en seco para el uso textil, ideal para la elaboración de ovillos, conos, madejas y tejidos de uso universal.',
        img: '/assets/catalogo/p1-drytex.png'
    },
    "Drytex Color": {
        title: 'Drytex Color',
        text: 'Es la fibra desarollada con colores de alta solidez en lotes de producción que se adaptan a los procesos de nuestros clientes.',
        img: '/assets/catalogo/p1-drytex-color.png'
    },
    "Drytex Sun": {
        title: 'Drytex Sun',
        text: 'Es la fibra teñida en masa, tiene un performance que la coloca como la #1 en su tipo.',
        img: '/assets/catalogo/p1-drytex-sun.png'
    },
    "Drytex Cycle": {
        title: 'Drytex Cycle',
        text: 'La única fibra acrílica hilada en seco producida en las Américas, con componente reciclado y certificación RCS.',
        img: '/assets/catalogo/p1-drytex-cycle.png'
    },
    "Drytex Nanoshield": {
        title: 'Drytex Nanoshield',
        text: 'Es la fibra textil con aplicación de nanopartículas de cobre con poder antibacterial, antimicrobial y antiviral.',
        img: '/assets/catalogo/p1-drytex-nanoshield.png'
    },
    "Fibra acrílica reciclada antibacterial con nanopartículas de cobre": {
        title: 'Drytex reciclada antibacterial con nanopartículas de cobre',
        text: 'Fibra textil que reduce el contenido bactericida de las prendas y cumple los estándares ecosostenibles de producción industrial.',
        img: ''
    }
}

const keysListToShow = [
    //'tipo_de_fibra_producto',
    //'certificados',
    'm3_de_agua',
    'btu_de_energia',
    'lugar_de_fabricacion'
]

const customLabel: any = {
    'm3_de_agua': 'Cantidad de agua (m<sup>3</sup>) por cantidad de fibra (Ton)',
    'btu_de_energia': 'BTU de energía por cantidad de fibra (Ton)',
    'lugar_de_fabricacion': 'Lugar de fabricación'
}

export const ViewHistory1 = (props: ContainerProps) => {
    return (
        <div className='ViewHistory1'>
            <header>
                <div className='GroupPage'>
                    <label className='fnt-bold'>1</label>
                </div>
                <div className='GroupBanner'>
                    <img src="/assets/catalogo/banner-1.png" alt='Not Source'/>
                    {
                        resuorceTipoFibra[props.dataGeneral.tipo_de_fibra_producto]?.img ?
                        <img className={`logoDrytex ${props.dataGeneral.tipo_de_fibra_producto === 'Drytex Color' ? 'ImageDrytexColor' : ''}`} src={resuorceTipoFibra[props.dataGeneral.tipo_de_fibra_producto]?.img} alt="Not Source" />
                        : null
                    }
                </div>
            </header>
            <div className='MainContent'>
                <div className='content'>
                    <div className='GroupTitle'>
                        <p className='fnt-regular'>
                            NUESTRA MATERIA PRIMA
                            <span className='fnt-bold' style={{ textTransform: 'uppercase' }}>&nbsp;FIBRA DYTREX</span>
                        </p>
                        <p className='fnt-regular'>
                            El proceso de producción de esta prenda inicia con nuestra fibra
                        </p>
                    </div>
                    <div className='GroupShadow'>
                        <p className='fnt-bold'>
                            {resuorceTipoFibra[props.dataGeneral.tipo_de_fibra_producto]?.title}
                        </p>
                        <p className='fnt-regular'>
                            {resuorceTipoFibra[props.dataGeneral.tipo_de_fibra_producto]?.text}
                        </p>
                    </div>
                    <div className='GroupInputs'>
                        {
                            keysListToShow.map(row => {
                                let data: { value: string; label: string; } | null = props.complete[row];
                                if (!data) return null;

                                return (
                                    <div className='GroupInput'>
                                        <label dangerouslySetInnerHTML={{ __html: customLabel[row] }} className='fnt-bold' />
                                        <div><span className="fnt-regular">{data.value}</span></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <a className='AccederBlockchain fnt-bold' href={props.url_traza} rel="noreferrer" target='_blank'>Acceder al Blockchain</a>
                </div>
            </div>
        </div>
    )
}