import React, { useEffect } from 'react';
import { View } from "./Components/View";
import { ViewModal } from './Components/ViewModal';
import { Controller } from "./Infraestructure/Controller";
import { ViewModalDetail } from './Components/ViewModalDetail';
import './../../../assets/styles/lists.scss';

const ViewMain = () => {
    const controller = Controller();

    useEffect(() => {
        controller.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <View
                formFilters={controller.formFilter}
                listOption={controller.listOption}
                tabOption={controller.tabOption}
                onChangeTab={controller.onChangeTab}
                onChangeFilter={controller.onChangeFilter}
                onPagination={controller.onPagination}
                resetFormFilter={controller.resetFormFilters}
                searchList={controller.searchList}
            />
            <ViewModal
                closeModal={controller.closeModal}
                modalOptions={controller.modalOption}
                formModal={controller.formModal}
                onChange={controller.onChangeFormModal}
                onSaveModal={controller.onSaveModal}
                loadingFormModal={controller.loadingFormModal}
            />
            <ViewModalDetail
                closeModal={controller.closeModalDetail}
                loadingDetailModal={controller.loadingDetailModal}
                modalDetail={controller.modalDetail}
            />
        </>
    )
}

export default ViewMain;