import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import './ElementUpload.scss';
const { Dragger } = Upload;

interface ContainerProps {
  formatUpload: string[];
  onChange(name: string, file: File): any;
  name: string;
  value: File | null;
}

export const ElementUpload = (props: ContainerProps) => {
  const CustomUploadProps: UploadProps = {
    multiple: false,
    showUploadList: false,
    beforeUpload: (file: File) => {
      if (! props.formatUpload.some(format => file.type.match(`${format}`))) {
        message.error(`${file.name} no es un archivo válido`);
        return false;
      }
      props.onChange(props.name, file);
      return false;
    }
  };

  return (
    <Dragger {...CustomUploadProps} className='ElementUpload'>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Seleccione o arrastre sus archivos aquí</p>
      <p className="ant-upload-hint">
        Solo se admite archivos de tipo <strong>{props.formatUpload.join(', ')}</strong>
      </p>
      { props.value?.name ? <p className="textFile"> {props.value?.name} </p> : null }
    </Dragger>
  );
};
