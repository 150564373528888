import React from 'react';
import { EntityData } from '../Domain/EntityData';

interface ContainerProps {
    payload: EntityData;
}

export const ViewContentPreviewModal = (props: ContainerProps) => {

    return (
        <>
            <div className='form__Modal ViewContentPreviewModal'>
                <h1 className='fw-MontBold' style={{ lineHeight: 'normal', marginBottom: 12 }}> {props.payload.vote_name} </h1>
                <p>{props.payload.proposal_description}</p>

                <div style={{ margin: '20px 0', border: '2px solid #ebebeb' }} />
                <div className='group-quest'>
                    <p>A favor</p>
                    <p>En contra</p>
                    <p>No opina</p>
                </div>
            </div>
        </>
    )
}