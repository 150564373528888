import React, { Component } from 'react';
import './../assets/styles/login.scss'
import API_SERVICE from './../services/api'
import { message, Spin } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import { AdapterLocalStorage } from '../shared/Infraestructure/AdapterLocalStorage';

const getExeception = (err:any) => {
    if(err.errors){
        const values = Object.entries(err.errors);
        //const getMessages: Array<string> = [];
        values.map((item:any) => message.error(item[1][0]))
        //return getMessages;
    } else {
        message.error(err);
    }
}

class Login extends Component<any, any> {

	constructor(props: any) {	
		super(props);
		this.state = {

		};
	}

	componentDidMount() {
		this.loginWithHash();
	}

	/* login with hash */
	loginWithHash = () => {
		if(window.location.pathname !== '/qraccess') return;

		const location = window.location.search;
		const urlParams = new URLSearchParams(location);
		const hash:string = `${urlParams.get('hash')}`;

		API_SERVICE.Cuenta_Login_Hash(hash)
		.then((res) => {
			const { status, data } = res;

			if(status === 200) {
				AdapterLocalStorage.set([
					{ key: 'token', value: data.result.token },
					{ key: 'user', value: JSON.stringify({
							nombre: data?.result.nombre, 
							apellido: data?.result.apellidoPaterno,
							apellidoM: data?.result.apellidoMaterno,
							email: data?.result.email,
							tipo: data?.result.tipo
						})
					},
					{ key: 'dashboard', value: JSON.stringify(data.result.dashboard) },
					{ key: 'menu', value: JSON.stringify(data.result.menuLateral) },
				]);

				window.location.href = `/${data.result.urlRedirect}`; 
			}
		})
		.catch((err) => {
			getExeception(err);
			setTimeout(() => window.location.href = '/login', 3000)
		})
	}


	render() {

		return (
			<div className="login__QR">
				<div className="main__login" style={{filter:"blur(5px)"}}/>
				<Spin
					indicator={<SettingFilled spin height={50} size={32}/>}
					spinning={true}
					style={{ color: 'white' }}
					tip="Cargando.."
				/>
			</div>
		)
	}
}

export default Login;