/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import TemplateLogin from './../components/Login';
import API_SERVICE from './../services/api';
import { useNavigate } from 'react-router-dom';
import { Input, message } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterValidator } from '../shared/Infraestructure/AdapterValidator';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ForgotPassword = () => {
	const [messageResponse, setMessageResponse] = useState<{ type: 'success' | 'error' | ''; message: string; loading: boolean; }>({ type: '', message: '', loading: false })
	const [loadingForm, setLoadingForm] = useState(false);
	const formGeneric = useFormik({
		initialValues: { email: '' },
		onSubmit: () => {},
		validationSchema: Yup.object({
			email: Yup.string().email('Ingrese un Correo').required('Ingrese un Correo')
		})
	})

	const navigate = useNavigate();
	const onChange = (key: string, value: any) => formGeneric.setFieldValue(key, value);
	const onSubmit = async (evt: any) => {
		evt.preventDefault();
		try { await formGeneric.submitForm(); } catch (error) { }
		try { AdapterValidator.validate(await formGeneric.validateForm(), 'default') } catch (error) { message.error((error as Error).message); return; }
		setLoadingForm(true);

		try {
			const { status, data } = await API_SERVICE.Cuenta_Solicita_Recupera_Password(formGeneric.values)
			if(status === 200 || status === 201)
				setMessageResponse({ loading: false, message: data.message, type: 'success' });
		} catch (err) {
			const msg = (err as any).response.data.responseException.exceptionMessage;
			msg.errors ?
				setMessageResponse({ loading: false, message: msg.errors.Email[0], type: 'error' }) :
				setMessageResponse({ loading: false, message: msg, type: 'error' })
		} finally {
			setLoadingForm(false)
		}
	}

	const style = messageResponse.type === '' ? { height: '0px' } : {};
	return (
		<TemplateLogin>
				<div>
					<form onSubmit={(evt) => onSubmit(evt)}>
						<div className="header">
							<label className="title">Olvidé mi contraseña</label>
							<p className="info">
								Si olvidaste tu contraseña, por favor, digita tu 
								correo y te enviaremos las indicaciones correspondientes.	
							</p>
						</div>
						<div className="message" style={style}>
							{ messageResponse.type === 'error' &&  <Spin spinning={messageResponse.loading}><div className="msgError"> <a>x</a><label>{messageResponse.message}</label> </div> </Spin>}
							{ messageResponse.type === 'success' &&  <Spin spinning={messageResponse.loading}><div className="msgSuccess"> <a>✓</a><label>{messageResponse.message}</label> </div> </Spin>}
						</div>
						<div className="body">
							<div className="block-input">
								<label>Correo Electrónico:</label>
								<Input 
									placeholder="Correo" 
									value={formGeneric.values.email}  
									onChange={ (e:any) => onChange('email', e.target.value) }
								/>
								{ <p>  </p> }
							</div>
						</div>
						<div className="submit">
							<button className="sub-cancel" onClick={() => navigate('/login')}> Cancelar </button>
							<Spin indicator={antIcon} spinning={loadingForm} size="small">
								<button type='submit' onClick={(evt) => onSubmit(evt)}> Enviar </button>
							</Spin>
						</div>
					</form>
				</div>
			</TemplateLogin>
	)
}

export default ForgotPassword;