import React from 'react';
import { Col, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import './ElementInputs.scss';

interface IInputBase {
    label: string;
    name: string;
    required?: boolean;
    value: any;
    onChange(name: string, value: any): void;
    size?: 12 | 24;
}

export const InputText = (props: IInputBase) => {
    return (
        <Col span={props.size ?? 24} style={{padding:'8px 12px'}} className='ff-Mont-Family'>
            <label>&nbsp;&nbsp;{props.label} <span style={{ color: 'red' }}>*</span> </label>
            <Input onChange={ (e:any) => props.onChange(props.name, e.target.value)} value={props.value} />
        </Col>
    )
}

export const InputNumber = (props: IInputBase) => {
    const prevOnchange = (value: any) => {
        if (value && value < 0) return props.onChange(props.name, 1);
        props.onChange(props.name, value)
    }
    return (
        <Col span={props.size ?? 24} style={{padding:'8px 12px'}} className='ff-Mont-Family'>
            <label>&nbsp;&nbsp;{props.label} <span style={{ color: 'red' }}>*</span> </label>
            <Input min={1} type={'number'} onChange={(e:any) => prevOnchange(e.target.value)} value={props.value} />
        </Col>
    )
}

export const InputTextArea = (props: IInputBase) => {
    return (
        <Col span={props.size ?? 24} style={{padding:'8px 12px'}} className='ff-Mont-Family'>
            <label>&nbsp;&nbsp;{props.label} <span style={{ color: 'red' }}>*</span> </label>
            <TextArea onChange={ (e:any) => props.onChange(props.name, e.target.value)} value={props.value} rows={4} />
        </Col>
    )
}

interface rows {
    key: string;
    value: any;
    order: number;
}

export const HandleOrderInputs = (props: IInputBase) => {

    const onChange = (key: string, value: any) => {
        const newValue: rows[] = props.value;
        const resultIndex = newValue.findIndex(row => row.key === key);
        if (resultIndex === -1) return;
        newValue[resultIndex].value = value;
        props.onChange(props.name, newValue);
    }

    const addNewOption = () => {
        const newArr: rows[] = [...props.value, { key: `${Date.now()}`, value: '', order: (props.value.length + 1) }];
        props.onChange(props.name, newArr)
    }

    const deleteOption = (key: string) => {
        const arr: rows[] = props.value;
        const resultIndex = arr.findIndex(row => row.key === key);
        if (resultIndex === -1) return;
        arr.splice(resultIndex, 1)
        arr.map((row, index) => row.order = (index + 1))
        props.onChange(props.name, arr)
    }

    const handleSort = (result: DropResult) => {
        if (!result.destination) return;

        const items: rows[] = Array.from(props.value);
        const [recorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination?.index, 0, recorderedItem);

        items.map((row, index) => row.order = (index + 1) )
        props.onChange(props.name, items);
    }

    return (
        <Col span={props.size ?? 24} style={{padding:'8px 12px'}} className='HandleOrderInputs ff-Mont-Family'>
            <div className='content-title'>
                <label>&nbsp;&nbsp;{props.label} <span style={{ color: 'red' }}>*</span> </label>
                <i className="fa-solid fa-plus content-add-item" onClick={addNewOption}></i>
            </div>
            <DragDropContext onDragEnd={handleSort}>
                <Droppable droppableId='main'>
                    {(provide: DroppableProvided) => 
                        <div className='container-drag main' {...provide.droppableProps} ref={provide.innerRef}>
                            {
                                props.value.map((row: rows, index: number) =>
                                    <Draggable key={row.key} draggableId={row.key} index={index}>
                                        {
                                            (providedDraggable) => (
                                                <div
                                                    className='content item-drag'
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}
                                                    ref={providedDraggable.innerRef}
                                                >
                                                    <i {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}
                                                    ref={providedDraggable.innerRef} className='fa-solid fa-bars item-dragrable' style={{ fontSize: 20, alignSelf: 'center', marginRight: 10 }} />
                                                    <Input
                                                        onChange={(e:any) => onChange(row.key, e.target.value)}
                                                        value={row.value}
                                                    />
                                                    <span className='content-function' onClick={() => deleteOption(row.key)}> x </span>
                                                </div>
                                            )
                                        }
                                    </Draggable>
                                )
                            }
                            {provide.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
        </Col>
    )
}