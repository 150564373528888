import * as React from 'react'
import ToggleMenuWrapper from './../../../components/ToggleMenuWrapper';
import './../../../assets/styles/lists.scss';
import * as Actions from '../../../redux/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TITLE__REPORTE } from './../../../global/title-modules';
import { DatePicker, Select, Breadcrumb, Button, message } from 'antd';
import { EyeOutlined, RedoOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { List } from './../../../components/lists/list';
import moment from 'moment';
import { AdapterGeneric } from '../../../shared/Infraestructure/AdapterGeneric';
 
const { Option } = Select;

class Reports extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		this.state = {
			GroupCadena:{
				List:{ cadenasDeValor:[] }
			},
			Lote:{
				lotes:[]
			},
			search:{
                Grupo: 'Joyas',
                Etapas: []
            },
            loadingBtn1: false, 
            loadingBtn2: false,
            loadingBtn3: false,
            List_Proceso: [],
            List:[],
            current: 1,
            selectedRowKeys: [],
		};
    }
    
    componentDidMount(){
        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Proceso_Metadata_Etapas_Call(resolve, reject)
        })
        .then(() => {
            const List_Proceso = this.props.List_Etapa;
            this.setState({List_Proceso});
            this.HandleSearch();
        })
        .catch(() => {

        })
    }

	onChange = (payload:any) => {
        const { name, value } = payload;
        this.setState((state:any) => ({
            ...state, search:{
                ...state.search,
                [name] : value
            }
        }))
    }

    HandleSearch = (payload?:any) => {
        this.setState({loadingBtn1:true})

            const { search } = this.state, formData = new URLSearchParams();

            search.Etapas.forEach((item:any) => formData.append('Etapas', item));
            //formData.append('Grupo', search.Grupo);
            if(search.FechaIncicial) formData.append('FechaIncicial', search.FechaIncicial);
            if(search.FechaFinal) formData.append('FechaFinal', search.FechaFinal);

        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Lista_Procesos_Call( payload ? payload : formData, resolve, reject)
        })
        .then((res:any) => {
            
            const { List : { List_Temp, total } } = this.props;
            this.setState({
                loadingBtn1: false, 
                List: List_Temp,
                total: total
            });

            !payload && this.setState({current: 1});
            
        })
        .catch(() => {
            this.setState({loadingBtn1:false})
        })
    }

    handleDownload = () => {
		const { search } = this.state, formData = new URLSearchParams();

        const validate = this.handleValidatePreDownload();
		if(validate) return;

        search.Etapas.forEach((item:any) => formData.append('Etapas', item));
        formData.append('Grupo', search.Grupo);
        if(search.FechaIncicial) formData.append('FechaIncicial', search.FechaIncicial);
        if(search.FechaFinal) formData.append('FechaFinal', search.FechaFinal);

        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Descarga_Excel_Proceso_Call(formData, resolve, reject)
        })
        .then((res:any) => {
            this.DowloadExcel(this.props.Base64Excel);
        })
        .catch((err:any) => {
            console.log(err);
        })
	}

    DowloadExcel = (base64:any) => {

        var date = new Date();
        var Fecha = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        var Tiempo = date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

        const Nombre = `reporte-${Fecha} ${Tiempo}`;

        var a = document.createElement("a");
        a.href = base64;
        a.download = `${Nombre}.xlsx`;
        a.click();
	}

    handleValidatePreDownload = () => {
        const { List } = this.state;
        if(List.length === 0) {
            message.error('La búsqueda no arrojó resultados');
            return true;
        }
        else
            return false;
    }

	renderButtons = (value:any, type:number) => {
        
        const validation01 = value.urlTraza === '#' || value.urlTraza === undefined;

        switch(type) {
            case 1: 
                if(validation01) 
                    return null;//<a className="blockchain_disabled" > <EyeOutlined /> </a>
                else 
                    return <a className="blockchain" href={value.urlTraza} target='_blank' rel="noreferrer"> <EyeOutlined /> </a>
            case 2:
                return <a className="blockchain" href={ `reporte-trazabilidad/${value.id}`} target='_blank' rel="noreferrer"> <SearchOutlined /> </a>
            case 3:
                if(validation01) 
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    return <a className="blockchain" onClick={() => alert(`se registro el proceso con Id: ${value.id}`)}> <PlusOutlined /> </a>
                else 
                    return null;
        }
    }

	onPagination = (Pagination:any) => {

        const { search } = this.state, formData = new URLSearchParams();

        if(search.FechaIncicial) formData.append('FechaIncicial', search.FechaIncicial);
        if(search.FechaFinal) formData.append('FechaFinal', search.FechaFinal);
        search.Etapas.forEach((item:any) => formData.append('Etapas', item));
        formData.append('NumeroPagina', Pagination.current);
        formData.append('TamanioPagina', Pagination.pageSize);
        formData.append('Grupo', search.Grupo);
        this.setState({current: Pagination.current});

		this.HandleSearch(formData);
	}

    /*---------------------------------------------------------------*/

    reenviarFaltantesBlockchain = () => {
        this.setState({loadingBtn3: true});
        new Promise((resolve:any, reject:any) => {
            this.props.AProceso_Actualizar_Trazabilidad_Blockchain(resolve, reject);
        })
        .then((res) => {
            const { List : { List_Temp, total } } = this.props;
            this.setState({
                loadingBtn3: false, 
                List: List_Temp,
                total: total
            });
            message.success('Reenvío realizado de forma exitosa.');
            console.log(res);
        })
        .catch((err) => {
            this.setState({loadingBtn3: false});
            message.error(err);
        })
    }

	onRestore = () => {
		this.setState({loadingBtn2: true})
		const payload = {
			NumeroPagina: 1,
			TamanioPagina: 10
		}

        new Promise((resolve:any, reject:any) => {
            this.props.AReporte_Lista_Procesos_Call(payload, resolve, reject)
        })
        .then((res:any) => {
            this.setState({
                loadingBtn2: false, 
                List: this.props.List.List_Temp,
                total: this.props.List.total,
                search: {
                    Etapas: [],
                    Grupo: 'Joyas'
                },
                current: 1
            })
            
        })
        .catch(() => {
            this.setState({loadingBtn2:false})
        })
	}

	getDateFormat = (date:any): any => {
		var d = moment(date);
		return date && d.isValid() ? d : null;
	};

    /*---------------------------row-selection-------------------------------*/
    
    onChangeSelection = (selectRow:any) => {
        this.setState({selectedRowKeys: selectRow})
    }

    /*---------------------------------------------------------------*/

	render() {
        const { List_Proceso, total, loadingBtn1, loadingBtn2, loadingBtn3, current, search: { FechaIncicial, FechaFinal, Etapas } } = this.state;
		return (
			<ToggleMenuWrapper>
				<div className="content">
					<div className="header_content">
                        <div className="title">
                            <Breadcrumb separator="|" className='ff-Mont-Family'>
                                <Breadcrumb.Item> {TITLE__REPORTE} </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
					</div>			
					<div className="content_list">
						<div className="search ff-Mont-Family" style={{borderRadius: "10px 10px 0px 0px"}}>
							<div className="inputs">
								<p>FECHA DE REGISTRO:</p>
								<DatePicker placeholder='Fecha Inicio' value={AdapterGeneric.readDateFromInputsDate(FechaIncicial) as any} onChange={(value, text) => this.onChange({name:'FechaIncicial', value: text})}/>
								<DatePicker placeholder='Fecha Fin' value={AdapterGeneric.readDateFromInputsDate(FechaFinal)  as any} onChange={(value, text) => this.onChange({name:'FechaFinal', value: text}) }/>
							</div>
							<div className="inputs">
                                <p>PROCESO: </p>
                                <Select mode="tags" 
                                    allowClear  
                                    style={{width:'180px'}}
                                    dropdownMatchSelectWidth={false}
                                    value={Etapas}
                                    onChange={(value) => this.onChange({name:'Etapas', value})}
                                >
                                    { List_Proceso.map((item:any, index:any) =>  
                                        <Option value={item.Id} key={item.Id}>{ item.Nombre }</Option>) 
                                    }
                                </Select>
							</div>
                            <div className="inputs" style={{paddingLeft: '60px'}}>
                                <Button loading={loadingBtn1} onClick={() => this.HandleSearch()}>Buscar</Button>
                            </div>
                            <div className="inputs" style={{paddingLeft: '20px'}}>
                                <Button style={{ backgroundColor:'#00aeef' }} onClick={() => this.handleDownload()}>Descargar</Button>
                            </div>
                            <div className="inputs">
                                <Button loading={loadingBtn2} style={{ width:'50px' }} onClick={() => this.onRestore()}> <RedoOutlined/></Button>
                            </div>
                            <div className="inputs">
                                <Button style={{ width:'max-content' }} loading={loadingBtn3} onClick={this.reenviarFaltantesBlockchain}> Reenviar faltantes a Blockchain </Button>
                            </div>
						</div>
						<div className="list" style={{paddingBottom:'30px'}}> 
                            <List 
                                datasource={this.state.List}
                                columns={metadata(this.renderButtons)}
								total={total}
								onPagination={this.onPagination}
                                current={current}
                                //showCheckbox={true}
                                //onChangeSelection={this.onChangeSelection}
                                //selectedRowKeys={selectedRowKeys}
                            /> 
						</div>
					</div>
				</div>
			</ToggleMenuWrapper>
		)
	}
}

const metadata = (buttons:any) => [
    {title: 'ID', width: '8%', dataIndex: 'id',key: 'id'},
    {title: 'Proceso', width: '10%' , dataIndex: 'etapa', key: 'etapa'},
    {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'},
    {title: 'Fecha de Registro', dataIndex: 'fechaRegistro', key: 'fechaRegistro'},
    {title: 'Fuente - Proceso', dataIndex: 'procesos', key: 'procesos'},
    {title: 'Fuente - Registro', dataIndex: 'registros', key: 'registros'},
    {title: 'Usuario', dataIndex: 'usuario', key: 'usuario'},
    {title: 'Detalle', width: '8%', dataIndex: 'id', key: 'id', render: (text:any, record:any) => buttons(record, 2)},
    {title: 'Blockchain', width: '8%', dataIndex: 'id', key: 'id', render: (text:any, record:any) => buttons(record, 1)}
]

const mapStateToProps= (state:any) => ({
    List_Etapa: state.reporte.listE,
	List_Grupo_Cadena: state.reporte.listC,
    Base64Excel: state.reporte.excel,
    List: state.reporte.proceso
})

const mapDispatchToProps = (dispatch:any) => {
    const { AReporte_Descarga_Excel_Proceso_Call, AReporte_Lista_Etapa_Call, 
        AReporte_Lista_Procesos_Call, AReporte_Cadena_Valor_Lista_Call,
        AReporte_Proceso_Metadata_Etapas_Call, AProceso_Actualizar_Trazabilidad_Blockchain } = Actions;
    return bindActionCreators({
        AReporte_Descarga_Excel_Proceso_Call,
        AReporte_Lista_Etapa_Call,
		AReporte_Lista_Procesos_Call,
        AReporte_Cadena_Valor_Lista_Call,
        AReporte_Proceso_Metadata_Etapas_Call,
        AProceso_Actualizar_Trazabilidad_Blockchain
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);